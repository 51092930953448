import { Select } from 'antd';
import React from 'react';

const { Option } = Select;

export default function ConstantSelect({ fieldMeta, ...others }) {
  // build options
  const options = fieldMeta.constants
    ? fieldMeta.constants.map(constant => (
      <Option
        key={constant.value}
        value={constant.value}
      >
        {
          constant.label // for normal "Select" component
        }
      </Option>
    ))
    : [];

  return (
    <Select
      style={{ width: '100%' }}
      {...others}
    >
      {options}
    </Select>
  );
}