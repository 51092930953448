import { ArrowLeftOutlined, FileAddOutlined } from '@ant-design/icons';
import { Button, PageHeader } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { VIEWER_PATH } from '../../../util/routerConstants';
import { parseFormIdAndRecKey } from '../../../util/routerUtility';
import { selectFullFormMetas, selectOriginalAppNodes } from '../../appTree/appTreeSlice';
import AcknowledgeButton from './AcknowledgeButton';
import InformButton from './InformButton';
import RecallButton from './RecallButton';
import ReportButton from './ReportButton';

const SCREEN_PLACEHOLDER = '\b';

export default function SourcePageHeader() {

  //
  // hooks
  //

  const navigate = useNavigate();
  const location = useLocation();
  const appNodes = useSelector(selectOriginalAppNodes);
  const formMetas = useSelector(selectFullFormMetas);

  //
  // calculation
  //

  const [formId, recKey] = parseFormIdAndRecKey(location.pathname);
  // console.log('formId', formId);
  // console.log('recKey', recKey);
  const formMeta = formMetas.find(meta => meta.formId === formId);

  let formSubNode = null;
  let formWrappingAppNode = null;
  if (formId) {
    formWrappingAppNode = appNodes.find(appNode =>
      (formSubNode = (appNode.subNodes || []).find(subNode =>
        subNode.formMeta && subNode.formMeta.formId === formId)
      ) !== undefined
    );
  }

  const breadcrumbRoutes = [{
    path: 'p1',
    breadcrumbName: formWrappingAppNode
      ? formWrappingAppNode.appName
      : SCREEN_PLACEHOLDER
  }, {
    path: 'p2',
    breadcrumbName: formSubNode
      ? formSubNode.appName
      : SCREEN_PLACEHOLDER
  }];

  const detailSuffix = (recKey
    ? (' - ' + intl.get('SourcePageHeader.string.detail').d('Detail'))
    : '');

  const buttons = [];

  if (recKey) { // detail
    // recall
    buttons.push(<RecallButton key='recallButton' />);
    // acknowledge
    buttons.push(<AcknowledgeButton key='acknowledgeButton' />);
    // report
    buttons.push(<ReportButton key='reportButton' />);
    // inform
    buttons.push(<InformButton key='informButton' />);
  } else if (formSubNode) { // list
    const { formMeta } = formSubNode;
    const sourcePath = VIEWER_PATH
      + '/' + formMeta.formId
      + '/0';
    buttons.push(
      <Link
        key='createButton'
        to={sourcePath}
      >
        <Button
          type='primary'
          icon={<FileAddOutlined />}
          disabled={!formMeta || formMeta.sysFlg === 'Y'}
        >
          {intl.get('SourcePageHeader.Button.create')
            .d('New')}
        </Button>
      </Link>
    );
  }

  //
  // render
  //

  // console.log('formMeta', formMeta);
  // console.log('formSubNode', formSubNode);

  return (
    <PageHeader
      ghost={false}
      backIcon={recKey ? <ArrowLeftOutlined /> : false}
      onBack={() => navigate(-1)}
      breadcrumb={{
        routes: breadcrumbRoutes,
        separator: (formWrappingAppNode && formSubNode) ? '/' : SCREEN_PLACEHOLDER,
        itemRender: (route) => (<span>{route.breadcrumbName}</span>)
      }}
      title={
        formSubNode
          ? (formSubNode.appName + detailSuffix)
          : formMeta
            ? (formMeta.formName + detailSuffix)
            : SCREEN_PLACEHOLDER
      }
      extra={buttons}
    />
  );
}
