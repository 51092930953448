import { CommentOutlined, FileTextOutlined, HistoryOutlined, PaperClipOutlined, PartitionOutlined } from '@ant-design/icons';
import { Card, Spin, Tabs } from 'antd';
import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { selectFullFormMetas } from '../../appTree/appTreeSlice';
import { loadFormDetailAsync, selectLoadingFormSource, setAnchor } from '../formDetailSlice';
import AttachmentView from './AttachmentView';
import ChartView from './ChartView';
import CommentView from './CommentView';
import HistoryView from './HistoryView';
import InputView from './InputView';

export default function SourceDetailView() {

  //
  // hooks
  //

  const dispatch = useDispatch();
  const { formId, recKey } = useParams();
  // console.log('formId', formId);
  // console.log('recKey', recKey);
  const query = useQuery();
  const nodeRecKey = query.get('nodeRecKey');
  const fromFormId = query.get('fromFormId');
  const todoType = query.get('todoType');
  const todoRecKey = query.get('todoRecKey');
  // console.log('nodeRecKey', nodeRecKey);
  // console.log('fromFormId', fromFormId);
  // console.log('todoType', todoType);
  // console.log('todoRecKey', todoRecKey);

  const formMetas = useSelector(selectFullFormMetas);
  // const formSource = useSelector(selectFormSource);
  const loadingFormSource = useSelector(selectLoadingFormSource);

  // for form source
  const appFormMeta = formMetas.find(formMeta => formMeta.formId === formId);
  useEffect(() => {
    if (!appFormMeta) {
      // early return
      return;
    }

    dispatch(setAnchor({
      formRecKey: appFormMeta.recKey,
      srcRecKey: recKey,
      nodeRecKey,
      fromFormId,
      todoType,
      todoRecKey
    }));

    dispatch(loadFormDetailAsync());
  }, [appFormMeta, recKey, nodeRecKey, fromFormId, todoType, todoRecKey, dispatch]);

  // tab items
  const items = [];
  items.push({
    key: 'input',
    label: (
      <span>
        <FileTextOutlined />
        {intl.get('SourceDetailView.Tab.input')
          .d('Form')}
      </span>
    ),
    children: <InputView />
  });
  items.push({
    key: 'attachment',
    label: (
      <span>
        <PaperClipOutlined />
        {intl.get('SourceDetailView.Tab.attachment')
          .d('Attachment')}
      </span>
    ),
    children: <AttachmentView />
  });
  items.push({
    key: 'chart',
    label: (
      <span>
        <PartitionOutlined />
        {intl.get('SourceDetailView.Tab.chart')
          .d('Chart')}
      </span>
    ),
    children: <ChartView />
  });
  items.push({
    key: 'history',
    label: (
      <span>
        <HistoryOutlined />
        {intl.get('SourceDetailView.Tab.history')
          .d('History')}
      </span>
    ),
    children: <HistoryView />
  });
  items.push({
    key: 'comment',
    label: (
      <span>
        <CommentOutlined />
        {intl.get('SourceDetailView.Tab.comment')
          .d('Comment')}
      </span>
    ),
    children: <CommentView />
  });

  return (
    <Card
      style={{
        margin: 24,
        minHeight: '100vh'
      }}
      bodyStyle={{ paddingTop: 16 }}
    >
      {loadingFormSource
        ? (
          <Spin style={{
            width: '100%',
            padding: 24
          }} />
        )
        : (
          <Tabs
            defaultActiveKey="input"
            items={items}
          />
        )}
    </Card>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}