import { configureStore } from '@reduxjs/toolkit';
import appTreeReducer, { sliceName as appTreeSliceName } from '../features/appTree/appTreeSlice';
import designerReducer, { sliceName as designerSliceName } from '../features/designer/designerSlice';
import formDetailReducer, { sliceName as formDetailSliceName } from '../features/formViewer/formDetailSlice';
import formListReducer, { sliceName as formListSliceName } from '../features/formViewer/formListSlice';
import lovReducer, { sliceName as lovSliceName } from '../features/lov/lovSlice';
import shellReducer, { sliceName as shellSliceName } from '../features/shell/shellSlice';
import storageReducer, { sliceName as storageSliceName } from '../features/storage/storageSlice';

export default configureStore({
  reducer: {
    [shellSliceName]: shellReducer,
    [storageSliceName]: storageReducer,
    [appTreeSliceName]: appTreeReducer,
    [designerSliceName]: designerReducer,
    [formListSliceName]: formListReducer,
    [formDetailSliceName]: formDetailReducer,
    [lovSliceName]: lovReducer
  },
});
