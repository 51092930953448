import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Form, Input, message, Modal, Space, Typography } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordAsync, logout, selectLoading } from '../shellSlice';

const { Title } = Typography;

export default function PasswordForm({ title }) {
  // hooks
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();

  // handlers
  const handleLogout = () => {
    dispatch(logout());
  };

  const handleChangePassword = async (values) => {
    // console.log('values:', values);
    // api
    const { currentPassword, newPassword } = values;
    const result = await dispatch(changePasswordAsync({
      oldPassword: currentPassword,
      newPassword: newPassword
    }));
    // console.log('result', result);

    try {
      // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
      const fulfilled = unwrapResult(result);
      console.log('fulfilled', fulfilled);

      // prompt
      Modal.success({
        content: intl.get('PasswordSetter.message.passwordChanged')
          .d('Password changed successfully. Please re-login.'),
        onOk: handleLogout,
        onCancel: handleLogout
      });
    } catch (error) {
      // bad result (rejected)
      console.error('error', error);
      if ((error.message || '').includes('406')) {
        message.error(
          intl.get('PasswordSetter.message.currentPasswordIncorrect')
            .d('Password Changed Successfully'));
      }
    }
  };

  return (
    <Space
      direction="vertical"
      size="middle"
    >
      <Title level={4} strong={false}>
        {title}
      </Title>

      <Form
        name="passwordForm"
        initialValues={{}}
        layout="vertical"
        onFinish={handleChangePassword}
      >
        <Form.Item
          name="currentPassword"
          label={intl.get('PasswordSetter.FormItem.currentPassword')
            .d('Current Password')}
          rules={[{
            required: true
          }]}
        >
          <Input.Password disabled={loading} />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label={intl.get('PasswordSetter.FormItem.newPassword')
            .d('New Password')}
          rules={[{
            required: true,
            min: 3
          }]}
        >
          <Input.Password disabled={loading} />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label={intl.get('PasswordSetter.FormItem.confirmPassword')
            .d('Confirm Password')}
          validateFirst={true}
          dependencies={['newPassword']}
          rules={[{
            required: true,
            min: 3
          }, ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                intl.get('PasswordSetter.validator.passwordsDoNotMatch')
                  .d('The two passwords that you entered do not match')
              );
            },
          }),]}
        >
          <Input.Password disabled={loading} />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          {intl.get('PasswordSetter.Button.confirmChange')
            .d('Confirm Change')}
        </Button>
      </Form>
    </Space>
  );
}