import { createAsyncThunk, createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { selectHome } from '../shell/shellSlice';

import { assembleDynamicConstants } from './formUtility';

export const sliceName = 'formList';

//
// slice of state
//

const sliceState = {
  conditions: null, // formFieldMetaWithConditions
  // respect conditions
  formMeta: null,
  fieldMetas: null, // fieldMetaWithValueArrays
  pageSample: null,
  loading: false
};

//
// selectors
//

export const selectConditions = state => state[sliceName].conditions;
export const selectFormMeta = state => state[sliceName].formMeta;
export const selectFieldMetas = state => state[sliceName].fieldMetas;
export const selectPageSample = state => state[sliceName].pageSample;
export const selectLoading = state => state[sliceName].loading;
// calculated
export const selectLastPageReached = createDraftSafeSelector(
  [selectPageSample],
  (pageSample) => (pageSample && pageSample.last)
);
export const selectAltColumnKeys = createDraftSafeSelector(
  [selectFormMeta, selectFieldMetas],
  (formMeta, fieldMetas) => {
    let formMetaColumnKey; // M
    let formSourceColumnKey; // S
    let appCodeColumnKey; // C
    let nodeColumnKey; // N
    let todoTypeColumnKey; // T
    let allowBatchColumnKey; // A
    let completeFlgColumnKey;

    if (formMeta && formMeta.altTableName) {
      fieldMetas && fieldMetas.forEach(fieldMeta => {
        if ('M' === fieldMeta.altColumnType) { // form meta
          formMetaColumnKey = fieldMeta.recKey;
        } else if ('S' === fieldMeta.altColumnType) { // form source
          formSourceColumnKey = fieldMeta.recKey;
        } else if ('C' === fieldMeta.altColumnType) { // app code of form source
          appCodeColumnKey = fieldMeta.recKey;
        } else if ('N' === fieldMeta.altColumnType) { // node
          nodeColumnKey = fieldMeta.recKey;
        } else if ('T' === fieldMeta.altColumnType) { // todo type
          todoTypeColumnKey = fieldMeta.recKey;
        } else if ('A' === fieldMeta.altColumnType) { // allow batch
          allowBatchColumnKey = fieldMeta.recKey;
        } else if ('COMPLETE_FLG' === (fieldMeta.columnName + '').toUpperCase()) {
          completeFlgColumnKey = fieldMeta.recKey; // completeFlg
        }
      });
    }

    // use
    return {
      formMetaColumnKey,
      formSourceColumnKey,
      appCodeColumnKey,
      nodeColumnKey,
      todoTypeColumnKey,
      allowBatchColumnKey,
      completeFlgColumnKey
    };
  }
);

//
// thunks
//

const notLoading = (arg, thunkAPI) => {
  const { getState } = thunkAPI;
  const loading = selectLoading(getState());
  return !loading;
};

export const reloadFormSourcePageAsync = createAsyncThunk(
  sliceName + '/reloadFormSourcePageAsync',
  async (arg, thunkAPI) => {
    const { formRecKey, pageIndex, size } = arg;
    const { getState } = thunkAPI;
    const home = selectHome(getState());
    const conditions = selectConditions(getState());

    const postBody = {
      formFieldMetaWithConditions: toCompactConditions(conditions),
      homeUserId: home.userId,
      homeOrgId: home.orgId,
      homeLocId: home.locId
    }; // refer to w/s code

    const response = await axios.post(
      '/form/api/forms/' + formRecKey + '/search',
      postBody,
      {
        params: {
          page: pageIndex || 0,
          size: size || 10,
          charset: home.charset,
          advanced: true
        }
      }
    );
    // console.log('response', response);

    const formSourcePage = response.data;
    const { formMeta, fieldMetaWithValueArrays: fieldMetas, pageSample } = formSourcePage;
    // for dynamic constants
    assembleDynamicConstants(fieldMetas);

    return {
      formMeta,
      fieldMetas,
      pageSample
    };
  },
  { condition: notLoading }
);

export const loadNextPageAsync = createAsyncThunk(
  sliceName + '/loadNextPageAsync',
  async (arg, thunkAPI) => {
    const { getState } = thunkAPI;
    const home = selectHome(getState());
    const conditions = selectConditions(getState());
    const currentFormMeta = selectFormMeta(getState());
    const currentPageSample = selectPageSample(getState());

    const postBody = {
      formFieldMetaWithConditions: toCompactConditions(conditions),
      homeUserId: home.userId,
      homeOrgId: home.orgId,
      homeLocId: home.locId
    }; // refer to w/s code

    const response = await axios.post(
      '/form/api/forms/' + currentFormMeta.recKey + '/search',
      postBody,
      {
        params: {
          page: currentPageSample.number + 1, // index of the next page
          charset: home.charset,
          advanced: true
        }
      }
    );
    // console.log('response', response);

    const formSourcePage = response.data;
    const { fieldMetaWithValueArrays: fieldMetas, pageSample } = formSourcePage;
    // for dynamic constants
    assembleDynamicConstants(fieldMetas);

    return {
      fieldMetas,
      pageSample
    };
  },
  {
    condition: (arg, thunkAPI) => {
      const { getState } = thunkAPI;
      const loading = selectLoading(getState());
      const lastPageReached = selectLastPageReached(getState());
      // console.log('lastPageReached', lastPageReached);
      return !loading && !lastPageReached;
    }
  }
);

//
// slice
//

const _formListSlice = createSlice({
  name: sliceName,
  initialState: sliceState,
  reducers: {
    setConditions(state, action) {
      state.conditions = action.payload;
    }
  },
  extraReducers: (builder) => {
    // reload form source page
    builder.addCase(reloadFormSourcePageAsync.pending, (state, action) => {
      // state
      if (state.formMeta) { // previously loaded
        const { formRecKey } = action.meta.arg;
        if (formRecKey !== state.formMeta.recKey) { // different module
          // clear meta
          state.formMeta = null;
        }
        // clear data
        state.fieldMetas = null;
        state.pageSample = null;
      }
      // mark
      state.loading = true;
    }).addCase(reloadFormSourcePageAsync.fulfilled, (state, action) => {
      const { formMeta, fieldMetas, pageSample } = action.payload;
      // state
      state.formMeta = formMeta;
      state.fieldMetas = fieldMetas;
      state.pageSample = pageSample;
      // mark
      state.loading = false;
    }).addCase(reloadFormSourcePageAsync.rejected, (state, action) => {
      console.log('action rejected', action);
      // mark
      state.loading = false;
    });

    // load next page
    builder.addCase(loadNextPageAsync.pending, (state, action) => {
      // mark
      state.loading = true;
    }).addCase(loadNextPageAsync.fulfilled, (state, action) => {
      const { fieldMetas, pageSample } = action.payload;
      // state
      state.fieldMetas.forEach(fieldMeta => {
        const matchedFieldMeta = fieldMetas.find(aFieldMeta => aFieldMeta.recKey === fieldMeta.recKey);
        // extend constants
        const oldConstants = fieldMeta.constants || [];
        const newConstants = matchedFieldMeta.constants || [];
        const delta = newConstants.filter(constant => {
          const matchedConstant = oldConstants.find(aConstant => aConstant.value === constant.value);
          return !matchedConstant;
        });
        const mergedConstants = [...oldConstants, delta];
        fieldMeta.constants = mergedConstants;
        // extend values
        const oldValues = fieldMeta.values;
        const newValues = matchedFieldMeta.values;
        fieldMeta.values = [...oldValues, ...newValues];
      });
      state.pageSample = pageSample;
      // mark
      state.loading = false;
    }).addCase(loadNextPageAsync.rejected, (state, action) => {
      console.log('action rejected', action);
      // mark
      state.loading = false;
    });

  }
});

//
// actions
//

export const { setConditions } = _formListSlice.actions;

//
// reducer
//

export default _formListSlice.reducer;

//
// private functions
//

function toCompactConditions(conditions) {
  const compactConditions = [];
  if (conditions) {
    conditions.forEach(condition => {
      compactConditions.push({
        recKey: condition.recKey,
        operator: condition.operator,
        value: condition.value
      });
    });
  }

  // use
  return compactConditions;
}