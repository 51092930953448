import { createAsyncThunk, createDraftSafeSelector, createEntityAdapter, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import axios from 'axios';
import { encode as btoa } from 'base-64';

export const sliceName = 'shell';

//
// adapter
//

const entityAdapter = createEntityAdapter({
  selectId: entity => entity.recKey
});

//
// slice of state
//

const sliceState = {
  charset: null,
  // EpUser, refer to API
  user: null,
  // EpLocWithOrgName, refer to API
  location: null,
  // applicable locations, list of EpLocWithOrgName, refer to API
  locations: entityAdapter.getInitialState(),
  // service suite versions, refer to API
  versionMap: null,
  // shared loading status
  loading: false
};

//
// selectors
//

// managed by adapter
export const {
  selectIds: selectLocationRecKeys,
  selectById: selectLocationByRecKey,
  selectAll: selectAllLocations
} = entityAdapter.getSelectors(
  // with a selector function
  state => state[sliceName].locations);

// not managed by adapter
export const selectCharset = state => state[sliceName].charset;
export const selectUser = state => state[sliceName].user;
export const selectLocation = state => state[sliceName].location;
export const selectVersionMap = state => state[sliceName].versionMap;
export const selectLoading = state => state[sliceName].loading;

// calculated
export const selectHome = createDraftSafeSelector(
  [selectCharset, selectUser, selectLocation],
  (charset, user, location) => {
    const { recKey: userRecKey, userId, name: userName } = user || {};
    const { locId, name: locName, orgId, orgName } = location || {};
    return {
      charset,
      userRecKey, userId, userName,
      locId, locName,
      orgId, orgName
    };
  }
);

//
// thunks
//

const notLoading = (arg, thunkAPI) => {
  const { getState } = thunkAPI;
  const loading = selectLoading(getState());
  return !loading;
};

export const loginAsync = createAsyncThunk(
  sliceName + '/loginAsync',
  async (arg, thunkAPI) => {
    // gen token
    const { userId, password, clientId, clientSecret } = arg;
    const genTokenResponse = await axios.post(
      '/oauth/token',
      {}, // empty body
      {
        params: {
          grant_type: 'password',
          username: userId,
          password: password
        },
        headers: {
          'Authorization': 'Basic ' + btoa(`${clientId || 'ci'}:${clientSecret || 'cs'}`)
        }
      });
    // console.log('genTokenResponse', genTokenResponse);
    const token = genTokenResponse.data;
    const { access_token: accessToken } = token;

    // update global axios defaults
    axios.defaults.headers.Authorization = 'Bearer ' + accessToken;

    // check user
    const checkUserResponse = await axios.get(
      '/epbm/api/shell/check-user',
      {
        params: {
          userId: userId
        },
      });
    // console.log('checkUserResponse', checkUserResponse);
    const epUserWithDefLoc = checkUserResponse.data;

    // use
    return {
      token,
      epUserWithDefLoc
    };
  },
  { condition: notLoading }
);

export const changePasswordAsync = createAsyncThunk(
  sliceName + '/changePasswordAsync',
  async (arg, thunkAPI) => {
    const { getState } = thunkAPI;
    const home = selectHome(getState());
    const { userId } = home;
    const { oldPassword, newPassword } = arg;
    const changePasswordResponse = await axios.post(
      '/epbm/api/shell/change-password',
      { userId, oldPassword, newPassword });
    // console.log('changePasswordResponse', changePasswordResponse);
    const remoteUser = changePasswordResponse.data;

    // use
    return remoteUser;
  },
  { condition: notLoading }
);

export const loadVersionMapAsync = createAsyncThunk(
  sliceName + '/loadVersionMapAsync',
  async (arg, thunkAPI) => {
    // create an instance
    const instance = axios.create();
    // intentionally remove auth header, so that request works even after user signed out
    delete instance.defaults.headers.Authorization;
    // request with the customized instance
    const versionMapResponse = await instance.get('/api/versions');
    // console.log('versionMapResponse', versionMapResponse);
    const versionMap = versionMapResponse.data;

    // use
    return versionMap;
  },
  { condition: notLoading }
);

export const loadLocationsAsync = createAsyncThunk(
  sliceName + '/loadLocationsAsync',
  async (arg, thunkAPI) => {
    const { getState } = thunkAPI;
    const home = selectHome(getState());
    const { userId } = home;
    const locationsResponse = await axios.get(
      '/epbm/api/shell/locations',
      {
        params: {
          userId: userId
        }
      }
    );
    // console.log('locationsResponse', locationsResponse);
    const locations = locationsResponse.data;

    // use
    return locations;
  },
  { condition: notLoading }
);

//
// slice
//

const definedThunks = [
  loginAsync,
  changePasswordAsync,
  loadVersionMapAsync,
  loadLocationsAsync
];
const isDefinedPendingAction = isPending(...definedThunks);
const isDefinedRejectedAction = isRejected(...definedThunks);

const _shellSlice = createSlice({
  name: sliceName,
  initialState: sliceState,
  reducers: {
    // reset
    reset(state, action) {
      const { charset, user, location } = action.payload;
      // state
      state.charset = charset;
      state.user = user;
      state.location = location;
    },

    // switch charset
    switchCharset(state, action) {
      const charset = action.payload;
      // state
      state.charset = charset;
    },

    // switch location
    switchLocation(state, action) {
      const location = action.payload;
      // state
      state.location = location;
    },

    // logout
    logout(state, action) {
      // state, managed by adapter
      entityAdapter.removeAll(state.locations);
      // state, not managed by adapter
      state.user = null;
      state.location = null;
      // leave charset alone
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      // console.log(loginAsync.fulfilled.toString(), action);
      // parse, refer to API
      const { epUserWithDefLoc } = action.payload;
      const { defaultLocation } = epUserWithDefLoc;
      // state, managed by adapter
      entityAdapter.setAll(state.locations, defaultLocation ? [defaultLocation] : []);
      // state, not managed by adapter
      state.user = epUserWithDefLoc;
      state.location = defaultLocation;
      // mark
      state.loading = false;
    });

    builder.addCase(loadLocationsAsync.fulfilled, (state, action) => {
      // console.log(loadLocationsAsync.fulfilled.toString(), action);
      const locations = action.payload;
      // state, managed by adapter
      entityAdapter.setAll(state.locations, locations);
      // mark
      state.loading = false;
    });

    builder.addCase(changePasswordAsync.fulfilled, (state, action) => {
      // console.log(changePasswordAsync.fulfilled.toString(), action);
      // mark
      state.loading = false;
    });

    builder.addCase(loadVersionMapAsync.fulfilled, (state, action) => {
      // console.log(loadVersionMapAsync.fulfilled.toString(), action);
      const versionMap = action.payload;
      // state, not managed by adapter
      state.versionMap = versionMap;
      // mark
      state.loading = false;
    });

    builder.addMatcher(isDefinedPendingAction, (state, action) => {
      // console.log(action);
      // mark
      state.loading = true;
    });

    builder.addMatcher(isDefinedRejectedAction, (state, action) => {
      console.log('action rejected', action);
      // mark
      state.loading = false;
    });
  }
});

//
// actions
//

export const { reset, switchCharset, switchLocation, logout } = _shellSlice.actions;

//
// reducer
//

export default _shellSlice.reducer;

//
// private functions
//
