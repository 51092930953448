import { PlusOutlined } from '@ant-design/icons';
import { Button, Spin, Tabs } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import StandardPopconfirm from '../../../components/StandardPopconfirm';
import { createFormLine, deleteFormLine, selectLoadingLineFieldMetas } from '../formDetailSlice';
import FieldMetasTableView from './FieldMetasTableView';

export default function FormlineView({
  lineName,
  lineFieldMetas = [],
  editable,
  newLineEnabled,
  supplementalFormRefs,
  // callbacks
  onFormCreated
}) {

  //
  // hooks
  //

  const loadingLineFieldMetas = useSelector(selectLoadingLineFieldMetas);
  const dispatch = useDispatch();

  //
  // render
  //

  if (loadingLineFieldMetas) {
    return (<Spin style={{ width: '100%' }} />);
  }

  if (!lineName
    || !lineFieldMetas
    || !lineFieldMetas.length === 0) {
    return null;
  }

  // console.debug('lineFieldMetas', lineFieldMetas);

  // buttons
  const newLineButton = (
    <Button
      type="primary"
      disabled={!editable || !newLineEnabled}
      icon={<PlusOutlined />}
      onClick={() => dispatch(createFormLine())}
    >
      {intl.get('FormlineView.Button.newLine')
        .d('New Line')}
    </Button>
  );

  const renderRecKeyColumn = (text, record, index) => (
    <StandardPopconfirm
      title={intl.get('FormlineView.Popconfirm.confirmDelete')
        .d('Confirm Delete')}
      disabled={!editable || !newLineEnabled}
      onConfirm={() => dispatch(deleteFormLine(text))}
    >
      <Button
        type="link"
        danger
        disabled={!editable || !newLineEnabled}
      >
        {intl.get('FormlineView.Button.deleteLine')
          .d('Delete')}
      </Button>
    </StandardPopconfirm>
  );

  return (
    <Tabs
      items={[{
        key: 'line',
        label: (lineName || 'subtable')
          // tunning
          // .replaceAll(/\s/g, '') // might not be available in some browsers
          .replace(/\s/g, '')
          .toLocaleLowerCase()
          .replace('subtable', intl.get('FormlineView.string.subTable')
            .d('Sub Table')),
        children: <FieldMetasTableView
          fieldMetas={lineFieldMetas}
          editable={editable}
          renderRecKeyColumn={renderRecKeyColumn}
          hideRecKeyColumn={!newLineEnabled}
          supplementalFormRefs={supplementalFormRefs}
          onFormCreated={(form) => onFormCreated && onFormCreated(form)}
        />
      }]}
      tabBarExtraContent={editable && newLineEnabled && newLineButton}
    />
  );
}