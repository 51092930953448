export function checkActionEnabled(actionConfigs, actionName) {
  const actionConfig = (actionConfigs || []).find(actionConfig =>
    actionConfig.name === actionName);
  return actionConfig && actionConfig.enabled;
}

export function splitFieldMetas(fieldMetas) {
  const upperFieldMetas = [];
  const lowerFieldMetas = [];

  const [embeddedTableDgY, largestNormalDgY] = evaluateVirtualTable(fieldMetas);
  (fieldMetas || []).forEach(fieldMeta => {
    if (embeddedTableDgY < 0 // not applicable
      || largestNormalDgY < embeddedTableDgY // not necessary
      || fieldMeta.dgY < embeddedTableDgY // as required
    ) {
      // to upper
      upperFieldMetas.push(fieldMeta);
    } else if (fieldMeta.dgY > embeddedTableDgY) {
      // to lower
      lowerFieldMetas.push(fieldMeta);
    }
  });

  // use
  return [upperFieldMetas, lowerFieldMetas];
}

export function assembleDynamicConstants(fieldMetas) {
  if (!fieldMetas
    || fieldMetas.length === 0
    || !fieldMetas[0].values) {
    // not applicable, early return
    return;
  }

  const valueArrayLength = fieldMetas[0].values.length;
  for (let i = 0; i < valueArrayLength; i++) {
    const row = {};
    row['key'] = i;
    for (let j = 0; j < fieldMetas.length; j++) {
      const fieldMeta = fieldMetas[j];
      const columnKey = fieldMeta.recKey;
      const columnValue = fieldMeta.values[i];
      row[columnKey] = columnValue;

      if (columnKey < 0 && columnValue) {
        const originColumnKey = -1 * columnKey;
        const originColumnValue = row[originColumnKey];
        // assemble dynamic constants, if applicable
        if (fieldMeta.componentType === 'L') {
          const reversedFieldMeta = fieldMetas.find(
            fieldMeta => fieldMeta.recKey === originColumnKey);
          if (reversedFieldMeta) {
            const newConstant = { value: originColumnValue, label: columnValue };
            if (reversedFieldMeta.constants) {
              const matchedConstant = reversedFieldMeta.constants.find(constant => {
                return constant.value === newConstant.value;
              });
              if (!matchedConstant) {
                reversedFieldMeta.constants.push(newConstant);
              }
            } else {
              reversedFieldMeta.constants = [newConstant];
            }
          }
        }
      }
    }
  }
}

export function buildRows(fieldMetas) {
  const rows = [];

  if (!fieldMetas
    || fieldMetas.length === 0
    || !fieldMetas[0].values) {
    // not applicable, early return
    return rows;
  }

  const valueArrayLength = fieldMetas[0].values.length;
  for (let i = 0; i < valueArrayLength; i++) {
    const row = {};
    row['key'] = i + '';
    // dynamic key-value pair
    for (let j = 0; j < fieldMetas.length; j++) {
      const fieldMeta = fieldMetas[j];
      const columnKey = fieldMeta.recKey;
      const columnValue = fieldMeta.values[i];
      row[columnKey] = columnValue;
      // combine post query value
      if (columnKey < 0 && columnValue) {
        const originColumnKey = -1 * columnKey;
        const originColumnValue = row[originColumnKey];
        const combinedValue = originColumnValue + ' - ' + columnValue;
        row[originColumnKey] = combinedValue;
      }
    }
    // add to list
    rows.push(row);
  }

  // use
  return rows;
}

export function buildAnchor(row, formMeta, altColumnKeys) {
  const {
    formMetaColumnKey,
    formSourceColumnKey,
    appCodeColumnKey,
    nodeColumnKey,
    todoTypeColumnKey,
    allowBatchColumnKey,
    completeFlgColumnKey
  } = altColumnKeys;
  // console.log('altColumnKeys', altColumnKeys);
  // console.log('row', row);

  //
  // legacy rubbish logic due to design flaw in db procedure
  //

  const formRecKey = formMetaColumnKey ? row[formMetaColumnKey] : formMeta.recKey;
  const srcRecKey = formSourceColumnKey ? row[formSourceColumnKey] : row['0'];
  const appCode = appCodeColumnKey && row[appCodeColumnKey];
  const completeFlg = completeFlgColumnKey && row[completeFlgColumnKey];
  const todoTypeCandidate = todoTypeColumnKey && row[todoTypeColumnKey];
  const allowBatch = allowBatchColumnKey && row[allowBatchColumnKey];
  const nodeRecKeyCandidate = nodeColumnKey && row[nodeColumnKey];

  // note! orders matter! yes, nonsense rubbish code, due to design flaw in db procedure
  const nodeRecKey = ('N' === todoTypeCandidate || 'T' === todoTypeCandidate)
    ? nodeRecKeyCandidate : null;
  const fromFormId = ('APPROVED' === formMeta.formId || 'TODO' === formMeta.formId)
    ? formMeta.formId : null;
  const todoRecKey = (('APPROVED' === formMeta.formId && 'Y' === completeFlg)
    || 'TODO' === formMeta.formId)
    ? row['0']
    : null;
  const todoType = ('TODO' === formMeta.formId)
    ? todoTypeCandidate : null;

  // use
  const anchor = {
    formRecKey, srcRecKey, appCode, nodeRecKey, fromFormId, todoType, todoRecKey, allowBatch
  };
  // console.log('anchor', anchor);
  return anchor;
}

export function findFieldMetaByColumnName(fieldMetas, columnNames) {
  return columnNames.map(columnName => (fieldMetas || []).find(fieldMeta =>
    fieldMeta.columnName === columnName));
}

export function findLineInfo(fieldMetas) {
  if (!fieldMetas) {
    // early return
    return [null, null];
  }

  for (const fieldMeta of fieldMetas) {
    if ('E' === fieldMeta.componentType // E for Embed
      && fieldMeta.constants) { // refer to api. line meta as constant
      const lineMetaAsConstant = fieldMeta.constants[0];
      const lineId = lineMetaAsConstant.value;
      const lineName = lineMetaAsConstant.label;
      // use
      return [lineId, lineName];
    }
  }

  // not found
  return [null, null];
}

export function findTimeStamp(fieldMetas) {
  for (const fieldMeta of (fieldMetas || [])) {
    if (fieldMeta.recKey === 1) { // refer to w/s code
      const timeStamp = fieldMeta.value;
      // use
      return timeStamp;
    }
  }

  // not found
  return null;
}

export function buildMentionString(users) {
  let mentionString = '';
  (users || []).forEach(user => {
    mentionString += mentionString.length === 0 ? '' : ', ';
    mentionString += ('@' + user.userName);
  });
  mentionString += mentionString.length === 0 ? '' : '\n';
  // use
  return mentionString;
}

//
// not exported
//

function evaluateVirtualTable(fieldMetas) {
  let embeddedTableDgY = -1;
  let largestNormalDgY = -1;
  (fieldMetas || []).forEach(fieldMeta => {
    if ('E' === fieldMeta.componentType) {
      // mark
      embeddedTableDgY = fieldMeta.dgY;
    } else {
      largestNormalDgY = Math.max(largestNormalDgY, fieldMeta.dgY);
    }
  });
  // console.log('embeddedTableDgY', embeddedTableDgY);
  // console.log('largestNormalDgY', largestNormalDgY);

  return [embeddedTableDgY, largestNormalDgY];
}