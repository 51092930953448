import { CheckCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import { blue, green, grey, orange } from '@mui/material/colors';
import { message, Typography } from 'antd';
import axios from 'axios';
import moment from 'moment';
import intl from 'react-intl-universal';

const { Text } = Typography;

const MESSAGE_TYPE_SUCCESS = 'success';
const MESSAGE_TYPE_WARNING = 'warning';
const MESSAGE_TYPE_INFO = 'info';

// common locales
require('intl/locale-data/jsonp/en.js');
require('intl/locale-data/jsonp/zh.js');
require('intl/locale-data/jsonp/fr.js');
require('intl/locale-data/jsonp/ja.js');

// app locales
const appLocales = {
  "zh-Hans": require('../locales/zh-Hans.json'),
  "zh-Hant": require('../locales/zh-Hant.json'),
  "en": require('../locales/en.json'),
};

// formats
const DATE_FORMAT = 'YYYY-MM-DD';
// const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_TIME_FORMAT = 'MMM Do, HH:mm';

// axios interceptor
export const axiosInterceptor = (config) => {
  const { method, url, params, data } = config;
  // log only a portion
  console.log('intercepted', {
    method, url, params, data
  });
  return config;
};

export async function initLocaleBasedOnCharsetAsync(charset) {
  console.log('initLocaleBasedOnCharsetAsync', charset);
  const intlLocale = charset === 'zhs'
    ? 'zh-Hans'
    : charset === 'zht'
      ? 'zh-Hant'
      : 'en'; // all fall back to en
  // console.log('intlLocale', intlLocale);

  await intl.init({
    currentLocale: intlLocale,
    locales: appLocales
  });
}

export function calculateCharset() {
  // from config
  let charset = (window.formAppConfig || {}).charset;
  if (charset) {
    // early return
    return charset;
  }

  // from browser
  const language = (navigator.language || '');
  // console.log('navigator.language', language);
  const smoothedLanguage = language.toLowerCase();
  if (smoothedLanguage.startsWith('en')) {
    charset = 'eng';
  } else if (smoothedLanguage.startsWith('zh')) {
    if (smoothedLanguage.includes('hans')
      || smoothedLanguage.includes('cn')) {
      charset = 'zhs';
    } else {
      charset = 'zht';
    }
  } else {
    // fallback
    charset = 'eng';
  }

  // use
  return charset;
}

export function createPlainAxioInstance() {
  // create an instance
  const instance = axios.create();
  // intentionally remove auth header, so that request works even after user signed out
  delete instance.defaults.headers.Authorization;
  // install interceptor
  instance.interceptors.request.use(axiosInterceptor);
  // use
  return instance;
}

export function toDateString(dateIsoString) {
  // console.log('dateIsoString', dateIsoString);
  if (!dateIsoString) {
    return '-';
  }

  const date = new Date(dateIsoString);
  return moment(date, DATE_FORMAT).format(DATE_FORMAT);
}

export function toDateTimeString(dateIsoString) {
  // console.log('dateIsoString', dateIsoString);
  if (!dateIsoString) {
    return '-';
  }

  const date = new Date(dateIsoString);
  return moment(date, DATE_TIME_FORMAT).format(DATE_TIME_FORMAT);
}

export function promptSuccess(text, duration) {
  // forward
  promptMessage(MESSAGE_TYPE_SUCCESS, text, duration);
}

export function promptWarning(text, duration) {
  // forward
  promptMessage(MESSAGE_TYPE_WARNING, text, duration);
}

export function promptInfo(text, duration) {
  // forward
  promptMessage(MESSAGE_TYPE_INFO, text, duration);
}

function promptMessage(type, text, duration) {
  const config = {
    // duration: 0,
    duration: duration || 1.5,
    icon: (type === MESSAGE_TYPE_SUCCESS
      ? <CheckCircleTwoTone
        twoToneColor={green[500]}
        style={{ fontSize: 32, margin: 16 }}
      />
      : type === MESSAGE_TYPE_WARNING
        ? <WarningTwoTone
          twoToneColor={orange[500]}
          style={{ fontSize: 32, margin: 16 }}
        />
        : <CheckCircleTwoTone
          twoToneColor={blue[500]}
          style={{ fontSize: 32, margin: 16 }}
        />
    ),
    content: (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        paddingTop: 16,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 16,
        marginTop: 4,
        marginBottom: 4,
        minHeight: 80,
        minWidth: 240,
        maxWidth: 360,
        backgroundColor: grey[100],
        borderRadius: 2
      }}>
        <Text
          strong={true}
          style={{
            fontSize: 16,
            color: grey[800],
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            textAlign: 'center',
          }}
        >
          {text}
        </Text>
      </div>
    ),
  };

  if (type === MESSAGE_TYPE_SUCCESS) {
    message.success(config);
  } else if (type === MESSAGE_TYPE_WARNING) {
    message.warning(config);
  } else {
    message.info(config);
  }
}
