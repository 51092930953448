export const ACTION_SAVE = 'SAVE';
export const ACTION_POST = 'POST';
export const ACTION_UNDO_POST = 'UNDOPOST';
export const ACTION_WITHDRAW = 'WITHDRAW';
export const ACTION_SUSPEND = 'SUSPEND';
export const ACTION_APPROVE = 'APPROVE';
export const ACTION_REJECT = 'REJECT';
export const ACTION_ENDORSE = 'ENDORSE_BEFORE';
export const ACTION_NEW_LINE = 'NEW_LINE';
export const ACTION_INFORM = 'INFORM';
export const ACTION_ACKNOWLEDGE = 'ACKNOWLEDGE';