import { Form, Table } from 'antd';
import React, { useRef } from 'react';
import FieldMetaEditingCell from './FieldMetaEditingCell';
import FieldMetaViewingCell from './FieldMetaViewingCell';

const designerBase = 18;
const labelSpan = 2;
const columnWidth = (100 / designerBase).toFixed(4) + '%';
const headerCellStyle = { style: { padding: 0.5 } };
const virtualTableRowCount = 50;
const labelBackground = '#fafafa';
const requiredBackground = '#ffccc7';

export default function FieldMetasFormView({
  fieldMetas,
  editable,
  supplementalFormRefs,
  // callbacks
  onFormCreated
}) {

  const formRef = useRef();
  // make form
  const [form] = Form.useForm();
  // update reference value
  formRef.current = form;

  const virtualColumns = buildVirtualColumns(
    fieldMetas, editable,
    [formRef, ...(supplementalFormRefs || [])]
  );
  const virtualData = buildVirtualData();

  if (!virtualColumns
    || virtualColumns.length === 0) {
    return null;
  }

  const virtualTable = (
    <Table
      size="small"
      bordered
      columns={virtualColumns}
      dataSource={virtualData}
      pagination={false}
      showHeader={true}
    />
  );

  // console.log('editable', editable);

  if (!editable) {
    // use without form
    return virtualTable;
  }

  // call back, if applicable
  if (editable && onFormCreated) {
    onFormCreated(form);
  }

  // use with form
  return (
    <Form form={form}>
      {virtualTable}
    </Form>
  );
}

function buildVirtualColumns(fieldMetas, editable, formRefs) {
  const virtualColumns = Array(designerBase).fill()
    .map((value1, columnIndex) => {
      return {
        title: ' ',
        dataIndex: columnIndex,
        width: columnWidth,
        render: (value2, row, rowIndex) =>
          renderVirtualTableCell(
            fieldMetas, rowIndex, columnIndex, editable,
            formRefs),
        onHeaderCell: () => headerCellStyle
      }
    });

  // use
  return virtualColumns;
}

function buildVirtualData() {
  const virtualData = Array(virtualTableRowCount).fill()
    .map((value, index) => ({ key: index }));
  // console.log(virtualData);
  return virtualData;
}

function validateFieldMeta(fieldMeta) {
  return fieldMeta.selected === 'Y';
}

function renderVirtualTableCell(
  fieldMetas,
  rowIndex,
  columnIndex,
  editable,
  formRefs
) {

  // search cell
  for (const fieldMeta of fieldMetas) {
    if (!validateFieldMeta(fieldMeta)) {
      continue;
    }

    if (fieldMeta.dgY - 1 === rowIndex) { // minus the header cell in form designer
      if (fieldMeta.dgX === columnIndex) { // accurate target
        if (fieldMeta.placeHolder !== 'Y') { // normal field meta
          // console.log(fieldMeta.fieldLabel);
          if ('C' === fieldMeta.componentType) { // checkbox special case
            return {
              children: editable
                ? <FieldMetaEditingCell fieldMeta={fieldMeta} />
                : <FieldMetaViewingCell fieldMeta={fieldMeta} />,
              props: {
                colSpan: fieldMeta.dgW,
                rowSpan: fieldMeta.dgH,
                style: { background: labelBackground }
              }
            };
          } else {
            // use label
            return {
              children: fieldMeta.fieldLabel,
              props: {
                colSpan: labelSpan,
                rowSpan: fieldMeta.dgH,
                style: { background: labelBackground }
              }
            };
          }
        } else { // place holder (K for "remarK"), or embedded table (E for "Embed")
          return {
            children:
              ('K' === fieldMeta.componentType // remark
                || 'k' === fieldMeta.componentType // remark (left aligned)
                || 'E' === fieldMeta.componentType) // embedded table
                ? fieldMeta.fieldLabel
                : ' ',
            props: {
              colSpan: fieldMeta.dgW,
              rowSpan: 'E' === fieldMeta.componentType
                ? 0 // hide the embedded table for now (temporary solution)
                : fieldMeta.dgH,
              style: 'K' === fieldMeta.componentType
                ? {
                  background: labelBackground,
                  textAlign: 'center'
                }
                : 'k' === fieldMeta.componentType
                  ? {
                    background: labelBackground,
                    textAlign: 'left',
                    whiteSpace: 'pre-wrap'
                  }
                  : null
            }
          };
        }
      } else if (columnIndex >= labelSpan // next to the target
        && fieldMeta.dgX === columnIndex - labelSpan
        && fieldMeta.placeHolder !== 'Y' // normal field meta
        && fieldMeta.componentType !== 'C') { // checkbox special case

        return {
          // use form item
          children: editable
            ? <FieldMetaEditingCell
              fieldMeta={fieldMeta}
              liveFormRefs={formRefs}
            />
            : <FieldMetaViewingCell fieldMeta={fieldMeta} />,

          props: {
            colSpan: fieldMeta.dgW - labelSpan,
            rowSpan: fieldMeta.dgH,
            style: {
              background: fieldMeta.editable
                && fieldMeta.required
                ? requiredBackground
                : 'white',
            }
          }
        };
      }
    }
  }

  // other cells
  return {
    children: '.',
    props: {
      colSpan: 0, // mark either one as 0 to skip rendering
      rowSpan: 0
    }
  }
}