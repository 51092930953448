import { Popconfirm } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';

export default function StandardPopconfirm({
  children,
  ...others
}) {

  //
  // render
  //

  return (
    <Popconfirm
      title="Please confirm this action"
      okText={intl.get('StandardPopupconfirm.Button.confirm')
        .d('Confirm')}
      okButtonProps={{
        // type: 'text',
        type: 'primary',
        shape: 'round',
        danger: true,
        style: {
          // fontWeight: 'bold',
          // color: blue[900],
          minWidth: 80
        }
      }}
      cancelText={intl.get('StandardPopupconfirm.Button.cancel')
        .d('Cancel')}
      cancelButtonProps={{
        // type: 'text',
        shape: 'round',
        danger: false,
        style: {
          // fontWeight: 'bold',
          // color: red[500],
          minWidth: 80
        }
      }}
      // allow override
      {...others}
    >
      {children}
    </Popconfirm>
  );
}