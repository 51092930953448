import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Button, Pagination, Space } from 'antd';
import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { VIEWER_PATH } from '../../../util/routerConstants';
import { selectFullFormMetas } from '../../appTree/appTreeSlice';
import { reloadFormSourcePageAsync, selectAltColumnKeys, selectFieldMetas, selectFormMeta, selectLoading, selectPageSample, setConditions } from '../formListSlice';
import CriteriaPanel from './CriteriaPanel';
import FieldMetasTableView from './FieldMetasTableView';

export default function SourceTableView() {
  // hooks
  const { formId } = useParams();
  // console.log('formId', formId);
  const formMetas = useSelector(selectFullFormMetas);
  const loading = useSelector(selectLoading);
  const formMeta = useSelector(selectFormMeta);
  const fieldMetas = useSelector(selectFieldMetas);
  const pageSample = useSelector(selectPageSample);
  const altColumnKeys = useSelector(selectAltColumnKeys);
  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const appFormMeta = formMetas.find(formMeta => formMeta.formId === formId);
  const queryCondition = query.get('condition');
  const queryPage = query.get('page');
  const queryTim = query.get('tim'); // time in milli
  // console.log('queryCondition', queryCondition);
  // console.log('queryPage', queryPage);
  // console.log('queryTim', queryTim);

  useEffect(() => {
    if (!appFormMeta) {
      // early return
      return;
    }

    // setup conditions first
    dispatch(setConditions(toConditionArray(queryCondition)));

    // reload
    const promise = dispatch(reloadFormSourcePageAsync({
      formRecKey: appFormMeta.recKey,
      pageIndex: queryPage || 0
    }));
    // https://redux-toolkit.js.org/api/createAsyncThunk#canceling-while-running
    return () => {
      promise.abort();
    };
  }, [appFormMeta, queryCondition, queryPage, queryTim, dispatch]);

  // handlers
  const onConditionCollected = (conditions) => {
    // console.debug('conditions', conditions);

    const params = new URLSearchParams();
    // time in milli, to trigger reloading even when conditions not changed
    params.set('tim', new Date().getTime());

    const conditionString = toConditionString(conditions);
    if (conditionString) {
      params.set('condition', conditionString);
    }

    // build search
    const search = '?' + params;

    // compose
    const to = {
      pathname: location.pathname,
      search: search
    };
    // console.debug('to', to);

    const smoothedQueryCondition = queryCondition || '';
    const smoothedConditionString = conditionString || '';
    // console.debug('smoothedQueryCondition', smoothedQueryCondition);
    // console.debug('smoothedConditionString', smoothedConditionString);
    // console.debug('queryPage', queryPage);
    if (smoothedQueryCondition === smoothedConditionString
      // if on non-first page, should always use "push" instead, so that user can "back" to
      && (!queryPage)) {
      // replace
      navigate(to, {
        replace: true
      });
    } else {
      // push
      navigate(to);
    }
  };

  // render
  return (
    <Space
      style={{
        width: '100%',
        padding: 24
      }}
      direction="vertical"
      size="middle"
    >
      <CriteriaPanel
        formId={formId}
        fieldMetas={fieldMetas || []}
        recoveringConditions={toConditionArray(queryCondition)}
        onCollectConditions={(conditions) => onConditionCollected(conditions)}
        loading={loading}
      />
      <FieldMetasTableView
        fieldMetas={fieldMetas}
        renderRecKeyColumn={(text, record) => (
          <LinkCell
            text={text}
            record={record}
            altColumnKeys={altColumnKeys}
            formMeta={formMeta}
            formMetas={formMetas}
          />
        )}
        loading={loading}
        showHeader={fieldMetas !== null}
        sticky
      />
      <CustomPagination pageSample={pageSample} />
    </Space>
  );
}

function CustomPagination({ pageSample }) {
  // hooks
  const location = useLocation();
  const navigate = useNavigate();

  // handlers
  const onPaginationChange = (readAsPage) => {
    // console.debug('readAsPage', readAsPage);
    // build search
    const params = new URLSearchParams(location.search);
    if (readAsPage === 1) {
      params.delete('page');
    } else {
      params.set('page', readAsPage - 1);
    }
    const paramsString = params.toString();
    const search = paramsString.length === 0
      ? undefined
      : ('?' + paramsString);
    // compose
    const to = {
      pathname: location.pathname,
      search: search
    };
    // console.debug('to', to);
    // push
    navigate(to);
  };

  // render
  if (!pageSample) {
    return null;
  }

  return (
    <Pagination
      hideOnSinglePage={true}
      showSizeChanger={false}
      pageSize={pageSample.size}
      total={pageSample.totalElements}
      current={pageSample.number + 1} // index to read-as
      itemRender={(current, type, originalElement) =>
        (type === 'prev' || type === 'next')
          ? <Button
            icon={type === 'prev'
              ? <CaretLeftOutlined />
              : <CaretRightOutlined />}
          />
          : originalElement
      }
      onChange={(readAsPage) => onPaginationChange(readAsPage)}
    />
  );
}

function LinkCell({
  record,
  text,
  altColumnKeys,
  formMeta,
  formMetas
}) {

  const {
    formMetaColumnKey,
    formSourceColumnKey,
    nodeColumnKey,
    todoTypeColumnKey,
    completeFlgColumnKey
  } = altColumnKeys;

  // render
  if (!formMeta.altTableName) {
    const srcRecKey = text;
    // normal case, queried from formmas. early return
    const sourcePath = VIEWER_PATH
      + '/' + formMeta.formId
      + '/' + srcRecKey;
    // early return
    return (
      <Link to={sourcePath}>
        {intl.get('SourceTableView.Button.open')
          .d('Open')}
      </Link>
    );
  }

  if (formMetaColumnKey === undefined
    || formSourceColumnKey === undefined) {
    // no link applicable, early return
    return null;
  }

  const formRecKey = record[formMetaColumnKey];
  const srcRecKey = record[formSourceColumnKey];
  const sourceFormMeta = formMetas.find(meta => (meta.recKey + '') === (formRecKey + ''));
  if (!formRecKey || !srcRecKey || !sourceFormMeta) {
    // no link applicable, early return
    return null;
  }

  const nodeRecKey = record[nodeColumnKey];
  const todoType = record[todoTypeColumnKey];
  let buttonText = intl.get('SourceTableView.Button.open')
    .d('Open');

  const params = new URLSearchParams();
  // on node rec key
  if (nodeRecKey && ('N' === todoType || 'T' === todoType)) {
    // override text
    buttonText = intl.get('SourceTableView.Button.acknowledge')
      .d('Acknowledge');
    params.append('nodeRecKey', nodeRecKey);
  }

  // on form id
  if ('APPROVED' === formMeta.formId
    || 'TODO' === formMeta.formId) {
    // for both
    params.append('fromFormId', formMeta.formId);
    if ('APPROVED' === formMeta.formId) {
      if (completeFlgColumnKey) {
        const completeFlg = record[completeFlgColumnKey];
        if ('Y' === completeFlg) {
          // on todo rec key
          params.append('todoRecKey', text);
        }
      }
    } else if ('TODO' === formMeta.formId) {
      // on todo rec key
      params.append('todoRecKey', text);
      // on todo type
      params.append('todoType', todoType);
    }
  }

  const paramsString = params + '';
  const sourcePath = VIEWER_PATH
    // eslint-disable-next-line
    // + '/' + sourceFormMeta.formId
    + '/' + (sourceFormMeta ? sourceFormMeta.formId : formRecKey)
    + '/' + srcRecKey
    + (paramsString ? ('?' + paramsString) : '');
  return (
    <Link to={sourcePath}>
      {buttonText}
    </Link>
  );
}

function toConditionString(conditionArray) {
  if (!conditionArray || conditionArray.length === 0) {
    // eraly return
    return '';
  }
  // use
  return JSON.stringify(conditionArray);
}

function toConditionArray(conditionString) {
  if (!conditionString || conditionString.length === 0) {
    // early return
    return [];
  }
  // use
  const array = JSON.parse(conditionString);
  // console.debug('array', array);
  return array;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}