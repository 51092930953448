import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';

export default function LoginForm({
  loading,
  onLogin
}) {

  return (
    <Form
      name="loginForm"
      initialValues={{}}
      onFinish={(values) => {
        const { username, password } = values;
        onLogin && onLogin(username, password);
      }}
    >
      <Form.Item
        name="username"
        label={intl.get('LoginForm.Input.username')
          .d('Username')} // for rule message auto generation
        labelCol={{ span: 0 }} // to hide label
        rules={[{
          required: true
        }]}
      >
        <Input
          prefix={<UserOutlined />}
          placeholder={intl.get('LoginForm.Input.username')
            .d('Username')}
          disabled={loading}
        />
      </Form.Item>

      <Form.Item
        name="password"
        label={intl.get('LoginForm.Input.password')
          .d('Password')} // for rule message auto generation
        labelCol={{ span: 0 }} // to hide label
        rules={[{
          required: true
        }]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder={intl.get('LoginForm.Input.password')
            .d('Password')}
          disabled={loading}
        />
      </Form.Item>

      <Button
        style={{ width: '100%' }}
        type="primary"
        htmlType="submit"
        loading={loading}
      >
        {intl.get('LoginForm.Button.login')
          .d('Login')}
      </Button>

    </Form>
  );
}
