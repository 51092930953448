import { LoadingOutlined } from '@ant-design/icons';
import { Collapse, Space, Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { selectOriginalAppNodes } from '../../appTree/appTreeSlice';
import { loadFieldMetasAsync, selectAllFieldMetas, selectLoading } from '../designerSlice';
import FieldMetasGrid from './FieldMetasGrid';
import LayoutEditor from './LayoutEditor';

const { Title } = Typography;
const { Panel } = Collapse;

function Spinner() {
  return (
    <Spin
      style={{
        width: '100%',
        padding: 16
      }}
      indicator={
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      }
    />
  );
}

export default function DesignerDetailView() {
  // hooks
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const fieldMetas = useSelector(selectAllFieldMetas);
  const appNodes = useSelector(selectOriginalAppNodes);
  const { formId } = useParams();
  // console.log('formId', formId);
  const query = useQuery();
  const lineId = query.get('lineId');
  // console.log('lineId', lineId);

  // parse
  let editingFormMeta = null;
  if (formId) {
    appNodes.forEach(appNode => {
      if (!editingFormMeta) {
        (appNode.subNodes || []).forEach(subNode => {
          if (!editingFormMeta
            && subNode.formMeta
            && subNode.formMeta.formId === formId) {
            editingFormMeta = subNode.formMeta;
          }
        });
      }
    });
  }
  // console.log('editingFormMeta', editingFormMeta);
  const formRecKey = editingFormMeta && editingFormMeta.recKey;

  useEffect(() => {
    if (!formRecKey) {
      // early return
      return;
    }

    // dispatch action
    dispatch(loadFieldMetasAsync({
      formRecKey,
      lineId
    }));
  }, [dispatch, formRecKey, lineId]);

  // render
  const availableItems = fieldMetas.filter(fieldMeta => fieldMeta.placeHolder === 'N');
  const placeholders = fieldMetas.filter(fieldMeta => fieldMeta.placeHolder === 'Y');

  return (
    <div style={{ padding: 24 }}>
      {
        loading
          ? <Spinner />
          : availableItems.length > 0 || placeholders.length > 0
            ? <Space
              style={{
                padding: 24,
                backgroundColor: 'white',
                width: '100%'
              }}
              direction="vertical"
            >
              <Title level={4} >
                {intl.get('DesignerDetailView.Title.pickItems')
                  .d('Pick Items')}
              </Title>
              <Collapse
                defaultActiveKey={['availableItems']}>
                <Panel
                  key="availableItems"
                  header={intl.get('DesignerDetailView.Panel.availableItems')
                    .d('Available Items')}
                >
                  <FieldMetasGrid fieldMetas={availableItems} />
                </Panel>
                <Panel
                  key="placeholders"
                  header={intl.get('DesignerDetailView.Panel.placeholders')
                    .d('Placeholders')}
                >
                  <FieldMetasGrid fieldMetas={placeholders} />
                </Panel>
              </Collapse>
              <br />
              <Title level={4} >
                {intl.get('DesignerDetailView.Title.designLayout')
                  .d('Design Layout')}
              </Title>
              <LayoutEditor fieldMetas={fieldMetas} />
            </Space>
            : null
      }
    </div>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}