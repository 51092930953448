import { Select } from 'antd';
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { TIME_FORMAT } from '../../shell/shellConstants';
import { searchLovAsync, selectByLovId } from '../lovSlice';

const { Option } = Select;

export default function LovSelect({
  fieldMeta,
  liveFormRefs,
  usedForCriteria,
  ...others
}) {
  // console.log('others', others);
  const { lovId } = fieldMeta;

  // hooks
  const [searchText, setSearchText] = useState();
  const lovRepo = useSelector(state => selectByLovId(state, lovId));
  // console.log('lovRepo', lovRepo);
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log('searchText', searchText);
    const smoothedSearchText = (searchText || '').toString().trim();
    if (!smoothedSearchText) {
      return;
    }

    const mergedFieldsValue = {};
    // console.debug('liveFormRefs', liveFormRefs);
    (liveFormRefs || []).forEach(liveFormRef => {
      if (liveFormRef && liveFormRef.current) {
        // merge
        Object.assign(mergedFieldsValue, liveFormRef.current.getFieldsValue());
      }
    });
    const formFieldMetaWithValues = buildMetaWithValues(mergedFieldsValue);

    // https://redux-toolkit.js.org/api/createAsyncThunk#canceling-while-running
    const promise = dispatch(searchLovAsync({
      lovId,
      searchText: smoothedSearchText,
      formFieldMetaWithValues,
      usedForCriteria
    }));
    return () => {
      // `createAsyncThunk` attaches an `abort()` method to the promise
      promise.abort();
    };
  }, [lovId, searchText, liveFormRefs, usedForCriteria, dispatch]);

  // handlers
  const setSearchTextDebounce = debounce(setSearchText, 500);

  // calculation
  const keyValuePairs = lovRepo?.keyValuePairs || [];
  // console.log('keyValuePairs', keyValuePairs);

  // merge with constants
  const merged = [...keyValuePairs];
  (fieldMeta.constants || []).forEach(constant => {
    const sampleKeyValuePair = ({
      key: constant.value,
      value: constant.label
    });
    const match = keyValuePairs.find(
      keyValuePair => keyValuePair.key === sampleKeyValuePair.key);
    if (!match) {
      merged.push(sampleKeyValuePair);
    }
  });
  // console.log('combined', combined);

  const options = merged.map(keyValuePair => (
    <Option
      key={keyValuePair.key}
      value={keyValuePair.key}
    >
      {keyValuePair.key} - {keyValuePair.value}
    </Option>
  ));

  // const options = keyValuePairs && keyValuePairs.length > 0
  //   // lov pairs
  //   ? keyValuePairs.map(keyValuePair => (
  //     <Option
  //       key={keyValuePair.key}
  //       value={keyValuePair.key}>
  //       {keyValuePair.key} - {keyValuePair.value}
  //     </Option>
  //   ));
  // // fall back to constants, if applicable
  // : fieldMeta.constants
  //   ? fieldMeta.constants.map(constant => (
  //     <Option
  //       key={constant.value}
  //       value={constant.value}
  //     >
  //       {
  //         (constant.value + ' - ' + constant.label) // LOV as "Select"
  //       }
  //     </Option>
  //   ))
  //   : [];

  // render
  return (
    <Select
      style={{ width: '100%' }}
      showSearch
      filterOption={false}
      notFoundContent={intl.get('LovSelect.string.noMatchFound').d('No match found')}
      onSearch={(text) => setSearchTextDebounce(text)}
      {...others}
    >
      {options}
    </Select>
  );
}

function buildMetaWithValues(fieldsValue) {
  const formFieldMetaWithValues = [];
  Object.keys(fieldsValue).forEach(key => {
    // skip those for lines
    if (key.indexOf('_') >= 0) {
      // early return
      return;
    }

    let value = fieldsValue[key];
    if (!value || !value.toString().trim()) {
      // early return
      return;
    }

    // convert, if applicable
    if (moment.isMoment(value)) {
      value = value.format(TIME_FORMAT);
    } else if (typeof (value) === typeof (true)) {
      value = value ? 'Y' : 'N';
    } else {
      value = value.toString().trim();
    }

    // collect
    formFieldMetaWithValues.push({
      recKey: key,
      value: value
    });
  });

  //use
  return formFieldMetaWithValues;
}