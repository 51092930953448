import { message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';
import App from './App';
import store from './app/store';
import { logout, reset, switchCharset } from './features/shell/shellSlice';
import { ITEM_KEY_ACCESS_TOKEN, ITEM_KEY_CHARSET, ITEM_KEY_HOME } from './features/storage/storageConstants';
import { axiosInterceptor, calculateCharset, initLocaleBasedOnCharsetAsync } from './util/commonUtility';

// initializer
const Initializer = () => {
  // hooks
  const [working, setWorking] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const initializeAsync = async () => {
      // init axios
      axios.defaults.baseURL = window.formAppConfig.serviceEntry;
      const persistedAccessToken = localStorage.getItem(ITEM_KEY_ACCESS_TOKEN);
      if (persistedAccessToken) {
        axios.defaults.headers.Authorization = 'Bearer ' + persistedAccessToken;
        console.log('persistedAccessToken', persistedAccessToken);
      }

      // https://github.com/axios/axios#interceptors

      // hook of auto-logout on 401 error
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          // https://github.com/axios/axios#handling-errors
          if (error.response
            // 401 Unauthorized
            && error.response.status === 401) {
            // logout
            dispatch(logout());
            // prompt
            message.destroy(-1);
            message.error({
              key: -1,
              content: 'Session expired. Please re-login.'
            });
          }
          // use
          return Promise.reject(error);
        });

      // install interceptor
      axios.interceptors.request.use(axiosInterceptor);

      // charset/locale
      const persistedCharset = localStorage.getItem(ITEM_KEY_CHARSET);
      console.log('persistedCharset', persistedCharset);
      const charset = persistedCharset || calculateCharset();
      // wait for it
      await initLocaleBasedOnCharsetAsync(charset);
      // switch
      dispatch(switchCharset(charset));

      // home recovery
      const persistedHomeString = localStorage.getItem(ITEM_KEY_HOME);
      // console.log('persistedHomeString', persistedHomeString);
      const persistedHome = persistedHomeString && JSON.parse(persistedHomeString);
      console.log('persistedHome', persistedHome);
      // reset, if applicable
      if (persistedHome) {
        dispatch(reset({
          charset,
          user: {
            recKey: persistedHome.userRecKey, // mind the field name
            userId: persistedHome.userId,
            name: persistedHome.userName // mind the field name
          },
          location: {
            locId: persistedHome.locId,
            name: persistedHome.locName, // mind the field name
            orgId: persistedHome.orgId,
            orgName: persistedHome.orgName,
          }
        }));
      }

      // mark
      setWorking(false);
    };
    initializeAsync();
  }, [dispatch]);

  // render
  return working
    ? null
    : (<App />);
}

const reduxRoot = (
  <Provider store={store}>
    <Initializer />
  </Provider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(process.env.NODE_ENV === 'production'
  ? (<React.StrictMode>
    {reduxRoot}
  </React.StrictMode>)
  : reduxRoot
);
