import { Button, Form, Input, Radio, Row, Space } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import LovSelect from '../../lov/ui/LovSelect';

const { TextArea } = Input;

export default function ApprovalView({
  confirmText,
  forEndorse,
  // callbacks
  onCancel,
  onConfirm
}) {

  const [form] = Form.useForm();

  // handlers
  const collectValues = async () => {
    const values = await form.validateFields();
    console.debug('values', values);
    onConfirm && onConfirm(values);
  }

  const formItems = [];
  if (forEndorse) {
    // endorseBefore
    formItems.push(
      <Form.Item
        key="endorseBefore"
        name="endorseBefore"
        rules={[{ required: true }]}
        initialValue={true}
      >
        <Radio.Group>
          <Radio value={true}>
            {intl.get('ApprovalView.string.endorseBefore')
              .d('Endorse Before')}
          </Radio>
          <Radio value={false}>
            {intl.get('ApprovalView.string.endorseAfter')
              .d('Endorse After')}
          </Radio>
        </Radio.Group>
      </Form.Item>
    );

    // endorsedUserIds
    formItems.push(
      <Form.Item
        key="endorsedUserIds"
        name="endorsedUserIds"
        label={intl.get('ApprovalView.FormItem.selectUsers')
          .d('Select Users')}
        rules={[{ required: true }]}
      >
        <LovSelect
          fieldMeta={{ lovId: 'USER' }}
          mode="multiple"
        />
      </Form.Item>
    );
  }

  // comment
  formItems.push(
    <Form.Item
      key="comment"
      name="comment"
      label={intl.get('ApprovalView.FormItem.comment')
        .d('Comment')}
    >
      <TextArea
        placeholder={intl.get('ApprovalView.FormItem.comment')
          .d('Comment')}
        rows={5}
      />
    </Form.Item>
  );

  return (
    <Form
      form={form}
      layout="vertical"
    >
      {formItems}
      <Row>
        <div style={{
          display: 'block',
          marginLeft: 'auto'
        }}>
          <Space direction="horizontal">
            <Button
              onClick={() => onCancel && onCancel()}
            >
              {intl.get('ApprovalView.Button.cancel')
                .d('Cancel')}
            </Button>
            <Button
              type="primary"
              onClick={() => collectValues()}
            >
              {confirmText || intl.get('ApprovalView.Button.confirm')
                .d('Confirm')}
            </Button>
          </Space>
        </div>
      </Row>
    </Form>
  );
}