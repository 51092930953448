import { Empty } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import Flowchart from 'react-simple-flowchart';
import { selectFormSource } from '../formDetailSlice';

export default function ChartView() {

  //
  // hooks
  //

  const formSource = useSelector(selectFormSource);

  //
  // calculation
  //

  const { wfdMasNodes, wfdMasNodeNexts, wfdNodes } = (formSource || {});
  if (!wfdMasNodes
    || !wfdMasNodeNexts
    || !wfdNodes
    || wfdMasNodes.length === 0
    || wfdMasNodeNexts.length === 0) {
    // early return
    return <Empty description={false} />;
  }

  const options = {
    'line-color': '#e8e8e8',
    'element-color': '#d9d9d9',
    'flowstate': {
      'C': {
        'fill': '#e6f7ff',
        'font-color': '#1890ff',
        'element-color': '#69c0ff',
        'font-weight': 'bold'
      },
      'A': {
        'fill': '#FBDE8B',
        'font-color': '#F18701',
        'element-color': '#F18701',
        'font-weight': 'bold'
      }
    }
  };

  // convert
  let chartCode = '';
  let startRecKey = '';

  wfdMasNodes.forEach(masNode => {
    const statusFlg = (wfdNodes.find(node =>
      node.wfmasNodeRecKey === masNode.recKey)
      || {}).statusFlg;
    // console.debug('statusFlg', statusFlg);
    if (masNode.name === 'Start') {
      chartCode += masNode.recKey + '=>start: ' + masNode.name + '|C\n';
      startRecKey = masNode.recKey;
    } else if (masNode.name === 'End') {
      if (statusFlg === 'C') {
        chartCode += masNode.recKey + '=>end: ' + masNode.name + '|C\n';
      } else if (statusFlg === 'A' || statusFlg === 'B') {
        chartCode += masNode.recKey + '=>end: ' + masNode.name + '|A\n';
      } else {
        chartCode += masNode.recKey + '=>end: ' + masNode.name + '\n';
      }
    } else {
      if (statusFlg === 'C') {
        chartCode += masNode.recKey + '=>condition: ' + masNode.name + '|C\n';
      } else if (statusFlg === 'A' || statusFlg === 'B') {
        chartCode += masNode.recKey + '=>condition: ' + masNode.name + '|A\n';
      } else {
        chartCode += masNode.recKey + '=>condition: ' + masNode.name + '\n';
      }
    }
  });

  wfdMasNodeNexts.forEach(masNodeNext => {
    if (masNodeNext.fromRecKey === startRecKey) {
      chartCode += masNodeNext.fromRecKey + '->' + masNodeNext.nextRecKey + '\n';
      wfdMasNodeNexts.forEach(masNodeNext2 => {
        if (masNodeNext2.fromRecKey !== startRecKey && masNodeNext2.switchFlg === 'Y') {
          chartCode += masNodeNext2.fromRecKey + '(yes, bottom)->' + masNodeNext2.nextRecKey + '\n';
        }
        else if (masNodeNext2.fromRecKey !== startRecKey && masNodeNext2.switchFlg === 'N') {
          chartCode += masNodeNext2.fromRecKey + '(no, right)->' + masNodeNext2.nextRecKey + '\n';
        }
      });
    }
  });

  //
  // render
  //

  return (
    <Flowchart
      chartCode={chartCode}
      options={options}
    />
  );
}