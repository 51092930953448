import { EnvironmentTwoTone, GlobalOutlined, SwapOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Divider, Modal, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { selectHome, switchLocation } from '../shellSlice';
import LocationModal from './LocationModal';

const { Text, Paragraph } = Typography;
const { Meta } = Card;

export default function UserView() {
  // hooks
  const [modalOpen, setModalOpen] = useState(false);
  const [avatarSrc, setAvatarSrc] = useState('https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png');

  const dispatch = useDispatch();
  const home = useSelector(selectHome);

  useEffect(() => {
    // auto show modal, if applicable
    if (!home.locId) {
      showModal();
    }
  }, [home.locId]);

  // handlers
  const showModal = () => {
    setModalOpen(true);
  };

  const handleLocationSelected = (location) => {
    // close modal
    setModalOpen(false);
    // dispatch action
    dispatch(switchLocation(location));
  };

  return (
    <div style={{ margin: 24 }}>
      <Card
        style={{ width: '50%' }}
        bordered={false}
      >
        <Meta
          avatar={
            <Avatar
              size={48}
              src={avatarSrc}
              onError={() => setAvatarSrc('logo512.png')}
            />
          }
          title={home.userName}
          description={
            <Paragraph>
              <Divider dashed />
              <Space
                direction="vertical"
                size="middle"
              >
                <Text type="secondary">
                  <EnvironmentTwoTone /> {intl.get('User.Text.currentLocation')
                    .d('Current Location')}
                </Text>

                <div>
                  <Text style={{ fontSize: 16 }}>
                    {home.locId
                      ? (home.locName + ' (' + home.locId + ')')
                      : '-'}
                  </Text>
                  <br />
                  <Text
                    type="secondary"
                    style={{ fontSize: 12 }}
                  >
                    {home.orgId
                      ? (home.orgName + ' (' + home.orgId + ')')
                      : null}
                  </Text>
                </div>

                <Button onClick={() => showModal()}>
                  {home.locId
                    ? <SwapOutlined />
                    : <GlobalOutlined />} {home.locId
                      ? intl.get('User.Button.switchLocation')
                        .d('Switch Location')
                      : intl.get('User.Button.selectLocation')
                        .d('Select Location')}
                </Button>
              </Space>
            </Paragraph>
          }
        />
      </Card>

      <Modal
        title={
          home.locId
            ? intl.get('User.Button.switchLocation')
              .d('Switch Location')
            : intl.get('User.Button.selectLocation')
              .d('Select Location')
        }
        centered
        footer={null}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        destroyOnClose={true} // to make "autoFocus" in "LocationModal" always work
      >
        <LocationModal
          onLocationSelected={location => handleLocationSelected(location)}
        />
      </Modal>
    </div>
  );
}
