import { FileOutlined, FilterOutlined, FilterTwoTone, FolderOpenOutlined, FolderOutlined, LoadingOutlined } from '@ant-design/icons';
import { Input, Menu, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DESIGNER_PATH, ROOT_PATH, VIEWER_PATH } from '../../../util/routerConstants';
import { isDashboardSubNode, isFormDesignerSubNode, isFormViewerSubNode, parseFormId } from '../../../util/routerUtility';
import { selectHome } from '../../shell/shellSlice';
import { loadAppsAsync, selectFilteredAppNodes, selectFilterText, selectLoading as selectAppTreeLoading, setFilterText } from '../appTreeSlice';

export default function AppTree({
  allowTooltip
}) {

  //
  // hooks
  //

  const home = useSelector(selectHome);
  const appTreeLoading = useSelector(selectAppTreeLoading);
  const dispatch = useDispatch();

  const { userRecKey, locId } = home;
  useEffect(() => {
    if (userRecKey && locId) {
      // dispatch action
      dispatch(loadAppsAsync());
    }
  }, [dispatch, userRecKey, locId]);

  //
  // render
  //

  return (appTreeLoading
    ? <Spinner />
    : <AppMenu allowTooltip={allowTooltip} />
  );
}

function AppMenu({
  allowTooltip
}) {

  //
  // hooks
  //

  // const formListLoading = useSelector(selectFormListLoading);
  const filteredAppNodes = useSelector(selectFilteredAppNodes);
  const filterText = useSelector(selectFilterText);
  const navigate = useNavigate();
  const location = useLocation();
  // use null as initial value to differentiate with UI manual update
  const [openKeysByMenu, setOpenKeysByMenu] = useState(null);
  // use null as initial value to differentiate with UI manual update
  const [selectedKeysByItem, setSelectedKeysByItem] = useState(null);

  const { pathname } = location;
  // console.log('pathname', pathname);
  const [openKeysByPath, selectedKeysByPath] = buildOpenKeysAndSelectedKeysByPath(
    pathname, filteredAppNodes);
  const openKeysByFilter = filterText && filterText.trim().length > 0
    ? filteredAppNodes.map(appNode => (appNode.recKey + ''))
    : [];
  const calculatedOpenKeys = openKeysByFilter.length > 0
    ? openKeysByFilter // first favour: filter
    : openKeysByMenu !== null
      ? openKeysByMenu // second favour: UI, empty is fine
      : openKeysByPath; // last favour: path
  const calculatedSelectedKeys = selectedKeysByItem !== null
    ? selectedKeysByItem // first favour: UI
    : selectedKeysByPath; // second favour: path
  // console.log('calculatedOpenKeys', calculatedOpenKeys);
  // console.log('calculatedSelectedKeys', calculatedSelectedKeys);

  // build menu items
  const menuItems = filteredAppNodes.map(appNode => {
    const appNodeKey = appNode.recKey + '';
    return ({
      key: appNodeKey,
      icon: calculatedOpenKeys.indexOf(appNodeKey) >= 0
        ? <FolderOpenOutlined />
        : <FolderOutlined />,
      // title: appNode.appName,
      label: appNode.appName,
      // build sub menu items
      children: (appNode.subNodes || []).map(subNode => {
        const subNodeKey = buildSubNodeKey(subNode);
        // console.log('subNodeKey', subNodeKey);
        const subNodeTitle = buildSubNodeTitle(subNode);
        // console.log('subNodeTitle', subNodeTitle);
        return ({
          key: subNodeKey,
          icon: <FileOutlined />,
          label: allowTooltip
            ? <Tooltip
              align={{
                points: ['tl', 'tl'],
                offset: [192, -8],
              }}
              title={subNodeTitle}
            >
              {subNodeTitle}
            </Tooltip>
            : subNodeTitle,
          // trick
          object: subNode
        });
      }),
      // // trick
      // object: appNode
    });
  });

  //
  // handlers
  //

  const handleMenuItemClick = (subNodeKey) => {
    // selected keys
    setSelectedKeysByItem([subNodeKey]);
    // find source
    let subNode;
    menuItems.forEach(menuItem => {
      (menuItem.children || []).forEach(subMenuItem => {
        const { key, object } = subMenuItem;
        if (key === subNodeKey) {
          subNode = object;
        }
      });
    });
    console.log('subNode', subNode);
    // navigation
    const linkPath = isFormDesignerSubNode(subNode)
      ? DESIGNER_PATH
      : isFormViewerSubNode(subNode)
        ? (VIEWER_PATH + '/' + subNodeKey)
        : ROOT_PATH;
    navigate(linkPath);
  };

  //
  // render
  //

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch'
    }}>
      <FilterInput />
      <Menu
        style={{ marginTop: 16 }}
        theme="dark"
        mode="inline"
        openKeys={calculatedOpenKeys}
        selectedKeys={calculatedSelectedKeys}
        onOpenChange={(newOpenKeys) => {
          // console.log('newOpenKeys', newOpenKeys);
          setOpenKeysByMenu(newOpenKeys);
        }}
        onClick={({ key }) => handleMenuItemClick(key)}
        items={menuItems}
      />
    </div>
  );
}

function FilterInput() {
  const filterText = useSelector(selectFilterText);
  const appTreeLoading = useSelector(selectAppTreeLoading);
  const dispatch = useDispatch();

  const handleFilterTextChange = (event) => {
    const filterText = event.target.value.trim();
    // console.log('filterText', filterText);
    dispatch(setFilterText(filterText));
  };

  const iconStyle = {
    paddingLeft: 4,
    paddingRight: 8
  };

  return (
    <div style={{
      margin: 16,
      marginBottom: 0,
      opacity: 0.95
    }}>
      <Input
        prefix={filterText
          ? <FilterTwoTone style={iconStyle} />
          : <FilterOutlined style={iconStyle} />}
        allowClear
        defaultValue={filterText}
        placeholder={intl.get('AppTree.Input.filter').d('Filter')}
        onChange={(e) => handleFilterTextChange(e)}
        disabled={appTreeLoading}
      />
    </div >
  );
}

function Spinner() {
  return (
    <Spin
      style={{
        width: '100%',
        padding: 16
      }}
      indicator={
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      }
    />
  );
}

function buildSubNodeKey(subNode) {
  const keyString = subNode.formMeta
    ? (subNode.formMeta.formId) // new policy, use formId
    : (subNode.recKey + ''); // fall back to node rec key, but in string format
  return keyString;
}

function buildSubNodeTitle(subNode) {
  const title = isDashboardSubNode(subNode)
    ? intl.get('App.string.dashboard')
      .d('Dashboard')
    : isFormDesignerSubNode(subNode)
      ? intl.get('App.string.formDesigner')
        .d('FormDesigner')
      : subNode.formMeta
        ? (subNode.formMeta.formName
          + ' ('
          + subNode.formMeta.formId
          + ')')
        : subNode.appName;
  return title;
}

function findParentAppNode(appNodes, subNodeQualifier) {
  const appNode = appNodes.find(appNode => (appNode.subNodes || []).find(subNodeQualifier));
  // use
  return appNode;
}

function buildOpenKeysAndSelectedKeysByPath(path, appNodesCatalog) {
  const openKeys = [];
  const selectedKeys = [];
  if (path.startsWith(VIEWER_PATH)) {
    const formId = parseFormId(path);
    if (formId) {
      // build selected keys
      selectedKeys.push(formId);
      // reverse-search for parent
      const parentAppNode = findParentAppNode(
        appNodesCatalog,
        subNode => buildSubNodeKey(subNode) === formId);
      if (parentAppNode) {
        // build open keys
        openKeys.push(parentAppNode.recKey + '');
      }
    }
  } else if (path.startsWith(DESIGNER_PATH)) {
    // candidate
    let formDesignerSubNode;
    appNodesCatalog.forEach(appNode => {
      formDesignerSubNode =
        // better performance
        (!formDesignerSubNode)
        // search
        && (appNode.subNodes || []).find(
          subNode => isFormDesignerSubNode(subNode));
      if (formDesignerSubNode) {
        // build selected keys
        selectedKeys.push(buildSubNodeKey(formDesignerSubNode));
        // build open keys
        openKeys.push(appNode.recKey + '');
      }
    });
  } else if (path === ROOT_PATH) {
    // TODO
  }

  // use
  // console.log('openKeys', openKeys);
  // console.log('selectedKeys', selectedKeys);
  return [openKeys, selectedKeys];
}