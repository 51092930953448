import { LinkOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { VIEWER_PATH } from '../../../util/routerConstants';
import { selectLoadingReverseMatch, selectReverseMatch } from '../formDetailSlice';

export default function ReverseMatchLink() {

  //
  // hooks
  //

  const loadingReverseMatch = useSelector(selectLoadingReverseMatch);
  const reverseMatch = useSelector(selectReverseMatch);

  //
  // render
  //

  if (loadingReverseMatch) {
    // early return
    return (
      <Spin style={{ paddingTop: 4 }} />
    );
  }

  if (!reverseMatch) {
    // early return
    return null;
  }

  const { formMeta, formmas } = reverseMatch;
  if (!formMeta || !formmas) {
    // early return
    // return null;
    return (
      <Button
        key='linkButton'
        type="dashed"
        icon={<LinkOutlined />}
        disabled
      >
        {intl.get('ReverseMatchLink.Button.notLinked')
          .d('N/A')}
      </Button>
    );
  }

  const formId = formMeta.formId;
  const srcRecKey = formmas.recKey;
  const url = VIEWER_PATH + '/' + formId + '/' + srcRecKey;

  return (
    <Link
      key='link'
      to={url}
    >
      <Button
        key='linkButton'
        type="dashed"
        icon={<LinkOutlined />}
      >
        {formMeta.formName
          + ' (' + formMeta.formId + '): '
          + formmas.docId}
      </Button>
    </Link>
  );
}