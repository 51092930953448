import { unwrapResult } from '@reduxjs/toolkit';
import { Layout, message, Select } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { initLocaleBasedOnCharsetAsync } from '../../../util/commonUtility';
import { ROOT_PATH } from '../../../util/routerConstants';
import { loginAsync, selectCharset, selectLoading, switchCharset } from '../shellSlice';
import LoginForm from './LoginForm';
import Version from './Version';

const { Content, Footer } = Layout;

const pageSetting = window.formAppConfig.loginPageSetting;
const backgroudUrl = window.formAppConfig.serviceEntry
  + '/epbm/api/image/form-h5/branding?resourceId=background';
const logoUrl = window.formAppConfig.serviceEntry
  + '/epbm/api/image/form-h5/branding?resourceId=logo';

const charsets = [
  { key: 'eng', display: 'English' },
  { key: 'zhs', display: '简体中文' },
  { key: 'zht', display: '繁體中文' }
];
const options = charsets.map(charset =>
  <Select.Option key={charset.key}>
    {charset.display}
  </Select.Option>
);

export default function LoginPage() {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const charset = useSelector(selectCharset);
  const loading = useSelector(selectLoading);

  // const login = () => {
  //   history.replace({ pathname: "/" });
  // };

  // handlers
  const performLogin = async (userId, password) => {
    const result = await dispatch(loginAsync({
      userId: userId,
      password: password
    }));
    // console.log('result', result);

    try {
      // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
      const fulfilled = unwrapResult(result);
      console.log('fulfilled', fulfilled);

      // good result (fullied), navigation
      navigate(ROOT_PATH, {
        replace: true
      });
    } catch (error) {
      // bad result (rejected)
      console.error('error', error);
      // prompt
      message.warning(
        intl.get('LoginPage.message.loginFailed')
          .d('Incorrect username or password'));
    }
  };

  return (
    <StyledLayout>
      <Content>
        <StyledFrame>
          <StyledLogo />
          <LoginForm
            loading={loading}
            onLogin={(userId, password) => {
              performLogin(userId, password);
            }}
          />
          {
            pageSetting.showCharset
              ? (
                <div style={{ marginTop: 24 }}>
                  <Select
                    style={{ width: '100%' }}
                    value={charset}
                    disabled={loading}
                    onChange={async (newCharset) => {
                      await initLocaleBasedOnCharsetAsync(newCharset);
                      dispatch(switchCharset(newCharset));
                    }}
                  >
                    {options}
                  </Select>
                </div>
              )
              : null
          }
        </StyledFrame>
      </Content>
      <StyledFooter>
        <Version />
      </StyledFooter>
    </StyledLayout>
  );
}

const StyledLayout = ({ children }) => (
  <Layout style={{
    minHeight: '100vh',
    backgroundImage: pageSetting.showBackground
      ? ('url(' + backgroudUrl + ')')
      : '',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }}>
    {children}
  </Layout>
);

const StyledLogo = () => (
  pageSetting.showLogo
    ? <img
      style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 150,
        paddingBottom: 24
      }}
      src={logoUrl}
      alt="logo"
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = 'logo512.png';
      }}
    />
    : null
);

const StyledFrame = ({ children }) => {
  const innerStyledNode = (
    <div style={{
      backgroundColor: 'transparent',
      margin: 'auto', // fall-back behaviour
      marginTop: pageSetting.formMarginTop,
      marginLeft: pageSetting.formMarginLeft,
      maxWidth: pageSetting.formMaxWidth,
    }}>
      {children}
    </div>
  );

  return (
    pageSetting.showFrame
      ? <div style={{
        background: '#fbfbfb',
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: '#d9d9d9',
        borderRadius: 8,
        margin: 'auto',
        marginTop: '5vh',
        marginLeft: 'auto',
        maxWidth: 320,
        padding: 24,
        paddingTop: 12,
        paddingBottom: 48
      }}>
        {innerStyledNode}
      </div >
      : innerStyledNode
  );
};

const StyledFooter = ({ children }) => (
  <Footer style={{
    textAlign: 'center',
    backgroundColor: 'transparent'
  }}>
    {children}
  </Footer>
);