import { ConfigProvider, message } from 'antd';
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import zhTW from 'antd/es/locale/zh_TW';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import React from "react";
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import './App.less';
import { selectCharset, selectUser } from './features/shell/shellSlice';
import LoginPage from './features/shell/ui/LoginPage';
import MainPage from './features/shell/ui/MainPage';

export default function App() {
  const charset = useSelector(selectCharset);

  // monent i18n
  const momentLocale = getMomentLocate(charset);
  // console.log('momentLocale', momentLocale);
  moment.locale(momentLocale);

  // antd i18n
  const antLocale = getAntLocate(charset);
  // console.log('antLocate', antLocale);
  const map = {
    // eslint-disable-next-line
    theField: '${label}',
    // eslint-disable-next-line
    lenValue: '${len}',
    // eslint-disable-next-line
    maxValue: '${max}',
    // eslint-disable-next-line
    minValue: '${min}'
  }
  // refer to https://ant.design/components/form-cn/#validateMessages
  const validateMessages = {
    required: intl.get('App.message.required', map)
      .d("Please input {theField}"),
    string: {
      len: intl.get('App.message.string.len', map)
        .d("{theField}'s length must be {lenValue}"),
      max: intl.get('App.message.string.max', map)
        .d("{theField}'s max value is {maxValue}"),
      min: intl.get('App.message.string.min', map)
        .d("{theField}'s min value is {minValue}"),
      range: intl.get('App.message.string.range', map)
        .d("{theField} must be between {minValue} and {maxValue}"),
    }
  };

  // message config
  message.config({
    top: '25vh',
    maxCount: 1,
    rtl: false,
  });

  // render
  return (
    <ConfigProvider
      locale={antLocale}
      form={{ validateMessages }}
    // renderEmpty={() => (<div />)}
    >
      <HashRouter>
        <Routes>
          <Route
            path="/login"
            element={<LoginPage />}
          />
          <Route
            // path="/private/*"
            path="/*"
            element={
              // https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f#get-started-upgrading-today
              <RequireAuth redirectTo="/login">
                <MainPage />
              </RequireAuth>
            }
          />
        </Routes>
      </HashRouter>
    </ConfigProvider>
  );
}

function RequireAuth({ children, redirectTo }) {

  //
  // hooks
  //

  const user = useSelector(selectUser);

  //
  // render
  //

  return user
    ? children
    : <Navigate
      to={redirectTo}
      replace
    />;
}

function getAntLocate(charset) {
  return charset === 'zhs'
    ? zhCN
    : charset === 'zht'
      ? zhTW
      : enUS;
}

function getMomentLocate(charset) {
  return charset === 'zhs'
    ? 'zh-cn'
    : charset === 'zht'
      ? 'zh-tw'
      : 'en';
}