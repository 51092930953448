import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { selectHome } from "../shell/shellSlice";

export const sliceName = 'lov';

//
// adapter
//

const entityAdapter = createEntityAdapter({
  selectId: entity => entity.lovId
});

//
// selectors
//

// managed by adapter
export const {
  selectById: selectByLovId
} = entityAdapter.getSelectors(state => state[sliceName]);
// not managed by adapter
export const selectLoading = state => state[sliceName].loading;
// internal use
const {
  selectById: _selectByLovId
} = entityAdapter.getSelectors();

//
// thunks
//

export const searchLovAsync = createAsyncThunk(
  sliceName + '/searchLovAsync',
  async (arg, thunkAPI) => {
    const { lovId, searchText, formFieldMetaWithValues, usedForCriteria } = arg;
    const { getState } = thunkAPI;
    const home = selectHome(getState());

    const response = await axios.post(
      '/form/api/lovs/' + lovId + '/search',
      {
        homeUserId: home.userId,
        homeOrgId: home.orgId,
        homeLocId: home.locId,
        searchText: searchText,
        formFieldMetaWithValues: formFieldMetaWithValues,
        usedForCriteria: usedForCriteria ? true : false
      },
      {
        params: {
          page: 0,
          size: 10
        }
      }
    );
    // console.log('response', response);

    const keyValuePairs = response.data.content;
    return keyValuePairs;
  }
);

//
// slice
//

const _lovSlice = createSlice({
  name: sliceName,
  initialState: entityAdapter.getInitialState({
    // extend
    loading: false
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchLovAsync.pending, (state, action) => {
      // mark
      state.loading = true;
    }).addCase(searchLovAsync.fulfilled, (state, action) => {
      // console.log('action fulfilled', action);
      const { lovId } = action.meta.arg;
      const keyValuePairs = action.payload;
      // current values
      const lovRepo = _selectByLovId(state, lovId);
      // console.log('lovRepo', lovRepo);
      const oldKeyValuePairs = lovRepo?.keyValuePairs || [];
      // console.log('oldKeyValuePairs', oldKeyValuePairs);
      // combine
      const combinedKeyValuePairs = [...oldKeyValuePairs];
      keyValuePairs.forEach(keyValuePair => {
        const match = oldKeyValuePairs.find(
          oldKeyValuePair => oldKeyValuePair.key === keyValuePair.key);
        if (!match) {
          combinedKeyValuePairs.push(keyValuePair);
        }
      });
      // console.log('newKeyValuePairs', newKeyValuePairs);
      // state, managed by adapter
      entityAdapter.upsertOne(state, {
        lovId: lovId,
        keyValuePairs: keyValuePairs,
        combinedKeyValuePairs: combinedKeyValuePairs
      });
      // mark
      state.loading = false;
    }).addCase(searchLovAsync.rejected, (state, action) => {
      console.log('action rejected', action);
      // mark
      state.loading = false;
    });
  }
});

//
// reducer
//

export default _lovSlice.reducer;