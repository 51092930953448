import { unwrapResult } from '@reduxjs/toolkit';
import { Button, message } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StandardPopconfirm from '../../../components/StandardPopconfirm';
import { ACTION_ACKNOWLEDGE } from '../formConstants';
import { forwardAsync, selectAcknowledgeApplicable, selectRecordAvailable, selectWorking } from '../formDetailSlice';

export default function AcknowledgeButton() {

  //
  // hooks
  //

  const navigate = useNavigate();
  const recordAvailable = useSelector(selectRecordAvailable);
  const acknowledgeApplicable = useSelector(selectAcknowledgeApplicable);
  const working = useSelector(selectWorking);
  const dispatch = useDispatch();

  //
  // handlers
  //

  const executeAcknowledge = async () => {
    const result = await dispatch(forwardAsync({
      actionName: ACTION_ACKNOWLEDGE
    }));

    try {
      // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
      const fulfilled = unwrapResult(result);
      console.log('fulfilled', fulfilled);

      // prompt
      message.success(
        intl.get('InputView.message.succeeded')
          .d('Action performed successfully'));
      // back
      navigate(-1);
    } catch (error) {
      // bad result
      console.error('error', error);
      // prompt
      message.error((error || {}).message);
    }
  };

  //
  // render
  //

  if (!acknowledgeApplicable) {
    return null;
  }

  return (
    <StandardPopconfirm
      key='acknowledgePopconfirm'
      title={intl.get('SourcePageHeader.Popconfirm.confirmAcknowledge')
        .d('Confirm "Mark as Acknowledged"')}
      okType="danger"
      onConfirm={() => executeAcknowledge()}
    >
      <Button
        type='danger'
        loading={working}
        disabled={!recordAvailable}
      >
        {intl.get('SourcePageHeader.Button.acknowledge')
          .d('Acknowledge')}
      </Button>
    </StandardPopconfirm>
  );
}
