import { Card, Layout, Menu } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import PasswordForm from './PasswordForm';

const { Sider, Content } = Layout;

export default function SettingsView() {
  return (
    <Card
      style={{ margin: 24 }}
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 16,
        paddingBottom: 16
      }}
      bordered={false}
    >
      <Layout style={{ background: 'white' }}>
        <Sider width={220}>
          <Menu
            style={{ height: '100%' }}
            mode="inline"
            defaultSelectedKeys={['security']}
            items={[{
              key: 'security',
              label: intl.get('Setting.MenuItem.changePassword')
                .d('Change Password')
            }]}
          />
        </Sider>
        <Content style={{
          paddingLeft: 40,
          paddingRight: 40,
          paddingTop: 8,
          paddingBottom: 32
        }}>
          <PasswordForm title={intl.get('Setting.MenuItem.changePassword')
            .d('Change Password')} />
        </Content>
      </Layout>
    </Card>
  );
}
