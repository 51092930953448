import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { DESIGNER_DETAIL_VIEW_PATH, DESIGNER_PATH } from '../../../util/routerConstants';
import DesignerPageHeader from './DesignerPageHeader';
import DesignerDetailView from './DesignerDetailView';
import DesignerTableView from './DesignerTableView';

export default function Designer() {
  return (
    <div>
      <DesignerPageHeader />
      <Routes>
        <Route
          // path={DESIGNER_DETAIL_VIEW_PATH}
          // path="/:formId"
          path={DESIGNER_DETAIL_VIEW_PATH.substring(DESIGNER_PATH.length)}
          element={<DesignerDetailView />}
        />
        <Route
          // path={DESIGNER_PATH}
          // path="/"
          path={DESIGNER_PATH.substring(DESIGNER_PATH.length)}
          element={<DesignerTableView />}
        />
      </Routes>
    </div>
  );
}