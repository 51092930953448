import { MailOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Form, Input, message, Modal, Row, Space } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import LovSelect from '../../lov/ui/LovSelect';
import { ACTION_INFORM } from '../formConstants';
import { forwardAsync, selectRecordAvailable, selectWorking } from '../formDetailSlice';

const { TextArea } = Input;

export default function InformButton() {

  //
  // hooks
  //

  const recordAvailable = useSelector(selectRecordAvailable);
  const [modalOpen, setModalOpen] = useState(false);

  //
  // render
  //

  return (
    <>
      <Button
        type='primary'
        icon={<MailOutlined />}
        disabled={!recordAvailable}
        onClick={() => setModalOpen(true)}
      >
        {intl.get('SourcePageHeader.Button.forward')
          .d('Forward')}
      </Button>

      <Modal
        title={intl.get('SourcePageHeader.Modal.title.inform')
          .d('Inform')}
        centered
        footer={null}
        open={modalOpen}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
      >
        <InformView onCancel={() => setModalOpen(false)} />
      </Modal>
    </>
  );
}

function InformView({
  onCancel
}) {

  //
  // hooks
  //

  const working = useSelector(selectWorking);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  //
  // handlers
  //

  const executeInform = async () => {
    const values = await form.validateFields();
    // console.debug('values', values);
    const { toUserIds, comment } = values;

    const result = await dispatch(forwardAsync({
      toUserIds,
      comment,
      actionName: ACTION_INFORM
    }));

    try {
      // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
      const fulfilled = unwrapResult(result);
      console.log('fulfilled', fulfilled);

      // prompt
      message.success(
        intl.get('InputView.message.succeeded')
          .d('Action performed successfully'));
      // dispose, if applicable
      onCancel && onCancel();
    } catch (error) {
      // bad result
      console.error('error', error);
      // prompt
      message.error((error || {}).message);
    }
  };

  //
  // render
  //

  return (
    <Form
      form={form}
      layout="vertical"
    >
      <Form.Item
        name="toUserIds"
        label={intl.get('InformView.Select.toUserIds')
          .d('Select Users')}
        rules={[{ required: true }]}
      >
        <LovSelect
          fieldMeta={{ lovId: 'USER' }}
          mode="multiple"
        />
      </Form.Item>
      <Form.Item
        name="comment"
        label={intl.get('InformView.TextArea.comment')
          .d('Comment')}
      >
        <TextArea
          placeholder={intl.get('InformView.TextArea.comment')
            .d('Comment')}
          rows={5}
        />
      </Form.Item>
      <Row>
        <div style={{
          display: 'block',
          marginLeft: 'auto'
        }}>
          <Space direction="horizontal">
            <Button
              loading={working}
              onClick={() => onCancel && onCancel()}
            >
              {intl.get('InformView.Button.cancel')
                .d('Cancel')}
            </Button>
            <Button
              type="primary"
              loading={working}
              onClick={() => executeInform()}
            >
              {intl.get('InformView.Button.send')
                .d('Send')}
            </Button>
          </Space>
        </div>
      </Row>
    </Form>
  );
}