import { CopyrightCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { loadVersionMapAsync, selectLoading, selectVersionMap } from '../shellSlice';

export default function Version() {
  // hooks
  const versionMap = useSelector(selectVersionMap);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();

  // handlers
  const handleOpenChange = (open) => {
    if (!open) {
      return;
    }

    if (loading // loading
      || versionMap) { // loaded
      return;
    }

    // dispatch
    dispatch(loadVersionMapAsync());
  };

  return (
    <div>
      {/* {intl.get('Version.string.copyright').d('Copyright') + ' '} */}
      <CopyrightCircleOutlined />
      {' Enterprise Browser - '}
      <Tooltip
        title={
          loading
            ? intl.get('Version.string.loading').d('Loading')
            : versionMap
              ? (intl.get('Version.string.webServiceVersion').d('Web Service Version')
                + ' '
                + versionMap.FORM.build.version)
              : '-'
        }
        trigger="click"
        onOpenChange={handleOpenChange}>
        <span>
          {intl.get('Version.string.version').d('Version') + ' '}
          {process.env.REACT_APP_VERSION}
        </span>
      </Tooltip>
    </div>
  );
}
