import { Checkbox } from 'antd';
import React from 'react';

export default function FieldMetaViewingCell({
  fieldMeta,
  valueArrayIndex
}) {

  // console.log('fieldMeta', fieldMeta);
  const computedValue = valueArrayIndex === undefined
    ? fieldMeta.value
    : (fieldMeta.values && fieldMeta.values.length > valueArrayIndex)
      ? fieldMeta.values[valueArrayIndex]
      : null;
  // console.log('computedValue', computedValue);

  if ('C' === fieldMeta.componentType) {
    // checkbox special case 
    return (
      <Checkbox
        disabled={true}
        checked={'Y' === computedValue}
      >
        {(!valueArrayIndex) && fieldMeta.fieldLabel}
      </Checkbox>
    );
  } else if ('R' === fieldMeta.componentType
    || 'S' === fieldMeta.componentType
    || 'L' === fieldMeta.componentType) {
    let value = computedValue;
    if (fieldMeta.constants) {
      fieldMeta.constants.forEach(constant => {
        if (constant.value === computedValue) {
          value = 'L' === fieldMeta.componentType
            ? (constant.value + ' - ' + constant.label)
            : constant.label;
        }
      });
    }
    return <div>{value}</div>;
  } else if ('T' === fieldMeta.componentType) {
    return (computedValue || '').split('\n').map((token, index) => {
      return (
        <div
          key={fieldMeta.recKey + '-' + index}
          style={{ wordBreak: "break-word" }}
        >
          {token}<br />
        </div>
      );
    });
  } else {
    // simple value
    return (
      <div style={{ wordBreak: "break-word" }}>
        {computedValue}
      </div>
    );
  }
}