export function isDashboardSubNode(subNode) {
  return subNode && subNode.appCode === 'W900900';
}

export function isFormDesignerSubNode(subNode) {
  return subNode && subNode.appCode === 'W900001';
}

export function isFormViewerSubNode(subNode) {
  return subNode && subNode.formMeta && subNode.formMeta.formId;
}

export function parseFormId(path) {
  // console.log('path', path);
  const leftIndex = path.indexOf('/', 1);
  // console.log('leftIndex', leftIndex);
  if (leftIndex >= 0) {
    const rightIndex = path.indexOf('/', leftIndex + 1);
    // console.log('rightIndex', rightIndex);
    const formId = rightIndex > 0
      ? path.substring(leftIndex + 1, rightIndex)
      : path.substring(leftIndex + 1);
    // console.log('formId', formId);
    // use
    return formId;
  } else {
    // not applicable
    return null;
  }
}

export function parseFormIdAndRecKey(path) {
  // console.log('path', path);
  let leftIndex = path.indexOf('/', 1);
  // console.log('leftIndex', leftIndex);
  if (leftIndex < 0) {
    // formId not applicable, early return
    return [null, null];
  }

  // formId
  let rightIndex = path.indexOf('/', leftIndex + 1);
  // console.log('rightIndex', rightIndex);
  const formId = rightIndex > 0
    ? path.substring(leftIndex + 1, rightIndex)
    : path.substring(leftIndex + 1);
  // console.log('formId', formId);

  // recKey
  leftIndex = rightIndex; // shift
  if (leftIndex < 0) {
    // recKey not applicable, early return
    return [formId, null];
  }

  rightIndex = path.indexOf('/', leftIndex + 1);
  // console.log('rightIndex', rightIndex);
  const recKey = rightIndex > 0
    ? path.substring(leftIndex + 1, rightIndex)
    : path.substring(leftIndex + 1);
  // console.log('recKey', recKey);

  // use
  return [formId, recKey];
}

// export function findAppFormMeta(formIdOrRecKey, appNodes) {
//   let formMeta = null;
//   appNodes.forEach(appNode => {
//     if (!formMeta) {
//       (appNode.subNodes || []).forEach(subNode => {
//         if (!formMeta
//           && subNode.formMeta
//           && (subNode.formMeta.formId === (formIdOrRecKey + '')
//             || (subNode.formMeta.recKey + '') === (formIdOrRecKey + ''))) {
//           formMeta = subNode.formMeta;
//         }
//       });
//     }
//   });
//   return formMeta;
// }
