import { UploadOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, message, Space, Table, Upload } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import StandardPopconfirm from '../../../components/StandardPopconfirm';
import {
  appendAttachmentAsync, detachAttachmentAsync, prepareAttachmentUrlAsync,
  selectAppendingAttachment, selectDetachingAttachment, selectFormSource, selectGeneratingFetchKey
} from '../formDetailSlice';

export default function AttachmentView() {

  //
  // hooks
  //

  const formSource = useSelector(selectFormSource);
  const appendingAttachment = useSelector(selectAppendingAttachment);
  const dispatch = useDispatch();

  //
  // handlers
  //

  const appendAttachment = async (file) => {
    const result = await dispatch(appendAttachmentAsync({
      file: file
    }));

    try {
      // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
      const attachmentRecord = unwrapResult(result);
      console.log('attachmentRecord', attachmentRecord);
      // prompt
      message.success(intl.get('AttachmentView.message.uploadSucceeded')
        .d('Upload succeeded'));
    } catch (error) {
      // bad result
      console.error('error', error);
      // prompt
      message.error((error || {}).message);
    }
  };

  //
  // render
  //

  const attachments = (formSource || {}).attachments || [];
  const columns = buildColumns(recKey => (<LinkButtons attachmentRecKey={recKey} />));

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{ width: '100%' }}
    >
      <Table
        size="small"
        columns={columns}
        dataSource={attachments}
        rowKey="recKey"
        bordered
        pagination={false}
      />
      <Upload
        showUploadList={false}
        beforeUpload={(file) => {
          console.log('file', file);
          // if ((file.size / 1024) > 10) { // 10KB
          if ((file.size / 1024 / 1024) > 10) { // 10MB
            // prompt
            message.warn(intl.get('AttachmentView.message.maxFileSize', { maxFileSize: '10MB' })
              .d('Max file size allowed is {maxFileSize}'));
            return false;
          } else {
            return true;
          }
        }}
        // https://github.com/react-component/upload#customrequest
        customRequest={({ file }) => {
          appendAttachment(file);
        }}
      >
        <Button
          type="primary"
          loading={appendingAttachment}
          icon={<UploadOutlined />}
        >
          {intl.get('AttachmentView.Button.upload')
            .d('Upload')}
        </Button>
      </Upload>
    </Space>
  );
}

function LinkButtons({ attachmentRecKey }) {

  //
  // hooks
  //

  const detachingAttachment = useSelector(selectDetachingAttachment);
  const generatingFetchKey = useSelector(selectGeneratingFetchKey);
  const dispatch = useDispatch();

  //
  // handlers
  //

  const performOpen = async (preview) => {
    const result = await dispatch(prepareAttachmentUrlAsync({
      attachmentRecKey: attachmentRecKey,
      preview: preview
    }));

    try {
      // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
      const attachmentUrl = unwrapResult(result);
      console.log('attachmentUrl', attachmentUrl);

      // open
      window.open(attachmentUrl, '_blank');
    } catch (error) {
      // bad result
      console.error('error', error);
    }
  };

  const detachAttachment = async () => {
    const result = await dispatch(detachAttachmentAsync({
      attachmentRecKey
    }));

    try {
      // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
      unwrapResult(result);
    } catch (error) {
      // bad result
      console.error('error', error);
      // prompt
      message.error((error || {}).message);
    }
  };

  //
  // calculation
  //

  const buttons = [];
  // preview
  buttons.push(
    <Button
      key="preview"
      type="link"
      size="small"
      style={{ margin: 0, padding: 0 }}
      disabled={generatingFetchKey}
      onClick={() => performOpen(true)}
    >
      {intl.get('AttachmentView.Button.preview')
        .d('Preview')}
    </Button>
  );

  // download
  buttons.push(
    <Button
      key="download"
      type="link"
      size="small"
      style={{ margin: 0, padding: 0 }}
      disabled={generatingFetchKey}
      onClick={() => performOpen(false)}
    >
      {intl.get('AttachmentView.Button.download')
        .d('Download')}
    </Button>
  );

  // delete
  buttons.push(
    <StandardPopconfirm
      key="delete"
      title={intl.get('AttachmentView.Popconfirm.confirmDelete')
        .d('Confirm Delete')}
      disabled={generatingFetchKey || detachingAttachment}
      onConfirm={() => detachAttachment()}
    >
      <Button
        type="link"
        size="small"
        style={{ margin: 0, padding: 0 }}
        danger
        disabled={generatingFetchKey || detachingAttachment}
      >
        {intl.get('AttachmentView.Button.delete')
          .d('Delete')}
      </Button>
    </StandardPopconfirm>
  );

  //
  // render
  //

  return (
    <Space
      direction="horizontal"
      size="small"
    >
      {buttons}
    </Space>
  );
}

function buildColumns(renderRecKey) {
  const columns = [];

  columns.push({
    title: intl.get('AttachmentView.column.fileName')
      .d('File Name'),
    dataIndex: 'name'
  });

  columns.push({
    title: intl.get('AttachmentView.column.createdBy')
      .d('Created By'),
    width: '20%',
    render: (value, row) => (
      row.createUserName + ' (' + row.createUserId + ')'
    )
  });

  columns.push({
    title: intl.get('AttachmentView.column.createdAt')
      .d('Created At'),
    width: '20%',
    dataIndex: 'createDateString'
  });

  columns.push({
    title: intl.get('AttachmentView.column.action')
      .d('Action'),
    width: '22%',
    dataIndex: 'recKey',
    render: (recKey) => renderRecKey ? renderRecKey(recKey) : recKey
  });

  return columns;
}