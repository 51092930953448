import { createAsyncThunk, createDraftSafeSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logout, selectHome } from '../shell/shellSlice';

export const sliceName = 'appTree';

//
// slice of state
//

const sliceState = {
  originalAppNodes: [],
  fullFormMetas: [],
  filterText: null,
  loading: false
};

//
// selectors
//

export const selectOriginalAppNodes = state => state[sliceName].originalAppNodes;
export const selectFullFormMetas = state => state[sliceName].fullFormMetas;
export const selectFilterText = state => state[sliceName].filterText;
export const selectLoading = state => state[sliceName].loading;

// calculated
export const selectFilteredAppNodes = createDraftSafeSelector(
  [selectOriginalAppNodes, selectFilterText],
  (originalAppNodes, filterText) => {
    const smoothedFilterText = (filterText || '').trim().toLowerCase();
    const filteredAppNodes = [];
    // examine and collect
    originalAppNodes.forEach(appNode => {
      // copy
      const appNodeCopy = Object.assign({}, appNode);
      // double down
      appNodeCopy.subNodes = appNodeCopy.subNodes || [];
      // filter, if applicable
      if (smoothedFilterText) {
        appNodeCopy.subNodes = appNodeCopy.subNodes.filter(subNode => {
          const candidate = subNode.formMeta
            ? (subNode.formMeta.formName + ' ' + subNode.formMeta.formId)
            : subNode.appName;
          return candidate.toLowerCase().indexOf(smoothedFilterText) >= 0;
        });
      }
      // add to list, if applicable
      if (appNodeCopy.subNodes.length > 0) {
        filteredAppNodes.push(appNodeCopy);
      }
    });
    // use
    return filteredAppNodes;
  }
);

//
// thunks
//

const notLoading = (arg, thunkAPI) => {
  const { getState } = thunkAPI;
  const loading = selectLoading(getState());
  return !loading;
};

export const loadAppsAsync = createAsyncThunk(
  sliceName + '/loadAppsAsync',
  async (arg, thunkAPI) => {
    const { getState } = thunkAPI;
    const home = selectHome(getState());
    const { userRecKey, locId, charset } = home;
    // appNodes
    const appNodesResponse = await axios.get(
      '/form/api/users/' + userRecKey + '/apps',
      {
        params: { locId, charset }
      });
    // console.log('appNodesResponse', appNodesResponse);
    const appNodes = appNodesResponse.data;

    // formMetas
    const formMetasResponse = await axios.get(
      '/form/api/forms',
      {
        params: { charset }
      }
    );
    // console.log('formMetasResponse', formMetasResponse);
    const formMetas = formMetasResponse.data;

    return { appNodes, formMetas };
  },
  {
    condition: notLoading
  }
);

//
// slice
//

const _appTreeSlice = createSlice({
  name: sliceName,
  initialState: sliceState,
  reducers: {
    setFilterText(state, action) {
      const text = action.payload;
      // state
      state.filterText = text;
    }
  },
  extraReducers: (builder) => {
    // load apps
    builder.addCase(loadAppsAsync.pending, (state, action) => {
      // state
      state.originalAppNodes = [];
      state.fullFormMetas = [];
      state.filterText = null;
      // mark
      state.loading = true;
    }).addCase(loadAppsAsync.fulfilled, (state, action) => {
      const { appNodes: originalAppNodes, formMetas: fullFormMetas } = action.payload;
      // state
      state.originalAppNodes = originalAppNodes;
      state.fullFormMetas = fullFormMetas;
      state.filterText = null;
      // mark
      state.loading = false;
    }).addCase(loadAppsAsync.rejected, (state, action) => {
      console.log('action rejected', action);
      // mark
      state.loading = false;
    });

    // respond to shell
    builder.addCase(logout, (state, action) => {
      // reset
      return sliceState;
    });
  }
});

//
// actions
//

export const { setFilterText } = _appTreeSlice.actions;

//
// reducer
//

export default _appTreeSlice.reducer;

//
// private functions
//
