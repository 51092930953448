import { FilterOutlined } from '@ant-design/icons';
import { Input, Space, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { loadLocationsAsync, selectAllLocations, selectLoading } from '../shellSlice';

const { Text } = Typography;

export default function LocationModal({ onLocationSelected }) {
  // handlers
  const locations = useSelector(selectAllLocations);
  const loading = useSelector(selectLoading);
  const [filterText, setFilterText] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (locations && locations.length > 0) {
      // early return
      return;
    }
    // dispatch action
    dispatch(loadLocationsAsync());
  }, [dispatch, locations]);

  // handlers
  const handleFilterTextChange = (event) => {
    setFilterText(event.target.value.trim());
  };

  const handleFilerPressEnter = () => {
    if (filteredLocations.length === 1) {
      // quick select
      onLocationSelected(filteredLocations[0]);
    }
  }

  const columns = [{
    title: intl.get('LocationModal.Table.Column.locName').d('Loc Name'),
    dataIndex: 'name', // refer to api
    width: '35%'
  }, {
    title: intl.get('LocationModal.Table.Column.locId').d('Loc ID'),
    dataIndex: 'locId'
  }, {
    title: intl.get('LocationModal.Table.Column.orgName').d('Org Name'),
    dataIndex: 'orgName',
    width: '35%'
  }, {
    title: intl.get('LocationModal.Table.Column.orgId').d('Org ID'),
    dataIndex: 'orgId'
  }];

  const filteredLocations = filterText
    ? (locations || []).filter(location =>
      location.locId.toLowerCase().indexOf(filterText.toLowerCase()) >= 0
      || location.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0
      || location.orgId.toLowerCase().indexOf(filterText.toLowerCase()) >= 0
      || location.orgName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0
    )
    : locations;

  return (
    <Space
      style={{
        width: '100%',
        height: '100%'
      }}
      direction="vertical"
      size="middle"
    >
      <Input
        style={{ margin: 1 }}
        prefix={<FilterOutlined />}
        placeholder={intl.get('LocationModal.Input.filter').d('Filter')}
        value={filterText}
        onChange={(e) => handleFilterTextChange(e)}
        onPressEnter={() => handleFilerPressEnter()}
        autoFocus
      />
      <Table
        style={{
          padding: 1, // pick an odd number to make border drawn properly
          width: '100%',
          height: 320
        }}
        bordered
        scroll={{ y: 240 }}
        size="small"
        pagination={false}
        footer={() => (
          <Text
            style={{ fontSize: 11 }}
            type="secondary"
          >
            {intl.get('LocationModal.Table.Footer', {
              filtered: filteredLocations.length,
              total: locations.length
            }).d('Displaying {filtered} of {total}')}
          </Text>
        )}
        rowKey="recKey"
        loading={loading}
        columns={columns}
        dataSource={filteredLocations}
        onRow={record => {
          return {
            onClick: () => onLocationSelected(record)
          };
        }}
      />
    </Space>
  );
}