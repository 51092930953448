import { Form, Table } from 'antd';
import React, { useRef } from 'react';
import intl from 'react-intl-universal';
import { buildRows } from '../formUtility';
import FieldMetaEditingCell from './FieldMetaEditingCell';
import FieldMetaViewingCell from './FieldMetaViewingCell';

const designerBase = 18;
// const multiplier = 375;
const multiplier = (window && window.screen)
  ? (window.screen.width || 1000) / 2
  : 500;
// console.log('window.screen.width', window.screen.width);
// console.log('multiplier', multiplier);
const requiredBackground = '#ffccc7';

export default function FieldMetasTableView({
  fieldMetas,
  editable,
  renderRecKeyColumn,
  hideRecKeyColumn,
  supplementalFormRefs,
  // callbacks
  onFormCreated,
  // others
  ...others
}) {

  // hooks
  const formRef = useRef();
  // make form
  const [form] = Form.useForm();
  // update reference value
  formRef.current = form;

  // calculation
  const [columns, narrow] = buildColumns(
    fieldMetas, editable, renderRecKeyColumn, hideRecKeyColumn,
    [formRef, ...(supplementalFormRefs || [])]
  );
  const rows = buildRows(fieldMetas);

  // render
  const table = (
    <Table
      size="small"
      columns={columns}
      dataSource={rows}
      pagination={false}
      scroll={narrow ? {} : { x: 1 }}
      bordered
      {...others}
    />
  );

  // console.log('editable', editable);

  if (!editable) {
    // use without form
    return table;
  }

  // call back, if applicable
  if (editable && onFormCreated) {
    onFormCreated(form);
  }

  // use with form
  return (
    <Form form={form}>
      {table}
    </Form>
  );
}

function validateFieldMetas(fieldMetas) {
  return fieldMetas
    && fieldMetas.length > 0
    && fieldMetas[0].values
  // && fieldMetas[0].values.length > 0
}

function calculateTotalDgW(fieldMetas) {
  let totalDgW = 0;

  if (!fieldMetas) {
    // not applicable, early return
    return totalDgW;
  }

  // accumulate
  fieldMetas.forEach(fieldMeta =>
    totalDgW += (fieldMeta.recKey <= 0 ? 0 : fieldMeta.dgW)
  );

  // use
  return totalDgW;
}

function getRowIdentifier(fieldMetas, index) {
  const recKeyMeta = fieldMetas.find(fieldMeta => fieldMeta.recKey === 0);
  return recKeyMeta
    && recKeyMeta.values
    && recKeyMeta.values.length > index
    ? recKeyMeta.values[index]
    : null
}

function buildColumns(
  fieldMetas,
  editable,
  renderRecKeyColumn,
  hideRecKeyColumn,
  formRefs
) {

  const columns = [];
  let narrow = true;

  if (!validateFieldMetas(fieldMetas)) {
    // not applicable, early return
    return [columns, narrow];
  }

  // calculate total dgW
  const totalDgW = calculateTotalDgW(fieldMetas);
  // console.log('totalDgW', totalDgW);
  narrow = totalDgW <= designerBase; // override
  // console.log('narrow', narrow);

  // build columns
  fieldMetas.filter(fieldMeta => fieldMeta.recKey >= 0) // filter out post query meta
    .forEach(fieldMeta => {
      const column = {
        dataIndex: fieldMeta.recKey,
        fixed: narrow
          ? false // not applicable
          : fieldMeta.recKey === 0
            ? 'right' // action column fixed to right
            : columns.length === 0
              ? 'left' // first column fixed to left
              : false,
        title: fieldMeta.recKey === 0
          ? intl.get('FieldMetasTableView.Table.Column.action').d('Action')
          : fieldMeta.fieldLabel,
        width: fieldMeta.recKey === 0
          ? 80
          : narrow
            ? (fieldMeta.dgW / (totalDgW + 2) * 100).toFixed(4) + '%' // use percentage
            : (fieldMeta.dgW / designerBase * multiplier),
        align: (fieldMeta.componentType === 'N' && !editable) ? 'right' : 'left',
        render: (text, record, index) => fieldMeta.recKey === 0
          ? renderRecKeyColumn
            ? renderRecKeyColumn(text, record, index)
            : (<div>{text}</div>)
          : editable
            ? {
              children: <FieldMetaEditingCell
                fieldMeta={fieldMeta}
                valueArrayIndex={index}
                formItemIdSuffix={getRowIdentifier(fieldMetas, index)}
                liveFormRefs={formRefs}
              />,
              props: {
                style: {
                  background: fieldMeta.editable
                    && fieldMeta.required
                    ? requiredBackground
                    : 'white',
                }
              }
            }
            : <FieldMetaViewingCell
              fieldMeta={fieldMeta}
              valueArrayIndex={index}
            />
      }
      // skip, if applicable
      if (fieldMeta.recKey === 0
        && hideRecKeyColumn) {
        return;
      }
      // add to list
      columns.push(column);
    });

  // use
  return [columns, narrow];
}
