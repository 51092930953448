import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Form, Input, message, Space, Table, Tag } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import StandardPopconfirm from '../../../components/StandardPopconfirm';
import { selectHome } from '../../shell/shellSlice';
import { appendCommentAsync, selectAppendingComment, selectFormSource } from '../formDetailSlice';

const { TextArea } = Input;

export default function CommentView() {

  //
  // hooks
  //

  const [users, setUsers] = useState([]);
  const [form] = Form.useForm();
  const home = useSelector(selectHome);
  const formSource = useSelector(selectFormSource);
  const appendingComment = useSelector(selectAppendingComment);
  const dispatch = useDispatch();

  //
  // handlers
  //

  const removeUser = (removingUser) => {
    const newUsers = users.filter(user => user !== removingUser);
    setUsers(newUsers);
  };

  const submitComment = async (comment) => {
    const result = await dispatch(appendCommentAsync({
      comment,
      users
    }));

    try {
      // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
      const fulfilled = unwrapResult(result);
      console.log('fulfilled', fulfilled);

      // prompt
      message.success(intl.get('CommentView.message.commentSubmitted')
        .d('Comment submitted'));
      // reset form
      form.resetFields();
    } catch (error) {
      // bad result
      console.error('error', error);
    }
  };

  //
  // render
  //

  const renderUser = (record) => {
    const text = record.createUserName + ' (' + record.createUserId + ')';
    return record.createUserId === home.userId
      ? text
      : (
        <Button
          style={{ padding: 0 }}
          type="link"
          disabled={appendingComment}
          onClick={() => {
            const newUsers = users.slice();
            newUsers.push({
              userId: record.createUserId,
              userName: record.createUserName
            });
            setUsers(newUsers);
          }}
        >
          {text}
        </Button>
      );
  };

  const columns = buildColumns(renderUser);
  const comments = (formSource || {}).comments || [];

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: '100%' }}
    >
      <Table
        style={{ whiteSpace: "pre-wrap" }}
        size="small"
        columns={columns}
        dataSource={comments}
        rowKey="recKey"
        bordered
        pagination={false}
      />
      <CommentForm
        form={form}
        users={users}
        working={appendingComment}
        onRemoveUser={(user) => removeUser(user)}
        onSubmitComment={(comment) => submitComment(comment)}
      />
    </Space>
  );
}

function CommentForm({
  form,
  users,
  working,
  onRemoveUser,
  onSubmitComment
}) {

  return (
    <Form
      form={form}
      layout="vertical"
    >
      {
        users.length === 0
          ? null
          :
          <Form.Item
            label={intl.get('CommentView.string.notify')
              .d('Notify')}
          >
            <UserTags
              users={users}
              onRemoveUser={onRemoveUser}
            />
          </Form.Item>
      }
      <Form.Item
        name="comment"
        rules={[{ required: true }]}
        label={intl.get('CommentView.string.newComment')
          .d('New Comment')}
      >
        <TextArea
          placeholder={intl.get('CommentView.TextArea.comment')
            .d('Comment')}
          rows={4}
          disabled={working}
        />
      </Form.Item>
      <StandardPopconfirm
        title={intl.get('CommentView.Popconfirm.confirmSubmit')
          .d('Confirm Submit')}
        disabled={working}
        onConfirm={async () => {
          const values = await form.validateFields();
          if (values && onSubmitComment) {
            onSubmitComment(values.comment);
          }
        }}
      >
        <Button
          type="primary"
          loading={working}
        >
          {intl.get('CommentView.Button.submit')
            .d('Submit')}
        </Button>
      </StandardPopconfirm>
    </Form>
  );
}

function UserTags({
  users,
  onRemoveUser
}) {

  return (
    <Space direction="horizontal">
      {users.map(user =>
        <Tag
          key={user.userId}
          color="blue"
          closable
          onClose={() => onRemoveUser && onRemoveUser(user)}
        >
          {user.userName} ({user.userId})
        </Tag>
      )}
    </Space>
  );
}

function buildColumns(renderUser) {
  const columns = [];

  columns.push({
    title: intl.get('CommentView.column.user')
      .d('User'),
    dataIndex: 'createUserName',
    render: (value, record) => renderUser
      ? renderUser(record)
      : value,
    width: '25%'
  });

  columns.push({
    title: intl.get('CommentView.column.formComment').d('Comment'),
    dataIndex: 'formComment'
  });

  columns.push({
    title: intl.get('CommentView.column.createDateString').d('Time'),
    dataIndex: 'createDateString',
    width: '25%'
  });

  return columns;
}
