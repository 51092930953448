import { createSlice } from '@reduxjs/toolkit';
import { loginAsync, logout, switchCharset, switchLocation } from "../shell/shellSlice";
import { ITEM_KEY_ACCESS_TOKEN, ITEM_KEY_CHARSET, ITEM_KEY_HOME } from "./storageConstants";

export const sliceName = 'storage';

const _storageSlice = createSlice({
  name: sliceName,
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      const { token, epUserWithDefLoc } = action.payload;
      const { access_token: accessToken } = token;
      const { defaultLocation } = epUserWithDefLoc;
      // persistence
      const home = {
        userRecKey: epUserWithDefLoc.recKey,
        userId: epUserWithDefLoc.userId,
        userName: epUserWithDefLoc.name,
        locId: defaultLocation && defaultLocation.locId,
        locName: defaultLocation && defaultLocation.name,
        orgId: defaultLocation && defaultLocation.orgId,
        orgName: defaultLocation && defaultLocation.orgName
      }
      localStorage.setItem(ITEM_KEY_HOME, JSON.stringify(home));
      localStorage.setItem(ITEM_KEY_ACCESS_TOKEN, accessToken);
    });

    builder.addCase(switchCharset, (state, action) => {
      const charset = action.payload;
      // persistence
      localStorage.setItem(ITEM_KEY_CHARSET, charset);
    });

    builder.addCase(switchLocation, (state, action) => {
      const location = action.payload;
      // persistence
      const persistedHomeString = localStorage.getItem(ITEM_KEY_HOME);
      const persistedHome = persistedHomeString && JSON.parse(persistedHomeString);
      if (persistedHome) {
        const home = Object.assign(persistedHome, {
          locId: location.locId,
          locName: location.name,
          orgId: location.orgId,
          orgName: location.orgName
        });
        localStorage.setItem(ITEM_KEY_HOME, JSON.stringify(home));
      }
    });

    builder.addCase(logout, (state, action) => {
      // persistence
      localStorage.removeItem(ITEM_KEY_HOME);
      localStorage.removeItem(ITEM_KEY_ACCESS_TOKEN);
      // leave charset alone
    });
  }
});

//
// reducer
//

export default _storageSlice.reducer;