import { createAsyncThunk, createDraftSafeSelector, createSlice, isFulfilled, isPending, isRejected, unwrapResult } from "@reduxjs/toolkit";
import axios from 'axios';
import moment from 'moment';
import numeral from 'numeral';
import { DATE_FORMAT, TIME_FORMAT } from '../shell/shellConstants';
import { selectHome } from '../shell/shellSlice';
import { ACTION_ACKNOWLEDGE, ACTION_APPROVE, ACTION_ENDORSE, ACTION_INFORM, ACTION_POST, ACTION_REJECT, ACTION_UNDO_POST, ACTION_WITHDRAW } from './formConstants';
import { assembleDynamicConstants, buildMentionString, findFieldMetaByColumnName, findLineInfo, findTimeStamp } from './formUtility';

export const sliceName = 'formDetail';

//
// slice of state
//

const sliceState = {
  anchor: null, // {formRecKey, srcRecKey, nodeRecKey, fromFormId, todoType, todoRecKey}
  // respect anchor
  formSource: null,
  // loaded as affiliates
  lineFieldMetas: null, // fieldMetaWithValueArray[]
  reverseMatch: null,
  // lazy
  reports: null,
  // marks
  unsaved: false,
  loadingFormSource: false,
  loadingLineFieldMetas: false,
  loadingReverseMatch: false,
  loadingReports: false,
  working: false, // shared for post/approval/forward actions
  generatingFetchKey: false,
  appendingAttachment: false,
  detachingAttachment: false,
  appendingComment: false
};

//
// selectors
//

export const selectAnchor = state => state[sliceName].anchor;
export const selectFormSource = state => state[sliceName].formSource;
export const selectLineFieldMetas = state => state[sliceName].lineFieldMetas;
export const selectReverseMatch = state => state[sliceName].reverseMatch;
export const selectReports = state => state[sliceName].reports;

export const selectUnsaved = state => state[sliceName].unsaved;
export const selectLoadingFormSource = state => state[sliceName].loadingFormSource;
export const selectLoadingLineFieldMetas = state => state[sliceName].loadingLineFieldMetas;
export const selectLoadingReverseMatch = state => state[sliceName].loadingReverseMatch;
export const selectLoadingReports = state => state[sliceName].loadingReports;
export const selectWorking = state => state[sliceName].working;
export const selectGeneratingFetchKey = state => state[sliceName].generatingFetchKey;
export const selectAppendingAttachment = state => state[sliceName].appendingAttachment;
export const selectDetachingAttachment = state => state[sliceName].detachingAttachment;
export const selectAppendingComment = state => state[sliceName].appendingComment;

// calculated
export const selectLoadingOverall = createDraftSafeSelector(
  [selectLoadingFormSource, selectLoadingLineFieldMetas, selectLoadingReverseMatch],
  (loadingFormSource, loadingLineFieldMetas, loadingReverseMatch) => {
    return loadingFormSource || loadingLineFieldMetas || loadingReverseMatch;
  }
);
export const selectWorkingOnAttachment = createDraftSafeSelector(
  [selectGeneratingFetchKey, selectAppendingAttachment, selectDetachingAttachment],
  (generatingFetchKey, appendingAttachment, detachingAttachment) => {
    return generatingFetchKey || appendingAttachment || detachingAttachment;
  }
);
export const selectRecordAvailable = createDraftSafeSelector(
  [selectAnchor],
  (anchor) => {
    const { formRecKey, srcRecKey } = (anchor || {});
    return formRecKey > 0 && srcRecKey > 0;
  }
);
export const selectAcknowledgeApplicable = createDraftSafeSelector(
  [selectRecordAvailable, selectAnchor],
  (recordAvailable, anchor) => {
    const { todoType } = (anchor || {});
    return recordAvailable
      && ('I' === todoType
        || 'C' === todoType
        || 'F' === todoType);
  }
);
export const selectRecallApplicable = createDraftSafeSelector(
  [selectRecordAvailable, selectAnchor],
  (recordAvailable, anchor) => {
    const { fromFormId, todoRecKey } = (anchor || {});
    return recordAvailable
      && 'APPROVED' === fromFormId
      && todoRecKey;
  }
);
export const selectActionIgnored = createDraftSafeSelector(
  [selectRecordAvailable, selectAnchor],
  (recordAvailable, anchor) => {
    const { fromFormId, todoType } = (anchor || {});
    return recordAvailable
      && ('APPROVED' === fromFormId
        || 'I' === todoType
        || 'C' === todoType
        || 'F' === todoType);
  }
);

//
// thunks
//

const notWorking = (arg, thunkAPI) => {
  const { getState } = thunkAPI;
  const working = selectWorking(getState());
  return !working;
};

export const saveAsync = createAsyncThunk(
  sliceName + '/saveAsync',
  async (arg, thunkAPI) => {
    const { fieldMetaWithValues, fieldMetaWithValueArrays } = arg;
    const { getState, dispatch, rejectWithValue } = thunkAPI;
    const home = selectHome(getState());
    const anchor = selectAnchor(getState());
    const formSource = selectFormSource(getState());
    const { formRecKey, srcRecKey } = anchor;

    const creatingNew = numeral(srcRecKey || 0).value() <= 0;
    const timeStamp = findTimeStamp(formSource.fieldMetaWithValues);

    const url = '/form/api/forms/'
      + formRecKey
      + (creatingNew
        // create
        ? '/sources'
        // update
        : ('/sources/' + srcRecKey));
    const data = {
      userId: home.userId,
      orgId: home.orgId,
      locId: home.locId,
      timeStamp: timeStamp,
      formFieldMetaWithValues: fieldMetaWithValues,
      formFieldMetaWithValueArrays: fieldMetaWithValueArrays
    }; // refer to w/s code

    try {
      const response = creatingNew
        // create
        ? await axios.post(url, data)
        // update
        : await axios.put(url, data);
      // console.log('response', response);

      // reload, if applicable
      if (!creatingNew) {
        dispatch(loadFormDetailAsync());
      }

      const remoteRecKey = response.data;
      return remoteRecKey;
    } catch (err) {
      // detailed error for post requests
      // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
      return rejectWithValue(err.response.data);
    }
  },
  { condition: notWorking }
);

export const postAsync = createAsyncThunk(
  sliceName + '/postAsync',
  async (arg, thunkAPI) => {
    const { fieldMetaWithValues, fieldMetaWithValueArrays, actionName } = arg;
    const { getState, dispatch, rejectWithValue } = thunkAPI;
    const home = selectHome(getState());
    const anchor = selectAnchor(getState());
    const formSource = selectFormSource(getState());
    const { formRecKey, srcRecKey } = anchor;

    const timeStamp = findTimeStamp(formSource.fieldMetaWithValues);

    try {
      const response = await axios.post(
        '/form/api/forms/'
        + formRecKey
        + '/sources/' + srcRecKey
        + (actionName === ACTION_POST
          ? '/post'
          : actionName === ACTION_UNDO_POST
            ? '/undo-post'
            : actionName === ACTION_WITHDRAW
              ? '/withdraw'
              : '/suspend'),
        {
          timeStamp: timeStamp,
          userId: home.userId,
          formFieldMetaWithValues: fieldMetaWithValues,
          formFieldMetaWithValueArrays: fieldMetaWithValueArrays
        } // refer to w/s code
      );
      // console.log('response', response);

      // reload
      dispatch(loadFormDetailAsync());

      const remoteRecKey = response.data;
      return remoteRecKey;
    } catch (err) {
      // detailed error for post requests
      // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
      return rejectWithValue(err.response.data);
    }
  },
  { condition: notWorking }
);

export const approveAsync = createAsyncThunk(
  sliceName + '/approveAsync',
  async (arg, thunkAPI) => {
    const {
      fieldMetaWithValues, fieldMetaWithValueArrays,
      comment, endorsedUserIds, endorseBefore, actionName
    } = arg;
    const { getState, dispatch, rejectWithValue } = thunkAPI;
    const home = selectHome(getState());
    const anchor = selectAnchor(getState());
    const { formRecKey, srcRecKey, nodeRecKey } = anchor;

    try {
      const response = await axios.post(
        '/form/api/forms/'
        + formRecKey
        + '/sources/'
        + srcRecKey
        + (actionName === ACTION_APPROVE
          ? '/approve'
          : actionName === ACTION_REJECT
            ? '/reject'
            : actionName === ACTION_ENDORSE
              ? endorseBefore
                ? '/endorse-before'
                : '/endorse-after'
              : ''),
        {
          userId: home.userId,
          orgId: home.orgId,
          locId: home.locId,
          nodeRecKey: nodeRecKey,
          comment: comment,
          endorsedUserIds: endorsedUserIds,
          formFieldMetaWithValues: fieldMetaWithValues,
          formFieldMetaWithValueArrays: fieldMetaWithValueArrays
        } // refer to w/s code
      );
      // console.log('response', response);

      // reset
      dispatch(setAnchor(null));

      const remoteRecKey = response.data;
      return remoteRecKey;
    } catch (err) {
      // detailed error for post requests
      // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
      return rejectWithValue(err.response.data);
    }
  },
  { condition: notWorking }
);

export const forwardAsync = createAsyncThunk(
  sliceName + '/forwardAsync',
  async (arg, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const home = selectHome(getState());
    const anchor = selectAnchor(getState());
    const { formRecKey, srcRecKey, todoRecKey } = anchor;
    const { comment = '', toUserIds = [], actionName } = arg;

    try {
      const response = await axios.post(
        '/form/api/forms/'
        + formRecKey
        + '/sources/'
        + srcRecKey
        + (actionName === ACTION_INFORM
          ? '/inform'
          : actionName === ACTION_ACKNOWLEDGE
            ? '/acknowledge'
            : '-'),
        {
          userId: home.userId,
          orgId: home.orgId,
          locId: home.locId,
          todoRecKey: todoRecKey,
          comment: comment,
          toUserIds: toUserIds
        } // refer to w/s code
      );
      // console.log('response', response);

      const remoteRecKey = response.data;
      return remoteRecKey;
    } catch (err) {
      // detailed error for post requests
      // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
      return rejectWithValue(err.response.data);
    }
  },
  { condition: notWorking }
);

export const recallAsync = createAsyncThunk(
  sliceName + '/recallAsync',
  async (arg, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const home = selectHome(getState());
    const anchor = selectAnchor(getState());
    const { formRecKey, srcRecKey, todoRecKey } = anchor;

    try {
      const response = await axios.post(
        '/form/api/forms/'
        + formRecKey
        + '/sources/'
        + srcRecKey
        + '/recall-workflow',
        {
          userId: home.userId,
          todoRecKey: todoRecKey
        } // refer to w/s code
      );
      // console.log('response', response);

      const remoteRecKey = response.data;
      return remoteRecKey;
    } catch (err) {
      // detailed error for post requests
      // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
      return rejectWithValue(err.response.data);
    }
  },
  { condition: notWorking }
);

export const loadFormDetailAsync = createAsyncThunk(
  sliceName + '/loadFormDetailAsync',
  async (arg, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const home = selectHome(getState());
    const anchor = selectAnchor(getState());
    const { formRecKey, srcRecKey, nodeRecKey } = anchor;

    const response = await axios.get(
      '/form/api/forms/' + formRecKey + '/sources/' + srcRecKey,
      {
        params: {
          pseudoUserId: home.userId,
          pseudoOrgId: home.orgId,
          pseudoLocId: home.locId,
          charset: home.charset,
          nodeRecKey: nodeRecKey || 0
        }
      }
    );
    // console.log('response', response);

    // for reverseMatch
    const formSource = response.data;
    const { fieldMetaWithValues } = formSource;
    const [refFormIdMeta, refFormDocIdMeta] = findFieldMetaByColumnName(
      fieldMetaWithValues,
      ['REF_FORM_ID', 'REF_FORM_DOC_ID']);
    if (refFormIdMeta && refFormDocIdMeta) {
      dispatch(loadReverseMatchAsync({
        formRecKey,
        srcRecKey
      }));
    }

    // for lineFieldMetas
    const [lineId] = findLineInfo(fieldMetaWithValues);
    // console.log('lineId', lineId);
    if (lineId) {
      dispatch(loadLineFieldMetasAsync({
        formRecKey,
        srcRecKey,
        nodeRecKey,
        lineId
      }));
    }

    // use
    return formSource;
  },
  {
    condition: (arg, thunkAPI) => {
      const { getState } = thunkAPI;
      const loadingFormSource = selectLoadingFormSource(getState());
      return !loadingFormSource;
    }
  }
);

export const loadReportsAsync = createAsyncThunk(
  sliceName + '/loadReportsAsync',
  async (arg, thunkAPI) => {
    const { getState } = thunkAPI;
    const anchor = selectAnchor(getState());
    const { formRecKey, srcRecKey } = anchor;

    const response = await axios.get(
      '/form/api/forms/'
      + formRecKey
      + '/sources/'
      + srcRecKey
      + '/reports');
    // console.log('response', response);

    const reports = response.data;
    return reports;
  },
  {
    condition: (arg, thunkAPI) => {
      const { getState } = thunkAPI;
      const loadingReports = selectLoadingReports(getState());
      return !loadingReports;
    }
  }
);

export const appendAttachmentAsync = createAsyncThunk(
  sliceName + '/appendAttachmentAsync',
  async (arg, thunkAPI) => {
    const { file } = arg;
    const { getState, rejectWithValue } = thunkAPI;
    const home = selectHome(getState());
    const anchor = selectAnchor(getState());
    const { srcRecKey } = anchor;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(
        '/form/api/attachments',
        formData,
        {
          params: {
            srcRecKey: srcRecKey,
            userId: home.userId,
            orgId: home.orgId,
            locId: home.locId,
          },
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      // console.log('response', response);

      const attachmentRecKey = response.data;
      const attachmentRecord = {
        recKey: attachmentRecKey,
        name: file.name,
        createUserId: home.userId,
        createUserName: home.userName,
        createDateString: moment(new Date()).format(TIME_FORMAT)
      };
      return attachmentRecord;
    } catch (err) {
      // detailed error for post requests
      // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
      return rejectWithValue(err.response.data);
    }
  }
);

export const detachAttachmentAsync = createAsyncThunk(
  sliceName + '/detachAttachmentAsync',
  async (arg, thunkAPI) => {
    const { attachmentRecKey } = arg;
    const { getState, rejectWithValue } = thunkAPI;
    const home = selectHome(getState());
    const anchor = selectAnchor(getState());
    const { srcRecKey, nodeRecKey } = anchor;

    try {
      const response = await axios.post(
        '/form/api/attachments/'
        + attachmentRecKey + '/detach',
        {}, // empty data
        {
          params: {
            srcRecKey: srcRecKey,
            nodeRecKey: nodeRecKey || 0,
            userId: home.userId,
            orgId: home.orgId,
            locId: home.locId,
          }
        }
      );
      // console.log('response', response);

      const remoteRecKey = response.data;
      return remoteRecKey;
    } catch (err) {
      // detailed error for post requests
      // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
      return rejectWithValue(err.response.data);
    }
  }
);

export const appendCommentAsync = createAsyncThunk(
  sliceName + '/appendCommentAsync',
  async (arg, thunkAPI) => {
    const { comment, users } = arg;
    const { getState, rejectWithValue } = thunkAPI;
    const home = selectHome(getState());
    const anchor = selectAnchor(getState());
    const { formRecKey, srcRecKey } = anchor;

    try {
      const response = await axios.post(
        '/form/api/forms/'
        + formRecKey
        + '/sources/'
        + srcRecKey
        + '/add-comment',
        {
          fromUserId: home.userId,
          comment: comment,
          toUserIds: users.map(user => user.userId)
        }
      );
      // console.log('response', response);

      const remoteRecKey = response.data;
      const now = new Date();
      const commentRecord = {
        recKey: -1 * (remoteRecKey + now.getTime()),
        createUserId: home.userId,
        createUserName: home.userName,
        formComment: buildMentionString(users) + comment,
        createDateString: moment(now).format(TIME_FORMAT)
      };
      return commentRecord;
    } catch (err) {
      // detailed error for post requests
      // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
      return rejectWithValue(err.response.data);
    }
  }
);

export const prepareReportUrlAsync = createAsyncThunk(
  sliceName + '/prepareReportUrlAsync',
  async (arg, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const anchor = selectAnchor(getState());
    const { formRecKey, srcRecKey } = anchor;
    const { reportRecKey } = arg;

    const result = await dispatch(generateFetchKeyAsync({
      resourceRecKey: reportRecKey
    }));

    // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
    const fetchKey = unwrapResult(result);
    // console.log('fetchKey', fetchKey);

    // build url
    const reportUrl = axios.defaults.baseURL
      + '/form/api/pdfs/' + reportRecKey
      + ('?formRecKey=' + formRecKey)
      + ('&srcRecKey=' + srcRecKey)
      + ('&fetchKey=' + fetchKey);
    // console.debug('reportUrl', reportUrl);

    return reportUrl;
  }
);

export const prepareAttachmentUrlAsync = createAsyncThunk(
  sliceName + '/prepareAttachmentUrlAsync',
  async (arg, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { attachmentRecKey, preview } = arg;

    const result = await dispatch(generateFetchKeyAsync({
      resourceRecKey: attachmentRecKey
    }));

    // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
    const fetchKey = unwrapResult(result);
    // console.log('fetchKey', fetchKey);

    // build url
    const attachmentUrl = axios.defaults.baseURL
      + '/epbm/api/attachment/' + attachmentRecKey
      + ('?preview=' + preview)
      + ('&fetchKey=' + fetchKey);
    // console.debug('attachmentUrl', attachmentUrl);

    return attachmentUrl;
  }
);

//
// private thunks
//

const loadLineFieldMetasAsync = createAsyncThunk(
  sliceName + '/loadLineFieldMetasAsync',
  async (arg, thunkAPI) => {
    const { lineId } = arg;
    const { getState } = thunkAPI;
    const home = selectHome(getState());
    const anchor = selectAnchor(getState());
    const { formRecKey, srcRecKey, nodeRecKey } = anchor;

    const response = await axios.get(
      '/form/api/forms/' + formRecKey
      + '/sources/' + srcRecKey
      + '/lines',
      {
        params: {
          lineId: lineId,
          homeUserId: home.userId,
          homeOrgId: home.orgId,
          homeLocId: home.locId,
          charset: home.charset,
          nodeRecKey: nodeRecKey || 0,
          columnCount: 100
        }
      });
    // console.log('response', response);

    const lineFieldMetas = response.data;
    // for dynamic constants
    assembleDynamicConstants(lineFieldMetas);
    return lineFieldMetas;
  },
  // intentionally no control
  { condition: () => true }
);

const loadReverseMatchAsync = createAsyncThunk(
  sliceName + '/loadReverseMatchAsync',
  async (arg, thunkAPI) => {
    const { getState } = thunkAPI;
    const anchor = selectAnchor(getState());
    const { formRecKey, srcRecKey } = anchor;

    const response = await axios.get(
      '/form/api/forms/'
      + formRecKey
      + '/sources/'
      + srcRecKey
      + '/reverse-match'
    );
    // console.log('response', response);

    const reverseMatch = response.data;
    return reverseMatch;
  },
  // intentionally no control
  { condition: () => true }
);

const generateFetchKeyAsync = createAsyncThunk(
  sliceName + '/generateFetchKeyAsync',
  async (arg, thunkAPI) => {
    const { resourceRecKey } = arg;

    const response = await axios.post(
      '/epbm/api/security/generate-fetch-key',
      {}, // empty body
      { params: { resourceRecKey } }
    );
    // console.log('response', response);

    const fetchKeyBean = response.data;
    const { fetchKey } = fetchKeyBean;
    return fetchKey;
  },
  {
    condition: (arg, thunkAPI) => {
      const { getState } = thunkAPI;
      const generatingFetchKey = selectGeneratingFetchKey(getState());
      return !generatingFetchKey;
    }
  }
);

//
// slice
//

const definedWorkThunks = [
  saveAsync,
  postAsync,
  approveAsync,
  forwardAsync,
  recallAsync
];
const isDefinedWorkPendingAction = isPending(...definedWorkThunks);
const isDefinedWorkFulfilledAction = isFulfilled(...definedWorkThunks);
const isDefinedWorkRejectedAction = isRejected(...definedWorkThunks);

const _formDetailSlice = createSlice({
  name: sliceName,
  initialState: sliceState,
  reducers: {
    setAnchor(state, action) {
      state.anchor = action.payload;
      // reset
      state.formSource = null;
      state.lineFieldMetas = null;
      state.reverseMatch = null;
      state.reports = null;
    },
    createFormLine(state, action) {
      state.lineFieldMetas.forEach(fieldMeta => {
        let value = '';
        if (fieldMeta.recKey === 0) {
          value = -1 * new Date().getTime();
        } else if ('Y' === fieldMeta.defFlg) { // apply defaults to non-rec-key fields
          if ('N' === fieldMeta.componentType) {
            if (fieldMeta.defValue) {
              value = numeral(fieldMeta.defValue).value();
            } else {
              // fall back
              value = numeral(fieldMeta.minValue || 0).value();
            }
          } else if ('D' === fieldMeta.componentType
            || 'M' === fieldMeta.componentType) {
            const format = 'D' === fieldMeta.componentType
              ? DATE_FORMAT
              : TIME_FORMAT;
            if (fieldMeta.defValue) {
              value = fieldMeta.defValue;
            } else {
              // fall back
              value = moment(new Date(), format).format(format);
            }
          } else { // for all other types of components
            value = fieldMeta.defValue;
          }
        }
        // to values, if applicable
        if (fieldMeta.values) {
          fieldMeta.values.push(value);
          // mark
          state.unsaved = true;
        }
      });
    },
    deleteFormLine(state, action) {
      const recKeyValue = action.payload;
      const recKeyMeta = state.lineFieldMetas.find(
        fieldMeta => fieldMeta.recKey === 0);
      if (recKeyMeta && recKeyMeta.values) {
        const rowIndex = recKeyMeta.values.indexOf(recKeyValue);
        // console.debug('rowIndex', rowIndex);
        if (rowIndex >= 0) {
          state.lineFieldMetas.forEach(fieldMeta => {
            // filter values, if applicable
            if (fieldMeta.values) {
              fieldMeta.values = fieldMeta.values
                .filter((value, index) => index !== rowIndex);
              // mark
              state.unsaved = true;
            }
          });
        }
      }
    },
    updateFormLine(state, action) {
      const updatingLineFieldMetas = action.payload;
      state.lineFieldMetas.forEach(lineFieldMeta => {
        if (lineFieldMeta.editable) { // double down on editable
          updatingLineFieldMetas.forEach(updatingFieldMeta => {
            if (lineFieldMeta.recKey === updatingFieldMeta.recKey) {
              lineFieldMeta.values = updatingFieldMeta.values;
            }
          });
        }
      });
      // mark
      state.unsaved = true;
    },
    updateFormHeader(state, action) {
      const updatingFieldMetas = action.payload;
      const { fieldMetaWithValues } = state.formSource;
      fieldMetaWithValues.forEach(fieldMetaWithValue => {
        if (fieldMetaWithValue.editable) { // double down on editable
          updatingFieldMetas.forEach(updatingFieldMeta => {
            if (fieldMetaWithValue.recKey === updatingFieldMeta.recKey) {
              fieldMetaWithValue.value = updatingFieldMeta.value;
            }
          });
        }
      });
      // mark
      state.unsaved = true;
    }
  },
  extraReducers: (builder) => {
    // formSource
    builder.addCase(loadFormDetailAsync.pending, (state, action) => {
      // state
      state.formSource = null;
      // mark
      state.loadingFormSource = true;
      state.unsaved = false;
    }).addCase(loadFormDetailAsync.fulfilled, (state, action) => {
      const formSource = action.payload;
      // state
      state.formSource = formSource;
      // mark
      state.loadingFormSource = false;
    }).addCase(loadFormDetailAsync.rejected, (state, action) => {
      console.log('action rejected', action);
      // mark
      state.loadingFormSource = false;
    });

    // lineFieldMetas
    builder.addCase(loadLineFieldMetasAsync.pending, (state, action) => {
      // state
      state.lineFieldMetas = null;
      // mark
      state.loadingLineFieldMetas = true;
    }).addCase(loadLineFieldMetasAsync.fulfilled, (state, action) => {
      const lineFieldMetas = action.payload;
      // state
      state.lineFieldMetas = lineFieldMetas;
      // mark
      state.loadingLineFieldMetas = false;
    }).addCase(loadLineFieldMetasAsync.rejected, (state, action) => {
      console.log('action rejected', action);
      // mark
      state.loadingLineFieldMetas = false;
    });

    // reverseMatch
    builder.addCase(loadReverseMatchAsync.pending, (state, action) => {
      // state
      state.reverseMatch = null;
      // mark
      state.loadingReverseMatch = true;
    }).addCase(loadReverseMatchAsync.fulfilled, (state, action) => {
      const reverseMatch = action.payload;
      // state
      state.reverseMatch = reverseMatch;
      // mark
      state.loadingReverseMatch = false;
    }).addCase(loadReverseMatchAsync.rejected, (state, action) => {
      console.log('action rejected', action);
      // mark
      state.loadingReverseMatch = false;
    });

    // reports
    builder.addCase(loadReportsAsync.pending, (state, action) => {
      // state
      state.reports = [];
      // mark
      state.loadingReports = true;
    }).addCase(loadReportsAsync.fulfilled, (state, action) => {
      const reports = action.payload;
      // state
      state.reports = reports;
      // mark
      state.loadingReports = false;
    }).addCase(loadReportsAsync.rejected, (state, action) => {
      console.log('action rejected', action);
      // mark
      state.loadingReports = false;
    });

    // fetch key
    builder.addCase(generateFetchKeyAsync.pending, (state, action) => {
      // mark
      state.generatingFetchKey = true;
    }).addCase(generateFetchKeyAsync.fulfilled, (state, action) => {
      // mark
      state.generatingFetchKey = false;
    }).addCase(generateFetchKeyAsync.rejected, (state, action) => {
      console.log('action rejected', action);
      // mark
      state.generatingFetchKey = false;
    });

    // append attachment
    builder.addCase(appendAttachmentAsync.pending, (state, action) => {
      // mark
      state.appendingAttachment = true;
    }).addCase(appendAttachmentAsync.fulfilled, (state, action) => {
      const attachmentRecord = action.payload;
      // state
      state.formSource.attachments.push(attachmentRecord);
      // mark
      state.appendingAttachment = false;
    }).addCase(appendAttachmentAsync.rejected, (state, action) => {
      console.log('action rejected', action);
      // mark
      state.appendingAttachment = false;
    });

    // detach attachment
    builder.addCase(detachAttachmentAsync.pending, (state, action) => {
      // mark
      state.detachingAttachment = true;
    }).addCase(detachAttachmentAsync.fulfilled, (state, action) => {
      const { attachmentRecKey } = action.meta.arg;
      // state
      state.formSource.attachments = state.formSource.attachments
        .filter(attachment => attachment.recKey !== attachmentRecKey);
      // mark
      state.detachingAttachment = false;
    }).addCase(detachAttachmentAsync.rejected, (state, action) => {
      console.log('action rejected', action);
      // mark
      state.detachingAttachment = false;
    });

    // append comment
    builder.addCase(appendCommentAsync.pending, (state, action) => {
      // mark
      state.appendingComment = true;
    }).addCase(appendCommentAsync.fulfilled, (state, action) => {
      const commentRecord = action.payload;
      // state
      state.formSource.comments.push(commentRecord);
      // mark
      state.appendingComment = false;
    }).addCase(appendCommentAsync.rejected, (state, action) => {
      console.log('action rejected', action);
      // mark
      state.appendingComment = false;
    });

    // work thunks
    builder.addMatcher(isDefinedWorkPendingAction, (state, action) => {
      // mark
      state.working = true;
    }).addMatcher(isDefinedWorkFulfilledAction, (state, action) => {
      // mark
      state.working = false;
    }).addMatcher(isDefinedWorkRejectedAction, (state, action) => {
      console.log('action rejected', action);
      // mark
      state.working = false;
    });
  }
});

//
// actions
//

export const {
  setAnchor,
  createFormLine, deleteFormLine, updateFormLine,
  updateFormHeader
} = _formDetailSlice.actions;

//
// reducer
//

export default _formDetailSlice.reducer;
