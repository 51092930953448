import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SOURCE_DETAIL_VIEW_PATH, SOURCE_TABLE_VIEW_PATH, VIEWER_PATH } from '../../../util/routerConstants';
import SourceDetailView from './SourceDetailView';
import SourcePageHeader from './SourcePageHeader';
import SourceTableView from './SourceTableView';

export default function FormViewer() {

  //
  // render
  //

  return (
    <div>
      <SourcePageHeader />

      <Routes>
        <Route
          // path={SOURCE_DETAIL_VIEW_PATH}
          // path="/:formId/:recKey"
          path={SOURCE_DETAIL_VIEW_PATH.substring(VIEWER_PATH.length)}
          element={<SourceDetailView />}
        />
        <Route
          // path={SOURCE_TABLE_VIEW_PATH}
          // path="/:formId"
          path={SOURCE_TABLE_VIEW_PATH.substring(VIEWER_PATH.length)}
          element={<SourceTableView />}
        />
      </Routes>
    </div>
  );
}
