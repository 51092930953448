import { Table } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { selectFormSource } from '../formDetailSlice';

export default function HistoryView() {

  //
  // hooks
  //

  const formSource = useSelector(selectFormSource);

  //
  // calculation
  //

  const { wfStatusCompacts } = (formSource || {});

  //
  // render
  //

  return (
    <Table
      style={{ whiteSpace: "pre-wrap" }}
      size="small"
      columns={buildColumns()}
      dataSource={wfStatusCompacts || []}
      rowKey="recKey"
      bordered
      pagination={false}
    />
  );
}

function buildColumns() {
  const columns = [];

  columns.push({
    title: intl.get('HistoryView.column.source')
      .d('Source'),
    dataIndex: 'source',
    width: '15%'
  });

  columns.push({
    title: intl.get('HistoryView.column.completeUserName').d('Complete User Name'),
    dataIndex: 'completeUserName',
    width: '15%'
  });

  columns.push({
    title: intl.get('HistoryView.column.completeDateString').d('Date'),
    dataIndex: 'completeDateString',
    width: '25%'
  });

  columns.push({
    title: intl.get('HistoryView.column.completeFlgString').d('Result'),
    dataIndex: 'completeFlgString',
    width: '15%'
  });

  columns.push({
    title: intl.get('HistoryView.column.comments').d('Comments'),
    dataIndex: 'comments'
  });

  return columns;
}