import { Radio } from 'antd';
import React from 'react';

const RadioGroup = Radio.Group;

export default function ConstantRadio({ fieldMeta, ...others }) {
  const radios = fieldMeta.constants
    ? fieldMeta.constants.map(constant => (
      <div key={constant.value}>
        <Radio
          // key={constant.value}
          value={constant.value}>
          {constant.label}
        </Radio>
      </div>
    ))
    : [];

  return (
    <RadioGroup
      style={{ width: '100%' }}
      {...others}
    >
      {radios}
    </RadioGroup>
  );
}