import { RollbackOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, message } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StandardPopconfirm from '../../../components/StandardPopconfirm';
import { recallAsync, selectRecallApplicable, selectRecordAvailable, selectWorking } from '../formDetailSlice';

export default function RecallButton() {

  //
  // hooks
  //

  const navigate = useNavigate();
  const recordAvailable = useSelector(selectRecordAvailable);
  const recallApplicable = useSelector(selectRecallApplicable);
  const working = useSelector(selectWorking);
  const dispatch = useDispatch();

  //
  // handlers
  //

  const executeRecall = async () => {
    const result = await dispatch(recallAsync());

    try {
      // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
      const fulfilled = unwrapResult(result);
      console.log('fulfilled', fulfilled);

      // prompt
      message.success(
        intl.get('InputView.message.succeeded')
          .d('Action performed successfully'));
      // back
      navigate(-1);
    } catch (error) {
      // bad result
      console.error('error', error);
      // prompt
      message.error((error || {}).message);
    }
  };

  //
  // render
  //

  if (!recallApplicable) {
    return null;
  }

  return (
    <StandardPopconfirm
      key='recallPopconfirm'
      title={intl.get('SourcePageHeader.Popconfirm.confirmRecall')
        .d('Confirm Recall')}
      okType="danger"
      onConfirm={() => executeRecall()}
    >
      <Button
        type="danger"
        loading={working}
        disabled={!recordAvailable}
        icon={<RollbackOutlined />}
      >
        {intl.get('SourcePageHeader.Button.recall')
          .d('Recall')}
      </Button>
    </StandardPopconfirm>
  );
}