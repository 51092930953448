import { DeleteOutlined, DislikeOutlined, FastBackwardOutlined, LikeOutlined, SaveOutlined, StepBackwardOutlined, StepForwardOutlined, TeamOutlined } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import StandardPopconfirm from '../../../components/StandardPopconfirm';
import { ACTION_APPROVE, ACTION_ENDORSE, ACTION_POST, ACTION_REJECT, ACTION_SAVE, ACTION_SUSPEND, ACTION_UNDO_POST, ACTION_WITHDRAW } from '../formConstants';
import { selectActionIgnored, selectAnchor, selectFormSource } from '../formDetailSlice';
import { checkActionEnabled } from '../formUtility';
import ApprovalView from './ApprovalView';

const ButtonGroup = Button.Group;

export default function ActionButtons({
  onSave,
  onPost,
  onUndoPost,
  onWithdraw,
  onSuspend,
  onApprove,
  onReject,
  onEndorse
}) {

  //
  // hooks
  //

  const [approvalModalMode, setApprovalModalMode] = useState(null);
  const actionIgnored = useSelector(selectActionIgnored);
  const anchor = useSelector(selectAnchor);
  const formSource = useSelector(selectFormSource);

  //
  // render
  //

  if (actionIgnored) {
    // return null;
    return (<div />);
  }

  const { nodeRecKey, todoType } = (anchor || {});
  const { actionConfigs } = (formSource || {});

  const actionComponents = [];
  // save button
  const saveEnabled = checkActionEnabled(actionConfigs, ACTION_SAVE);
  actionComponents.push(
    <StandardPopconfirm
      key='save'
      title={intl.get('ActionButtons.Popconfirm.confirmSave')
        .d('Confirm Save')}
      disabled={!saveEnabled}
      onConfirm={() => onSave && onSave()}
    >
      <Button
        type='primary'
        icon={<SaveOutlined />}
        disabled={!saveEnabled}
      >
        {intl.get('ActionButtons.Button.save')
          .d('Save')}
      </Button>
    </StandardPopconfirm>
  );

  if (nodeRecKey) { // workflow
    const approveEnabled = checkActionEnabled(actionConfigs, ACTION_APPROVE);
    actionComponents.push(
      <Button
        key='approve'
        type='primary'
        icon={<LikeOutlined />}
        disabled={!approveEnabled}
        onClick={() => setApprovalModalMode(ACTION_APPROVE)}
      >
        {intl.get('ActionButtons.Button.approve')
          .d('Approve')}
      </Button>
    );

    const rejectEnabled = checkActionEnabled(actionConfigs, ACTION_REJECT);
    actionComponents.push(
      <Button
        key='reject'
        type='primary'
        icon={<DislikeOutlined />}
        danger
        disabled={!rejectEnabled}
        onClick={() => setApprovalModalMode(ACTION_REJECT)}
      >
        {intl.get('ActionButtons.Button.reject')
          .d('Reject')}
      </Button>
    );

    const endorseEnabled = checkActionEnabled(actionConfigs, ACTION_ENDORSE)
      && todoType !== 'T';
    actionComponents.push(
      <Button
        key='endorse'
        icon={<TeamOutlined />}
        disabled={!endorseEnabled}
        onClick={() => setApprovalModalMode(ACTION_ENDORSE)}
      >
        {intl.get('ActionButtons.Button.endorse')
          .d('Endorse')}
      </Button>
    );
  } else {
    // normal viewing
    const innerButtons = [];
    // post button
    const postEnabled = checkActionEnabled(actionConfigs, ACTION_POST);
    innerButtons.push(
      <StandardPopconfirm
        key='post'
        title={intl.get('ActionButtons.Popconfirm.confirmPost')
          .d('Confirm Post')}
        disabled={!postEnabled}
        onConfirm={() => onPost && onPost()}
      >
        <Button
          type='primary'
          icon={<StepForwardOutlined />}
          disabled={!postEnabled}
        >
          {intl.get('ActionButtons.Button.post')
            .d('Post')}
        </Button>
      </StandardPopconfirm>
    );
    // undo post button
    const undoPostEnabled = checkActionEnabled(actionConfigs, ACTION_UNDO_POST);
    innerButtons.push(
      <StandardPopconfirm
        key='undoPost'
        title={intl.get('ActionButtons.Popconfirm.confirmUndoPost')
          .d('Confirm Undo Post')}
        disabled={!undoPostEnabled}
        onConfirm={() => onUndoPost && onUndoPost()}
      >
        <Button
          type='primary'
          icon={<StepBackwardOutlined />}
          disabled={!undoPostEnabled}
        >
          {intl.get('ActionButtons.Button.undoPost')
            .d('Undo Post')}
        </Button>
      </StandardPopconfirm>
    );
    // withdraw button
    const withdrawEnabled = checkActionEnabled(actionConfigs, ACTION_WITHDRAW);
    innerButtons.push(
      <StandardPopconfirm
        key='withdraw'
        title={intl.get('ActionButtons.Popconfirm.confirmWithdraw')
          .d('Confirm Withdraw')}
        disabled={!withdrawEnabled}
        onConfirm={() => onWithdraw && onWithdraw()}
      >
        <Button
          type='primary'
          icon={<FastBackwardOutlined />}
          disabled={!withdrawEnabled}
        >
          {intl.get('ActionButtons.Button.withdraw')
            .d('Withdraw')}
        </Button>
      </StandardPopconfirm>
    );
    // withdraw button
    const suspendEnabled = checkActionEnabled(actionConfigs, ACTION_SUSPEND);
    innerButtons.push(
      <StandardPopconfirm
        key='suspend'
        title={intl.get('ActionButtons.Popconfirm.confirmSuspend')
          .d('Confirm Suspend')}
        disabled={!suspendEnabled}
        onConfirm={() => onSuspend && onSuspend()}
      >
        <Button
          type='primary'
          icon={<DeleteOutlined />}
          disabled={!suspendEnabled}
        >
          {intl.get('ActionButtons.Button.suspend')
            .d('Suspend')}
        </Button>
      </StandardPopconfirm>
    );

    actionComponents.push(
      <ButtonGroup key='postButtonGroup'>
        {innerButtons}
      </ButtonGroup>
    );
  }

  // use
  return (
    <Space
      direction="horizontal"
      size={12}
    >
      {actionComponents}
      <Modal
        title={ACTION_APPROVE === approvalModalMode
          ? intl.get('ActionButtons.Modal.title.confirmApprove')
            .d('Confirm Approve')
          : ACTION_REJECT === approvalModalMode
            ? intl.get('ActionButtons.Modal.title.confirmReject')
              .d('Confirm Reject')
            : ACTION_ENDORSE === approvalModalMode
              ? intl.get('ActionButtons.Modal.title.confirmEndorse')
                .d('Confirm Endorse')
              : '-'}
        centered
        footer={null}
        open={approvalModalMode !== null}
        onCancel={() => setApprovalModalMode(null)}
        destroyOnClose={true}
      >
        <ApprovalView
          confirmText={ACTION_APPROVE === approvalModalMode
            ? intl.get('ActionButtons.Button.approve')
              .d('Approve')
            : ACTION_REJECT === approvalModalMode
              ? intl.get('ActionButtons.Button.reject')
                .d('Reject')
              : ACTION_ENDORSE === approvalModalMode
                ? intl.get('ActionButtons.Button.endorse')
                  .d('Endorse')
                : '-'}
          forEndorse={ACTION_ENDORSE === approvalModalMode}
          onCancel={() => setApprovalModalMode(null)}
          onConfirm={(values) => {
            // console.debug('values', values);
            // cache value
            const mode = approvalModalMode;
            // dispose modal
            setApprovalModalMode(null);
            // callback
            if (ACTION_APPROVE === mode) {
              onApprove && onApprove(values.comment);
            } else if (ACTION_REJECT === mode) {
              onReject && onReject(values.comment);
            } else if (ACTION_ENDORSE === mode) {
              onEndorse && onEndorse(
                values.comment,
                values.endorsedUserIds,
                values.endorseBefore);
            }
          }}
        />
      </Modal>
    </Space>
  );
}
