import { Button, Tag } from 'antd';
import React from 'react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { useDispatch } from 'react-redux';
import 'react-resizable/css/styles.css';
import { SizeMe } from 'react-sizeme';
import { updateFieldMetasPosition } from '../designerSlice';

const COLUMN_COUNT = 18;
const ROW_HEIGHT = 40;

const HEADER_LAYOUT = [];
const HEADER_GRIDS = [];
for (let i = 0; i < COLUMN_COUNT; i++) {
  HEADER_LAYOUT.push({
    i: i.toString(), x: i, y: 0, w: 1, h: 1, static: true
  });
  HEADER_GRIDS.push(
    <Button
      key={i}
      disabled
      style={{ padding: 0, fontSize: 10 }}
    >
      {i + 1}
    </Button>
  );
}

export default function LayoutEditor({ fieldMetas }) {
  const selectedFieldMetas = fieldMetas.filter(fieldMeta => fieldMeta.selected === 'Y');
  const layout = selectedFieldMetas.map(fieldMeta => ({
    i: (fieldMeta.recKey || 0).toString(),
    x: fieldMeta.dgX || 0,
    y: fieldMeta.dgY || 0,
    w: fieldMeta.dgW || 4,
    h: fieldMeta.dgH || 1,
    minW: fieldMeta.dgMinW
  }));
  const grids = selectedFieldMetas.map(fieldMeta =>
    <Tag
      key={fieldMeta.recKey || 0}
      style={{ padding: 8, fontSize: 14 }}
      color="blue"
    >
      {fieldMeta.fieldLabel}
    </Tag>
  );

  // insert header
  layout.splice(0, 0, ...HEADER_LAYOUT);
  grids.splice(0, 0, ...HEADER_GRIDS);

  const dispatch = useDispatch();
  const handleLayoutChange = (layout) => {
    console.debug('layout', layout);
    const updatingFieldMetas = [];
    fieldMetas.forEach(fieldMeta => {
      layout.forEach(dataGridConfig => {
        // update data grid
        if ((fieldMeta.recKey || 0).toString() === dataGridConfig.i) {
          const updatingFieldMeta = Object.assign({}, fieldMeta);
          updatingFieldMeta.dgX = dataGridConfig.x;
          updatingFieldMeta.dgY = dataGridConfig.y;
          updatingFieldMeta.dgW = dataGridConfig.w;
          updatingFieldMeta.dgH = dataGridConfig.h;
          // collect
          updatingFieldMetas.push(updatingFieldMeta);
        }
      });
    });
    dispatch(updateFieldMetasPosition(updatingFieldMetas));
  };

  return (
    // *** NOTE *** 
    // the wrapping <div> is neccessary, 
    // to prevent SizeMe constantly refreshing its width,
    // especially when dragging items in RGL
    <div style={{ width: '100%' }}>
      <SizeMe
        monitorWidth={true}
        monitorHeight={false}
        monitorPosition={false}
        refreshRate={125}
        refreshMode='debounce'
        noPlaceholder={true}
        render={({ size }) => {
          // console.log('size', size);
          const widthInSize = size ? (size.width || 0) : 0;
          // console.log('widthInSize', widthInSize);
          return widthInSize > 0
            ? <GridLayout
              width={widthInSize}
              className="layout"
              cols={COLUMN_COUNT}
              rowHeight={ROW_HEIGHT}
              layout={layout}
              onLayoutChange={(layout) => handleLayoutChange(layout)}
            >
              {grids}
            </GridLayout>
            : <div />;
        }}
      />
    </div>
  );
}