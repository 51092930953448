import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined, SmileTwoTone, UserOutlined } from '@ant-design/icons';
import { BackTop, Button, Dropdown, Layout, Space, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { DESIGNER_PATH, ROOT_PATH, SETTINGS_PATH, VIEWER_PATH } from '../../../util/routerConstants';
import AppTree from '../../appTree/ui/AppTree';
import Designer from '../../designer/ui/Designer';
import FormViewer from '../../formViewer/ui/FormViewer';
import { logout, selectUser } from '../shellSlice';
import SettingsView from './SettingsView';
import UserView from './UserView';
import Version from './Version';

const logoUrl = window.formAppConfig.serviceEntry
  + '/epbm/api/image/form-h5/branding?resourceId=logo';

const { Text } = Typography;
const { Header, Content, Footer, Sider } = Layout;

export default function Main() {
  // hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const divDomRef = useRef(null);
  const [siderCollapsed, setSiderCollapsed] = useState(false);

  // handlers
  const checkAndPushPath = (path) => {
    // console.log('location', location);
    // console.log('path', path);
    if (location.pathname !== path) {
      navigate(path);
    }
  };
  const handleLogout = () => {
    dispatch(logout());
  };

  const items = [{
    key: 'profile',
    icon: <UserOutlined />,
    label: intl.get('Main.MenuItem.userProfile').d('Profile'),
  }, {
    key: 'settings',
    icon: <SettingOutlined />,
    label: intl.get('Main.MenuItem.settings').d('Settings')
  }, {
    type: 'divider', // Must have
  }, {
    key: 'logout',
    icon: <LogoutOutlined />,
    label: intl.get('Main.MenuItem.logout').d('Logout')
  }];

  return (
    <Layout style={styles.outterLayout}>
      <Sider
        style={styles.sider}
        collapsible
        collapsed={siderCollapsed}
        trigger={null}
        width={250}
      >
        <div style={styles.logo}>
          <StyledLogo />
        </div>
        <AppTree allowTooltip={!siderCollapsed} />
      </Sider>
      <Layout style={styles.innerLayout}>
        <Header style={styles.header}>
          <Button
            style={{ padding: 0 }}
            type="link"
            onClick={() => setSiderCollapsed(!siderCollapsed)}
          >
            {siderCollapsed
              ? <MenuUnfoldOutlined style={styles.trigger} />
              : <MenuFoldOutlined style={styles.trigger} />}
          </Button>

          <div style={styles.popoverWrapper}>
            <Dropdown menu={{
              items: items,
              onClick: ({ key }) => {
                if (key === 'profile') {
                  checkAndPushPath(ROOT_PATH);
                } else if (key === 'settings') {
                  checkAndPushPath(SETTINGS_PATH);
                } else if (key === 'logout') {
                  handleLogout();
                } else {
                  console.log(`key "${key}" not handled`);
                }
              }
            }}>
              <Space>
                <SmileTwoTone />
                <Text type="secondary">
                  {user.userId}
                </Text>
              </Space>
            </Dropdown>
          </div>
        </Header>

        <div
          ref={divDomRef}
          style={styles.contentWrapper}
        >
          <BackTop
            visibilityHeight={200}
            target={() => divDomRef.current || window}
          />
          <Content style={styles.content}>
            <Routes>
              <Route
                // path={VIEWER_PATH}
                // path="/viewer/*"
                path={VIEWER_PATH + '/*'}
                element={<FormViewer />}
              />
              <Route
                // path={DESIGNER_PATH}
                // path="/designer/*"
                path={DESIGNER_PATH + '/*'}
                element={<Designer />}
              />
              <Route
                path={SETTINGS_PATH}
                element={<SettingsView />}
              />
              <Route
                path={ROOT_PATH}
                element={<UserView />}
              />
            </Routes>
          </Content>
          <Footer style={styles.footer}>
            <Version />
          </Footer>
        </div>

      </Layout>
    </Layout>
  );
}

const StyledLogo = () => (
  <Link to={ROOT_PATH}>
    <img
      style={{
        height: '100%',
        marginLeft: 16,
        paddingTop: 12,
        paddingBottom: 12
      }}
      src={logoUrl}
      alt="logo"
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = 'logo512.png';
      }}
    />
  </Link>
);

const styles = {
  outterLayout: {
    minHeight: '100vh'
  },
  innerLayout: {
    maxHeight: '100vh'
  },
  sider: {
    maxHeight: '100vh',
    overflow: 'auto',
    boxShadow: '2px 0 6px rgba(0,21,41,.35)',
    zIndex: 10,
    // position: 'relative'
  },
  header: {
    background: '#fff',
    padding: 0,
    position: 'relative',
    // display: 'flex',
    alignItems: 'center',
    boxShadow: '0 1px 4px rgba(0,21,41,.08)',
    zIndex: 9,
  },
  contentWrapper: {
    overflow: 'auto'
  },
  content: {
    // margin: 24,
    // padding: 24,
    // background: '#fff',
    background: 'transparent',
    minHeight: '100vh',
  },
  footer: {
    textAlign: 'center',
    backgroundColor: 'transparent',
    paddingTop: 0,
    paddingBottom: 24
  },
  logo: {
    height: 64,
    background: '#002140',
  },
  trigger: {
    fontSize: 20,
    paddingTop: 0,
    paddingLeft: 24,
    height: 64,
    cursor: 'pointer',
    transition: 'all .3s,padding 0s',
    color: '#434343'
  },
  avatar: {
    backgroundColor: '#f56a00'
  },
  popoverWrapper: {
    float: 'right',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    minWidth: 160,
  }
};