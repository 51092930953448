export const ROOT_PATH = '/';
export const LOGIN_PATH = '/login';
export const SETTINGS_PATH = '/setting';

export const DESIGNER_PATH = '/designer';
export const DESIGNER_DETAIL_VIEW_PATH = DESIGNER_PATH + '/:formId';

export const VIEWER_PATH = '/viewer';
export const SOURCE_TABLE_VIEW_PATH = VIEWER_PATH + '/:formId';
export const SOURCE_DETAIL_VIEW_PATH = SOURCE_TABLE_VIEW_PATH + '/:recKey';
