import { Tabs } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import FieldMetasFormView from './FieldMetasFormView';

export default function FormmasView({
  formMeta,
  fieldMetas = [],
  editable,
  continued,
  supplementalFormRefs,
  // callbacks
  onFormCreated
}) {

  if (!fieldMetas
    || !fieldMetas.length === 0) {
    return null;
  }

  const formView = (
    <FieldMetasFormView
      fieldMetas={fieldMetas}
      editable={editable}
      supplementalFormRefs={supplementalFormRefs}
      onFormCreated={(form) => { onFormCreated && onFormCreated(form) }}
    />
  );

  return !continued
    ? formView
    : (<Tabs items={[{
      key: 'continued',
      label: formMeta &&
        (formMeta.formName
          + ' - '
          + intl.get('FormmasView.string.continued').d('Cont.')),
      children: formView
    }]} />);
}