import { unwrapResult } from '@reduxjs/toolkit';
import { message, Row, Space, Spin } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import React, { useMemo, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { VIEWER_PATH } from '../../../util/routerConstants';
import { TIME_FORMAT } from '../../shell/shellConstants';
import { ACTION_APPROVE, ACTION_ENDORSE, ACTION_NEW_LINE, ACTION_POST, ACTION_REJECT, ACTION_SAVE, ACTION_SUSPEND, ACTION_UNDO_POST, ACTION_WITHDRAW } from '../formConstants';
import { approveAsync, postAsync, saveAsync, selectActionIgnored, selectAnchor, selectFormSource, selectLineFieldMetas, selectWorking } from '../formDetailSlice';
import { checkActionEnabled, findLineInfo, splitFieldMetas } from '../formUtility';
import ActionButtons from './ActionButtons';
import FormlineView from './FormlineView';
import FormmasView from './FormmasView';
import ReverseMatchLink from './ReverseMatchLink';

export default function InputView() {

  //
  // hooks
  //

  const [redirectRecKey, setRedirectRecKey] = useState();
  const working = useSelector(selectWorking);
  const anchor = useSelector(selectAnchor);
  const formSource = useSelector(selectFormSource);
  const lineFieldMetas = useSelector(selectLineFieldMetas);
  const actionIgnored = useSelector(selectActionIgnored);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const upperFormmasFormRef = useRef();
  const lowerFormmasFormRef = useRef();
  const formlineFormRef = useRef();

  const { srcRecKey } = (anchor || {});
  const { formMeta, actionConfigs, fieldMetaWithValues: fieldMetas } = (formSource || {});
  const saveEnabled = !actionIgnored
    && (srcRecKey === '0' // creating new form
      || checkActionEnabled(actionConfigs, ACTION_SAVE));
  const newLineEnabled = !actionIgnored
    && (srcRecKey === '0' // creating new form
      || checkActionEnabled(actionConfigs, ACTION_NEW_LINE));

  const [upperFormmasView, lowerFormmasView, formlineView] = useMemo(() => {
    // split
    const [upperFieldMetas, lowerFieldMetas] = splitFieldMetas(fieldMetas);
    // upper formmas view
    const upperFormmasView = (
      <FormmasView
        formMeta={formMeta}
        fieldMetas={upperFieldMetas}
        editable={saveEnabled}
        supplementalFormRefs={[lowerFormmasFormRef]}
        onFormCreated={(form) => { upperFormmasFormRef.current = form }}
      />
    );
    // lower formmas view, if applicable
    const lowerFormmasView = lowerFieldMetas.length > 0 && (
      <FormmasView
        formMeta={formMeta}
        fieldMetas={lowerFieldMetas}
        editable={saveEnabled}
        continued={true}
        supplementalFormRefs={[upperFormmasFormRef]}
        onFormCreated={(form) => { lowerFormmasFormRef.current = form }}
      />
    );
    const [lineId, lineName] = findLineInfo(fieldMetas);
    // formline view
    const formlineView = lineId
      ? <FormlineView
        lineName={lineName}
        lineFieldMetas={toSmoothedLineFieldMetas(lineFieldMetas)}
        editable={saveEnabled}
        newLineEnabled={newLineEnabled}
        supplementalFormRefs={[upperFormmasFormRef, lowerFormmasFormRef]}
        onFormCreated={(form) => { formlineFormRef.current = form }}
      />
      : null;

    return [upperFormmasView, lowerFormmasView, formlineView];
  }, [
    formMeta, fieldMetas, lineFieldMetas,
    upperFormmasFormRef, lowerFormmasFormRef, formlineFormRef,
    saveEnabled, newLineEnabled
  ]);

  //
  // handlers
  //

  const executeFormAction = async (actionName) => {
    const [fieldMetaWithValues, fieldMetaWithValueArrays] = await buildUploadValues(
      upperFormmasFormRef,
      lowerFormmasFormRef,
      formlineFormRef
    );

    const result = actionName
      ? await dispatch(postAsync({
        fieldMetaWithValues, fieldMetaWithValueArrays, actionName
      }))
      : await dispatch(saveAsync({
        fieldMetaWithValues, fieldMetaWithValueArrays
      }));

    try {
      // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
      const remoteRecKey = unwrapResult(result);
      console.log('fulfilled', remoteRecKey);

      // prompt
      message.success(
        intl.get('InputView.message.succeeded')
          .d('Action performed successfully'));
      // redirect
      setRedirectRecKey(remoteRecKey);
    } catch (error) {
      // bad result
      console.error('error', error);
      // prompt
      message.error((error || {}).message);
    }
  };

  const executeFormApprovalAction = async (
    actionName, comment,
    endorsedUserIds, endorseBefore
  ) => {

    let [fieldMetaWithValues, fieldMetaWithValueArrays] = [null, null];
    if (checkActionEnabled(actionConfigs, ACTION_SAVE)
      && actionName !== ACTION_REJECT) {
      // collect
      [fieldMetaWithValues, fieldMetaWithValueArrays] = await buildUploadValues(
        upperFormmasFormRef,
        lowerFormmasFormRef,
        formlineFormRef,
        endorseBefore
      );
    }

    // if (1 + 1 === 2) {
    //   return;
    // }

    const result = await dispatch(approveAsync({
      fieldMetaWithValues, fieldMetaWithValueArrays,
      comment, endorsedUserIds, endorseBefore, actionName
    }));

    try {
      // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
      const fulfilled = unwrapResult(result);
      console.log('fulfilled', fulfilled);

      // message
      message.success(
        intl.get('InputView.message.succeeded')
          .d('Action performed successfully'));
      // back
      navigate(-1);
    } catch (error) {
      // bad result
      console.error('error', error);
      // prompt
      message.error((error || {}).message);
    }
  };

  //
  // render
  //

  // validation
  if (!formMeta || !srcRecKey) {
    // early return
    return null;
  }

  // redirect
  if (redirectRecKey
    // was creating new
    && numeral(redirectRecKey).value() !== numeral(srcRecKey).value()) {
    // build path
    const pathname = VIEWER_PATH + '/' + formMeta.formId + '/' + redirectRecKey;
    // early return
    return (
      <Navigate to={pathname} replace />
    );
  }

  // compose
  const content = (
    <Space
      style={{ width: '100%' }}
      direction="vertical"
      size="middle"
    >
      <Row
        align="middle"
        justify="space-between"
      >
        <ActionButtons
          onSave={() => executeFormAction()}
          onPost={() => executeFormAction(ACTION_POST)}
          onUndoPost={() => executeFormAction(ACTION_UNDO_POST)}
          onWithdraw={() => executeFormAction(ACTION_WITHDRAW)}
          onSuspend={() => executeFormAction(ACTION_SUSPEND)}
          onApprove={(comment) => executeFormApprovalAction(ACTION_APPROVE, comment)}
          onReject={(comment) => executeFormApprovalAction(ACTION_REJECT, comment)}
          onEndorse={(comment, endorsedUserIds, endorseBefore) => executeFormApprovalAction(
            ACTION_ENDORSE, comment,
            endorsedUserIds, endorseBefore)}
        />
        <ReverseMatchLink />
      </Row>
      {upperFormmasView}
      {formlineView}
      {lowerFormmasView}
    </Space>
  );

  return working
    ? (
      <Spin tip={intl.get('InputView.Spin.working').d('Please hold')}>
        {content}
      </Spin>
    )
    : content;
}

async function buildUploadValues(
  upperFormmasFormRef,
  lowerFormmasFormRef,
  formlineFormRef,
  ignoreValidation
) {

  const upperFormmasFormValues = await collectFormValues(upperFormmasFormRef, ignoreValidation);
  const lowerFormmasFormValues = await collectFormValues(lowerFormmasFormRef, ignoreValidation);
  const formlineFormValues = await collectFormValues(formlineFormRef, ignoreValidation);

  console.debug('upperFormmasFormValues', upperFormmasFormValues);
  console.debug('lowerFormmasFormValues', lowerFormmasFormValues);
  console.debug('formlineFormValues', formlineFormValues);

  const fieldMetaWithValues = buildFieldMetaWithValues(upperFormmasFormValues);
  if (fieldMetaWithValues) {
    fieldMetaWithValues.push(...(buildFieldMetaWithValues(lowerFormmasFormValues) || []));
  }
  const fieldMetaWithValueArrays = buildFieldMetaWithValueArrays(formlineFormValues);

  console.debug('fieldMetaWithValues', fieldMetaWithValues);
  console.debug('fieldMetaWithValueArrays', fieldMetaWithValueArrays);

  // use
  return [fieldMetaWithValues, fieldMetaWithValueArrays];
}

async function collectFormValues(formRef, ignoreValidation) {
  return formRef.current
    ? ignoreValidation
      ? await formRef.current.getFieldsValue()
      : await formRef.current.validateFields()
    : null;
}

function buildFieldMetaWithValues(formValues) {
  if (!formValues) {
    // mark as null, early return
    return null;
  }

  const fieldMetaWithValues = [];
  Object.keys(formValues).forEach(key => {
    const value = formValues[key];
    // collect
    fieldMetaWithValues.push({
      recKey: key,
      value: toFormFieldMetaValue(value)
    });
  });

  //use
  return fieldMetaWithValues;
}

function buildFieldMetaWithValueArrays(formValues) {
  if (!formValues) {
    // mark as null, early return
    return null;
  }

  const fieldMetaWithValueArrays = [];
  Object.keys(formValues)
    // sort keys first
    .sort((left, right) => {
      const [leftFieldMetaRecKey, leftRecordRecKey] = left.split('_');
      const [rightFieldMetaRecKey, rightRecordRecKey] = right.split('_');
      if (leftRecordRecKey === rightRecordRecKey) {
        return leftFieldMetaRecKey.localeCompare(rightFieldMetaRecKey);
      }
      const leftRecordRecKeyNum = numeral(leftRecordRecKey || 0).value();
      const rightRecordRecKeyNum = numeral(rightRecordRecKey || 0).value();
      if (leftRecordRecKeyNum < 0) {
        return -(leftRecordRecKeyNum - rightRecordRecKeyNum);
      } else {
        return leftRecordRecKeyNum - rightRecordRecKeyNum;
      }
    })
    .forEach(key => {
      const value = formValues[key];
      const [fieldMetaRecKey] = key.split('_');
      const matchedFieldMeta = fieldMetaWithValueArrays.find(fieldMeta =>
        fieldMeta.recKey === fieldMetaRecKey);
      if (matchedFieldMeta) {
        matchedFieldMeta.values.push(toFormFieldMetaValue(value));
      } else {
        // make
        const fieldMetaWithValueArray = {
          recKey: fieldMetaRecKey,
          values: [toFormFieldMetaValue(value)]
        };
        // add
        fieldMetaWithValueArrays.push(fieldMetaWithValueArray);
      }
    });

  //use
  return fieldMetaWithValueArrays;
}

function toFormFieldMetaValue(formValue) {
  return moment.isMoment(formValue)
    ? formValue.format(TIME_FORMAT)
    : (typeof (formValue) === typeof (true))
      ? formValue
        ? 'Y'
        : 'N'
      : formValue;
}

function toSmoothedLineFieldMetas(fieldMetas) {
  return (fieldMetas || []).map(fieldMeta => {
    const newFieldMeta = Object.assign({}, fieldMeta);
    if (newFieldMeta.dgX === null) {
      newFieldMeta.dgX = 0;
    }
    if (newFieldMeta.dgY === null) {
      newFieldMeta.dgY = 1;
    }
    if (newFieldMeta.dgW === null) {
      newFieldMeta.dgW = 3;
    }
    if (newFieldMeta.dgH === null) {
      newFieldMeta.dgH = 1;
    }
    if (newFieldMeta.dgMinW === null) {
      newFieldMeta.dgMinW = 3;
    }
    return newFieldMeta;
  });
}
