import { DownOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Input, Row, Space } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import LovSelect from '../../lov/ui/LovSelect';
import { DATE_FORMAT } from '../../shell/shellConstants';
import ConstantSelect from './ConstantSelect';

const { RangePicker } = DatePicker;

const colSpan = 12;
const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

export default function CriteriaPanel({
  formId,
  fieldMetas,
  recoveringConditions,
  onCollectConditions,
  loading
}) {

  // hooks
  const [expanded, setExpanded] = useState(false);
  const [form] = Form.useForm();
  const formRef = useRef(form);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.resetFields();
    }
  }, [formId, formRef]); // make sure that this effect depends on formId

  useEffect(() => {
    if (!formRef.current) {
      return;
    }
    // reset first
    formRef.current.resetFields();
    // set controlled value 
    const fieldsValue = {};
    recoveringConditions.forEach(condition => {
      // console.debug('condition', condition);
      const { recKey, value, operator } = condition;
      const matchedFieldMeta = fieldMetas.find(fieldMeta =>
        fieldMeta.recKey.toString() === recKey.toString());
      if (!matchedFieldMeta || !value) {
        // no match, early return
        return;
      } else if (matchedFieldMeta.componentType === 'D') {
        const rangeValue =
          // reuse
          fieldsValue[recKey]
          // create
          || [null, null];
        const momentValue = moment(value, DATE_FORMAT);
        // update
        if (operator === '>=') {
          rangeValue[0] = momentValue;
        } else if (operator === '<=') {
          rangeValue[1] = momentValue;
        }
        // use
        fieldsValue[recKey] = rangeValue;
      } else {
        // use
        fieldsValue[recKey] = value;
      }
    });
    // console.debug('fieldsValue', fieldsValue);
    // set
    formRef.current.setFieldsValue(fieldsValue);
  }, [fieldMetas, recoveringConditions, formRef]);

  // form items
  const conditionFormItems = buildFormItems(fieldMetas, expanded, loading);
  const allFormItems = [...conditionFormItems];

  // place holder
  if (conditionFormItems.length % 2 === 0) {
    allFormItems.push(<CriteriaPlaceholder key='placeholder' />);
  }

  // buttons
  allFormItems.push(
    <CriteriaActionButtons
      key='actionButtons'
      expanded={expanded}
      showResetButton={conditionFormItems.length > 0}
      showExpandButton={conditionFormItems.length > 0}
      onResetFields={() => form.resetFields()}
      onCollectConditions={() => {
        const conditions = collectConditions(form);
        if (onCollectConditions) {
          onCollectConditions(conditions);
        }
      }}
      onToggleExpanded={() => setExpanded(!expanded)}
      loading={loading}
    />
  );

  // console.log('formFields', formFields);
  return (
    <Card bodyStyle={{ paddingBottom: 0 }}>
      <Form
        {...formLayout}
        form={form}
      >
        <Row>{allFormItems}</Row>
      </Form>
    </Card>
  );
}

function CriteriaFormItem({ fieldMeta, loading }) {
  const itemName = fieldMeta.recKey.toString();
  const itemLabel = fieldMeta.fieldLabel;

  return (
    <Col span={colSpan}>
      <Form.Item
        name={itemName}
        label={itemLabel}
      >
        {
          (fieldMeta.componentType === 'T'
            || fieldMeta.componentType === 'I')
            ? <Input
              allowClear
              disabled={loading}
            />
            : fieldMeta.componentType === 'S'
              ? <ConstantSelect
                fieldMeta={fieldMeta}
                allowClear
                disabled={loading}
              />
              : fieldMeta.componentType === 'L'
                ? <LovSelect
                  fieldMeta={fieldMeta}
                  usedForCriteria={true}
                  allowClear
                  disabled={loading}
                />
                : fieldMeta.componentType === 'D'
                  ? <RangePicker
                    style={{ width: '100%' }}
                    format={DATE_FORMAT}
                    allowEmpty={[true, true]}
                    disabled={loading}
                  />
                  // not catered
                  : <Input
                    allowClear
                    disabled={loading}
                  />
        }
      </Form.Item>
    </Col>
  );
}

function CriteriaPlaceholder() {
  return (
    <Col span={colSpan} >
      <Button
        style={{
          whiteSpace: "pre-wrap",
          marginBottom: 24
        }}
        type="text"
      >
        {'\b'}
      </Button>
    </Col>
  );
}

function CriteriaActionButtons({
  expanded,
  showResetButton,
  showExpandButton,
  onResetFields,
  onCollectConditions,
  onToggleExpanded,
  loading
}) {
  return (
    <Col
      key={'buttons'}
      span={colSpan}
    >
      <Space
        style={{
          position: 'absolute',
          right: 0
        }}
        size="middle"
      >
        {showResetButton && (
          <Button
            disabled={loading}
            onClick={() => onResetFields && onResetFields()}
          >
            {intl.get('CriteriaForm.Button.reset')
              .d('Reset')}
          </Button>
        )}
        <Button
          type="primary"
          icon={<SearchOutlined />}
          disabled={loading}
          onClick={() => onCollectConditions && onCollectConditions()}
        >
          {intl.get('CriteriaForm.Button.search')
            .d('Search')}
        </Button>
        {showExpandButton && (
          <Button
            type="text"
            style={{ color: '#1890ff' }}
            disabled={loading}
            onClick={() => onToggleExpanded && onToggleExpanded()}
          >
            {expanded
              ? intl.get('CriteriaForm.Button.collapse')
                .d('Collapse')
              : intl.get('CriteriaForm.Button.expand')
                .d('Expand')}
            {expanded
              ? <UpOutlined />
              : <DownOutlined />}
          </Button>
        )}
      </Space>
    </Col>
  );
}

function buildFormItems(fieldMetas, expanded, loading) {
  const formItems = [];
  fieldMetas.forEach((fieldMeta) => {
    if (!expanded // show only 1 form field when collapsed
      && formItems.length >= 1) {
      // early retun
      return;
    }

    if (fieldMeta.recKey <= 0
      || fieldMeta.searchCondition !== 'Y') {
      // early retun
      return;
    }

    formItems.push(
      <CriteriaFormItem
        key={fieldMeta.recKey}
        fieldMeta={fieldMeta}
        loading={loading}
      />
    );
  });
  // use
  return formItems;
}

function collectConditions(form) {
  const formFields = form.getFieldsValue();
  // console.debug('formFields', formFields);

  const conditions = [];
  Object.keys(formFields).forEach(key => {
    const value = formFields[key];
    if (!value) {
      return;
    }

    if (Array.isArray(value)) {
      const [valueFrom, valueTo] = value;
      if (valueFrom && moment.isMoment(valueFrom)) {
        const formattedDateValue = valueFrom.format(DATE_FORMAT)
        conditions.push({
          recKey: key,
          operator: '>=',
          value: formattedDateValue
        });
      }
      if (valueTo && moment.isMoment(valueTo)) {
        const formattedDateValue = valueTo.format(DATE_FORMAT)
        conditions.push({
          recKey: key,
          operator: '<=',
          value: formattedDateValue
        });
      }
    } else {
      const smoothedValue = value.toString().trim();
      if (!smoothedValue) {
        return;
      }

      conditions.push({
        recKey: key,
        operator: "LIKE",
        value: smoothedValue
      });
    }
  });

  // use
  // console.debug('conditions', conditions);
  return conditions;
}