import { FilterOutlined } from '@ant-design/icons';
import { Input, Space, Table } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DESIGNER_PATH } from '../../../util/routerConstants';
import { selectFullFormMetas } from '../../appTree/appTreeSlice';

export default function DesignerTableView() {
  const [filterText, setFilterText] = useState('');
  const formMetas = useSelector(selectFullFormMetas);

  const handleFilterTextChange = (event) => {
    setFilterText(event.target.value.trim());
  };

  const filteredFormMetas = filterText
    ? (formMetas || []).filter(formMeta =>
      formMeta.formName.toLowerCase().indexOf(filterText.toLowerCase()) >= 0
      || formMeta.formId.toLowerCase().indexOf(filterText.toLowerCase()) >= 0
    )
    : formMetas;

  const columns = [{
    dataIndex: 'formName',
    title: intl.get('DesignerTableView.Table.Column.formName')
      .d('Form Name')
  }, {
    dataIndex: 'formId',
    title: intl.get('DesignerTableView.Table.Column.formId')
      .d('Form ID')
  }, {
    dataIndex: 'recKey',
    title: intl.get('DesignerTableView.Table.Column.action')
      .d('Action'),
    width: '10%',
    render: (text, record) => (
      <Link to={DESIGNER_PATH + '/' + record.formId}>
        {intl.get('DesignerTableView.Button.edit')
          .d('Edit')}
      </Link>
      // <Button type="link">
      // </Button>
    ),
  }];

  return (
    <div style={{ padding: 24 }}>
      <Space
        style={{
          padding: 24,
          backgroundColor: 'white',
          width: '100%'
        }}
        direction="vertical"
        size="large"
      >
        <Input
          prefix={<FilterOutlined />}
          placeholder={intl.get('DesignerTableView.Input.filter').d('Filter')}
          value={filterText}
          onChange={(e) => handleFilterTextChange(e)}
          allowClear
          autoFocus
        />
        <Table
          dataSource={filteredFormMetas}
          columns={columns}
          rowKey='recKey'
          size="small"
          pagination={false}
          bordered
          sticky
        />
      </Space>
    </div>
  );
}