import { createAsyncThunk, createDraftSafeSelector, createEntityAdapter, createSlice, isAnyOf, isPending, isRejected } from '@reduxjs/toolkit';
import axios from 'axios';

export const sliceName = 'designer';

//
// adapter
//

const entityAdapter = createEntityAdapter({
  selectId: entity => entity.recKey
});

//
// slice of state
//

const sliceState = {
  fieldMetas: entityAdapter.getInitialState(),
  // shared loading status
  loading: false
};

//
// selectors
//

// managed by adapter
export const {
  selectAll: selectAllFieldMetas,
  selectIds: selectFieldMetaRecKeys
} = entityAdapter.getSelectors(state => state[sliceName].fieldMetas);

// not managed by adapter
export const selectLoading = state => state[sliceName].loading;

// calculated
export const selectPickedFieldMetas = createDraftSafeSelector(
  [selectAllFieldMetas],
  (fieldMetas) => fieldMetas.filter(fieldMeta => fieldMeta.selected === 'Y')
)

//
// thunks
//

const notLoading = (arg, thunkAPI) => {
  const { getState } = thunkAPI;
  const loading = selectLoading(getState());
  return !loading;
};

export const loadFieldMetasAsync = createAsyncThunk(
  sliceName + '/loadFieldMetasAsync',
  async (arg, thunkAPI) => {
    const { formRecKey, lineId, charset } = arg;
    const fieldMetasResponse = await axios.get(
      '/form/api/forms/' + formRecKey + '/fields',
      {
        params: { lineId, charset }
      }
    );
    // console.log('fieldMetasResponse', fieldMetasResponse);

    const fieldMetas = fieldMetasResponse.data;
    return fieldMetas;
  },
  { condition: notLoading }
);

export const saveFieldMetasAsync = createAsyncThunk(
  sliceName + '/loadFieldMetasAsync',
  async (arg, thunkAPI) => {
    const { formRecKey, formFieldMetas, lineId } = arg;
    const fieldMetasResponse = await axios.post(
      '/form/api/forms/' + formRecKey + '/fields',
      {
        formFieldMetas,
        lineId: lineId || null
      }
    );
    // console.log('fieldMetasResponse', fieldMetasResponse);

    const newFieldMetas = fieldMetasResponse.data;
    return newFieldMetas;
  },
  { condition: notLoading }
);

//
// slice
//

const isDefinedPending = isPending(loadFieldMetasAsync);
const isDefinedRejected = isRejected(loadFieldMetasAsync);
const {
  selectById: selectFieldMetaByRecKeyLocally,
  selectAll: selectAllFieldMetasLocally
} = entityAdapter.getSelectors();

const _designerSlice = createSlice({
  name: sliceName,
  initialState: sliceState,
  reducers: {
    pickFieldMeta(state, action) {
      const { recKey, picked } = action.payload;
      const matchedFieldMeta = selectFieldMetaByRecKeyLocally(state.fieldMetas, recKey);
      if (!matchedFieldMeta) {
        // early return
        return;
      }

      if (picked) { // picked
        // calculate last row
        const allFieldMetas = selectAllFieldMetasLocally(state.fieldMetas);
        let maxDgY = 0;
        allFieldMetas.forEach(fieldMeta => {
          if (fieldMeta.selected === 'Y'
            && fieldMeta !== matchedFieldMeta) {
            maxDgY = Math.max(maxDgY, fieldMeta.dgY);
          }
        });
        // console.log('maxDgY', maxDgY);

        // state, managed by adapter
        entityAdapter.updateOne(state.fieldMetas, {
          id: recKey,
          changes: {
            selected: 'Y',
            dgX: 0,
            dgY: maxDgY + 1,
          }
        });
      } else { // de-picked
        // state, managed by adapter
        entityAdapter.updateOne(state.fieldMetas, {
          id: recKey,
          changes: {
            selected: 'N',
          }
        });
      }
    },

    updateFieldMetasPosition(state, action) {
      const updatingFieldMetas = action.payload;
      // state, managed by adapter
      entityAdapter.updateMany(state.fieldMetas, updatingFieldMetas.map(fieldMeta => (
        {
          id: fieldMeta.recKey,
          changes: {
            dgX: fieldMeta.dgX,
            dgY: fieldMeta.dgY,
            dgW: fieldMeta.dgW,
            dgH: fieldMeta.dgH
          }
        }
      )));
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(
      loadFieldMetasAsync.fulfilled,
      saveFieldMetasAsync.fulfilled
    ), (state, action) => {
      // console.log(action);
      // state, managed by adapter
      const fieldMetas = action.payload;
      entityAdapter.setAll(state.fieldMetas, fieldMetas);
      // mark
      state.loading = false;
    });

    builder.addMatcher(isDefinedPending, (state, action) => {
      // mark
      state.loading = true;
    });

    builder.addMatcher(isDefinedRejected, (state, action) => {
      console.log('action rejected', action);
      // mark
      state.loading = false;
    })
  }
});

//
// actions
//

export const { pickFieldMeta, updateFieldMetasPosition } = _designerSlice.actions;

//
// reducer
//

export default _designerSlice.reducer;