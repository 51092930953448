import { Checkbox, Col, Row } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { pickFieldMeta } from '../designerSlice';

const COLUMN_COUNT = 4;

export default function FieldMetasGrid({ fieldMetas }) {
  // hooks
  const dispatch = useDispatch();

  const cols = fieldMetas.map(fieldMeta => (
    <Col
      key={fieldMeta.recKey || 0}
      span={24 / COLUMN_COUNT}
    >
      <Checkbox
        checked={fieldMeta.selected === 'Y'}
        disabled={fieldMeta.altColumnType !== null}
        onChange={event => dispatch(
          pickFieldMeta({
            recKey: fieldMeta.recKey,
            picked: event.target.checked
          })
        )}
      >
        {fieldMeta.fieldLabel}
      </Checkbox>
    </Col>
  ));

  return (
    <Row gutter={[0, 16]}>
      {cols}
    </Row>
  );
}