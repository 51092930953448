import { PrinterOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadReportsAsync, prepareReportUrlAsync,
  selectGeneratingFetchKey, selectLoadingReports, selectRecordAvailable, selectReports
} from '../formDetailSlice';

export default function ReportButton() {

  //
  // hooks
  //

  const recordAvailable = useSelector(selectRecordAvailable);
  const [modalOpen, setModalOpen] = useState(false);

  //
  // render
  //

  return (
    <>
      <Button
        type='primary'
        icon={<PrinterOutlined />}
        disabled={!recordAvailable}
        onClick={() => setModalOpen(true)}
      >
        {intl.get('SourcePageHeader.Button.report')
          .d('Report')}
      </Button>

      <Modal
        bodyStyle={{ padding: 0 }}
        title={intl.get('SourcePageHeader.Modal.title.selectReport')
          .d('Select Report')}
        centered
        footer={null}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
      >
        <ReportView />
      </Modal>
    </>
  );
}

function ReportView() {

  //
  // hooks
  //

  const loadingReports = useSelector(selectLoadingReports);
  const reports = useSelector(selectReports);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadReportsAsync());
  }, [dispatch]);

  //
  // render
  //

  const renderRecKey = (reportRecKey) => (
    <OpenReportButton
      key={reportRecKey}
      reportRecKey={reportRecKey}
    />
  );

  return (
    <Table
      style={{
        padding: 1,
        width: '100%'
      }}
      bordered
      size="small"
      pagination={false}
      columns={buildColumns(renderRecKey)}
      dataSource={reports}
      rowKey="recKey"
      loading={loadingReports}
    />
  );
}

function OpenReportButton({
  reportRecKey
}) {

  //
  // hooks
  //

  const generatingFetchKey = useSelector(selectGeneratingFetchKey);
  const dispatch = useDispatch();

  //
  // handlers
  //

  const performOpen = async () => {
    const result = await dispatch(prepareReportUrlAsync({
      reportRecKey: reportRecKey
    }));

    try {
      // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
      const reportUrl = unwrapResult(result);
      console.log('reportUrl', reportUrl);

      // open
      window.open(reportUrl, '_blank');
    } catch (error) {
      // bad result
      console.error('error', error);
    }
  };

  //
  // render
  //

  return (
    <Button
      type="link"
      size="small"
      style={{ margin: 0, padding: 0 }}
      disabled={generatingFetchKey}
      onClick={performOpen}
    >
      {intl.get('ReportView.Button.print')
        .d('Print')}
    </Button>
  );
}

function buildColumns(renderRecKey) {
  const columns = [];

  columns.push({
    title: intl.get('ReportView.column.code')
      .d('Code'),
    dataIndex: 'rptCode',
    width: '25%'
  });

  columns.push({
    title: intl.get('ReportView.column.name')
      .d('Name'),
    dataIndex: 'name',
  });

  columns.push({
    title: intl.get('ReportView.column.action')
      .d('Action'),
    dataIndex: 'recKey',
    render: (value, record) => renderRecKey
      ? renderRecKey(value)
      : value,
    width: '25%'
  });

  // use
  return columns;
}
