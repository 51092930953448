import { ArrowLeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, message, Modal, PageHeader } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isFormDesignerSubNode, parseFormId } from '../../../util/routerUtility';
import { selectOriginalAppNodes } from '../../appTree/appTreeSlice';
import { saveFieldMetasAsync, selectAllFieldMetas, selectLoading } from '../designerSlice';

const SCREEN_PLACEHOLDER = '\b';

export default function DesignerPageHeader() {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const loading = useSelector(selectLoading);
  const fieldMetas = useSelector(selectAllFieldMetas);
  const appNodes = useSelector(selectOriginalAppNodes);

  // calculation
  const query = new URLSearchParams(location.search);
  const lineId = query.get('lineId');
  const lineLabel = query.get('lineLabel');
  // console.log('lineId', lineId);
  // console.log('lineLabel', lineLabel);

  let formDesignerSubNode = null;
  let formDesignerWrappingAppNode = appNodes.find(appNode =>
    (formDesignerSubNode = (appNode.subNodes || []).find(subNode =>
      isFormDesignerSubNode(subNode))
    ) !== undefined
  );

  const { pathname } = location;
  const formId = parseFormId(pathname);
  let editingFormSubNode = null;
  if (formId) {
    appNodes.forEach(appNode => {
      if (!editingFormSubNode) {
        editingFormSubNode = (appNode.subNodes || []).find(subNode =>
          subNode.formMeta && subNode.formMeta.formId === formId)
      }
    });
  }

  const breadcrumbRoutes = [{
    path: 'p1',
    breadcrumbName: formDesignerWrappingAppNode
      ? formDesignerWrappingAppNode.appName
      : SCREEN_PLACEHOLDER
  }, {
    path: 'p2',
    breadcrumbName: formDesignerSubNode
      ? intl.get('App.string.formDesigner')
        .d('Form Designer') || formDesignerSubNode.appName
      : SCREEN_PLACEHOLDER
  }];

  const buttons = [];
  if (editingFormSubNode) {
    fieldMetas.filter(fieldMeta =>
      fieldMeta.componentType === 'E' // E for Embed
      && fieldMeta.constants // refer to api. line meta as constant
    ).forEach(fieldMeta => {
      const lineMetaAsConstant = fieldMeta.constants[0];
      const editLineButton = (
        <Link
          key={fieldMeta.recKey}
          to={
            pathname
            + '?lineId=' + lineMetaAsConstant.value
            + '&lineLabel=' + lineMetaAsConstant.label
          }
        >
          <Button loading={loading}>
            {intl.get('DesignerPageHeader.Button.edit')
              .d('Edit') + ' ' + lineMetaAsConstant.label}
          </Button>
        </Link>
      );
      // edit line button
      buttons.push(editLineButton);
    });
    // save button
    if (fieldMetas && fieldMetas.length > 0) {
      buttons.push(
        <Button
          key="save"
          type="primary"
          danger
          loading={loading}
          onClick={() => handleSave()}
        >
          {intl.get('DesignerPageHeader.Button.save')
            .d('Save')}
        </Button>
      );
    }
  }

  // handlers
  const handleSave = () => {
    if (!editingFormSubNode) {
      // early return
      return;
    }

    Modal.confirm({
      title: intl.get('DesignerPageHeader.Modal.title.saveLayout')
        .d('Save Layout'),
      content: intl.get('DesignerPageHeader.Modal.content.confirmSaveLayout')
        .d('You are about to save this layout. Please confirm this operation'),
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      onOk: async () => {
        console.log('formId', formId);
        console.log('lineId', lineId);

        // dispatch action
        const result = await dispatch(saveFieldMetasAsync({
          formRecKey: editingFormSubNode.formMeta.recKey,
          formFieldMetas: fieldMetas,
          lineId
        }));

        try {
          // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
          const fulfilled = unwrapResult(result);
          console.log('fulfilled', fulfilled);

          // prompt
          message.success(
            intl.get('DesignerPageHeader.message.layoutSaved')
              .d('Layout saved successfully'));
        } catch (error) {
          // bad result
          console.error('error', error);
        }
      }
    });
  };

  // render
  return (
    <PageHeader
      ghost={false}
      backIcon={editingFormSubNode ? <ArrowLeftOutlined /> : false}
      onBack={() => navigate(-1)}
      breadcrumb={{
        routes: breadcrumbRoutes,
        separator: (formDesignerWrappingAppNode && formDesignerSubNode) ? '/' : SCREEN_PLACEHOLDER,
        itemRender: (route) => (<span>{route.breadcrumbName}</span>)
      }}
      title={
        editingFormSubNode
          ? (editingFormSubNode.appName
            + ((lineId && lineLabel)
              ? (' - ' + lineLabel)
              : ''))
          : formDesignerSubNode
            ? intl.get('App.string.formDesigner')
              .d('Form Designer') || formDesignerSubNode.appName
            : SCREEN_PLACEHOLDER
      }
      extra={buttons}
    />
  );
}